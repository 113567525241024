import React from "react";
import "./BadgeComponent.css";

const BadgeComponent = ({ text, className, children, onClick }) => {
  return (
    <div
      onClick={() => {
        onClick && onClick();
      }}
      className={`badge-reusable-wrap-index`}
    >
      <div
        className={`badge-wrap-index ${className?.toLowerCase()} grey-bg-two`}
      >
        {children} <span>{text || ""}</span>
      </div>
    </div>
  );
};

export default BadgeComponent;
