/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./IconDropFragment.css";
import { useDarkMode } from "../../../utils/themes/useDarkMode";
import { useOnClickOutside } from "../../../utils/helper/UseOnClickOutside";

const IconDropFragment = ({ list, iconColor, className }) => {
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  const [showDrop, setShowDrop] = useState(false);

  const SvgIconDrop = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      className="img"
    >
      <path
        fill={props || theme === "light" ? "#1A1A1A" : "#ffffff"}
        // className="down-icon"
        d="M11.168 6.863a2.143 2.143 0 1 1 0-4.286 2.143 2.143 0 0 1 0 4.286M11.168 13.291a2.143 2.143 0 1 1 0-4.285 2.143 2.143 0 0 1 0 4.285M11.168 19.72a2.143 2.143 0 1 1 0-4.286 2.143 2.143 0 0 1 0 4.286"
      ></path>
    </svg>
  );

  const testingDropRef = useOnClickOutside(() => {
    setShowDrop(false);
  });



  return (
    <div
      className={`reusable-icon-drop-fragment-business-wrapper-box ${
        className ? className : ""
      }`}
      ref={testingDropRef}
    >
      {/* icon click box star */}
      <div
        onClick={() => {
          setShowDrop(!showDrop);
        }}
        className="icon-click-drop-box card-bg"
      >
        <figure className="img-box">{SvgIconDrop(iconColor)}</figure>
      </div>
      {/* icon click box end */}
      {/* drop list box start */}
      {showDrop && list?.length > 0 ? (
        <div className="drop-list-box-wrap shadow-shadow-box-shadow border-theme card-bg">
          { list?.map((chi, idx) => {
            if (chi?.hide) {
              return <React.Fragment key={idx}></React.Fragment>;
            }
            return (
              <div
                onClick={() => {
                  chi?.onClick && chi?.onClick(chi);
                }}
                className="list-item border-theme-bottom"
              >
                {chi?.icon ? (
                  <div className="icon-box grey-bg">
                    <figure className="img-box">{chi?.icon}</figure>
                  </div>
                ) : (
                  ""
                )}
                <p style={{color: chi?.color ? chi?.color : ""}} className="text">{chi?.text}</p>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
      {/* drop list box end */}
    </div>
  );
};

export default IconDropFragment;
