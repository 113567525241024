import {
//   RavenCheckBox,
  RavenInputField,
  RavenModal,
  toast,
} from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import "../../../../styles/dashboard/pos/AddAlertRecievers.css";
import { useDispatch, useSelector } from "react-redux";
// import RootState from "../../../../redux/types";

import { validateEmail } from "../../../../utils/helper/ValidateInput";
import { shareFlierFunc } from "../../../../redux/pos";
import ImageFragment from "../../../../components/fragments/ImageFragment";

const ShareFlierModal = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const { flier_to_use, loadingSettlement } = useSelector((state) => state.pos);
  // interface formComponent {
  //   email: string;
  //   full_name: string;
  //   phone: string;
  // }
  const [details, setDetails] = useState({
    email: "",
  });

  const [showError, setShowError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleSubmit = async () => {
    const obj = {
      file: flier_to_use,
      email: details?.email,
    };
    // console.log(obj);
    // return;
    const data = await dispatch(shareFlierFunc(obj));
    if (data?.payload?.data?.status === "success") {
      setDetails((prev) => {
        return { ...prev, email: "", };
      });

      onClose && onClose();
    }
  };

  return (
    <RavenModal
      onBtnClick={() => {
        if (!validateEmail(details.email)) {
          toast.error("Invalid email address!", { position: "top-right" });
          setShowError(true);
          return;
        } else {
          handleSubmit();
        }
      }}
      loading={loadingSettlement}
      btnLabel="Share Flier"
      btnColor="black-light"
      visble={visible}
      onClose={onClose}
      className={`session-expired-wrap-modal`}
      disabled={!flier_to_use || !details?.email}
    >
      <div className="alert-receiver-modal-wrap-index">
        <div className="title-text-box">
          <p className="title">Share Flier</p>
          <p className="text grey-white-color">Enter email to share flier</p>
        </div>
        <div className="receiver-list-box">
          <ImageFragment
            key={`vbnm,sm`}
            // url={`https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg`}
            url={flier_to_use}
            loadSize={700}
            //   className={`raven-big-content-view-text-box raven-big-content-view-text-box-two`}
            className={`flier-box-wrap`}
          >
            <div className="flier-box-wrap"></div>
          </ImageFragment>
          <RavenInputField
            color="black-light"
            type="email"
            label="Email Address * "
            placeholder="john@mail.com"
            name={`email`}
            // onChange={handleChange}
            value={details?.email}
            onChange={(e) => {
              handleChange(e);
              setShowError(false);
            }}
            showError={showError}
          />
        </div>
      </div>
    </RavenModal>
  );
};

export default ShareFlierModal;
