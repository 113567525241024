import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../styles/dashboard/send-money/SendMoneyBank.css";
import infoIcon from "../../../../assets/info-black-icon.svg";
import templateCsv from "../../../../assets/raven_bulk_upload_transfer_file_format.xlsx";
// import { io } from "socket.io-client";
// import env from "../../../env";
// import CryptoJS from "crypto-js";
// import uploadIcon from "../../../../assets/upload-file-img.svg";
import {
  RavenButton,
  RavenInputField,
  RavenPagination,
  RavenTable,
  // RavenTableRow,
  RavenToolTip,
  toast,
} from "@ravenpay/raven-bank-ui";
// import ConfirmTransactionPinModal from "../ConfirmPinModal";
// import SelectScheduleBox from "../../../../components/reusables/SelectScheduleBox";
import TransactionPinModal from "../../../../components/reusables/TransactionPinModal";
import * as XLSX from "xlsx";
import {
  convertObjectToFormData,
  filterEmptyColumns,
  formatMetaData,
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  lowerCaseWrap,
  reactSelectStyle,
  returnCbnTransferFee,
  reverseArrayFunc,
  stringifyArrayOfObjects,
  sumAllNum,
} from "../../../../utils/helper/Helper";
import moment from "moment";
import SuccessModal from "../../../../components/reusables/SuccessModal";
import {
  bulkTransfer,
  fetchBanks,
  getAllSaveRecordsBank,
  requestBulkValidationProcess,
  turnOffBulkValidationProcess,
  // turnOffBulkValidationProcess,
  validateBulkTransferData,
} from "../../../../redux/transaction";
import { useDispatch, useSelector } from "react-redux";
import { getAllAccounts, getUser } from "../../../../redux/user";
// import useWindowSize from "../../../../utils/helper/UseWindowSize";
// import { formatNumWithComma } from "../../../../utils/Helpers";
import ActionContext from "../../../../context/ActionContext";
// import env from "../../../../env";
import ManualEditTable from "../bank/ManualEditTable";
import { CSVLink } from "react-csv";
import NoContentBox from "../../../../components/reusables/NoContentBox";
import NewPreviewDetailBoxAuto from "../bank/NewPrevieDetailBoxAuto";
import DescriptionPreviewBox from "../../../../components/initiator-des-box/DescriptionPreviewBox";
import { UseSocketFunction } from "../../../../utils/helper/UseSocket";
// import { UseSocketFunction } from "../../../../utils/helper/UseSocket";

const DashboardSendMoneyBulkAuto = () => {
  const [customUniqueKey, setCustomUniqueKey] = useState("");
  const actionCtx = useContext(ActionContext);
  const location = useLocation();
  const [doneValidating, setDoneValidating] = useState(false);
  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
  });
  const [fromDate, setFromDate] = useState("");
  // const [perPageVal] = useState(10);
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.transaction);
  const { user, business } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [permanentTotal, setPermanentTotal] = useState(0);
  const [step, setStep] = useState(1);
  const [bulkList, setBulkList] = useState([]);
  const [details, setDetails] = useState({
    narration: "Sent from raven",
    schedule: "",
    transfer_pin: "",
    template_title: "",
    schedule_time: "",
    source_account: "",
    description: "",
    des_imges: [],
  });
  const stepList = [
    "Transfer detail",
    "View file",
    "View validated file",
    "Confirm Transactions",
  ];

  // const SERVER = process.env.REACT_APP_SOCKET_BASE_URL;
  // const authenticated = localStorage.getItem("token");
  const headerList = [
    "S/N",
    "Account Number",
    "Bank",
    "Account Name",
    "Amount",
    "status",
  ];

  const [parsedJson, setParsedJson] = useState(0);
  let expectedFormat = {
    0: "Account Name",
    1: "Account Number",
    2: "Bank",
    3: "Bank Code",
    4: "Amount",
    5: "Narration",
  };
  const [editObj, setEditObj] = useState({
    account_number: "",
    account_name: "",
    status: "pending",
    amount: "",
    id: "",
    bank: "",
  });
  const [processingData, setProcessingData] = useState(false);
  const [editState, setEditState] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [firstList, setFirstList] = useState([]);
  const [filterVal, setFilterVal] = useState("");
  function generateUniqueId() {
    const timestamp = Date.now().toString(36); // Convert current timestamp to base36
    const randomString = Math.random().toString(36).substr(2, 5); // Generate a random string
    const uniqueId = `${timestamp}-${randomString}`;
    return uniqueId;
  }

  function handleFileUpload(file) {
    // function handleFile(file /*:File*/) {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX?.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      // console.log(rABS, wb);
      /* Convert array of arrays */
      const data = XLSX?.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      setParsedJson(data);
      // this.setState({ data: data, cols: make_cols(ws["!ref"]) });
    };
    if (rABS) reader?.readAsBinaryString(file);
    else reader?.readAsArrayBuffer(file);
  }

  useEffect(() => {
    const stillProcessing = localStorage.getItem("bank_name_validation");
    const keyUnique = localStorage.getItem("bank_name_custome_key");
    if (stillProcessing === "start" && keyUnique) {
      // socket.on("bank_name_validation")
      setCustomUniqueKey(keyUnique);
      setDoneValidating(false);
      setStep(3);
      setProcessingData(true);
      getDataAtOnce(keyUnique);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataAtOnce = async (keyId) => {
    const obj = {
      key: keyId,
    };
    const data = await dispatch(requestBulkValidationProcess(obj));
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      const dataArray = data?.payload?.data.data;
      if (dataArray?.transfer_data?.length > 0) {
        setProcessingData(false);
        setDoneValidating(true);
        localStorage.removeItem("bank_name_validation");
        localStorage.removeItem("bank_name_custome_key");
        setCustomUniqueKey("");
        const newArray = dataArray?.transfer_data?.map((chi, idx) => {
          return {
            ...chi,
            meta_data: formatMetaData(chi?.meta_data),
            num:
              dataArray?.transfer_data?.length - idx < 10
                ? `0${dataArray?.transfer_data?.length - idx}`
                : dataArray?.transfer_data?.length - idx,
            amount: formatMetaData(chi?.meta_data)?.amount,
            account_number: formatMetaData(chi?.meta_data)?.account_number,
            account_name: formatMetaData(chi?.meta_data)?.account_name,
            bank: {
              label: formatMetaData(chi?.meta_data)?.bank,
              value: formatMetaData(chi?.meta_data)?.bank_code,
            },
          };
        });
        setDoneValidating(true);
        if (getDetailNum(newArray)?.verfied > 0) {
          setFilterVal({ label: "Verified", value: "verified" });
          setFilterList(newArray);
        }
        setPermanentTotal(dataArray?.saved_data?.length);
        setFilterVal("");
        setFirstList(newArray);
        setBulkList(reverseArrayFunc(newArray));
        paginateFunc(
          reverseArrayFunc(newArray),
          actionCtx?.perPageVal?.value,
          1
        );
      }
    }
  };

  const handleChange = (e, id) => {
    if (editState) {
      const { name, value } = e.target;
      //   const obj = { ...editObj, [name]: value };
      // console.log(e.target.name);
      // console.log(editObj);
      if (name === "bank") {
        setEditObj((prev) => {
          return { ...prev, [name]: value, bank_code: value?.value };
        });
        const newListAll = bulkList?.map((chi) =>
          chi?.id === editObj?.id
            ? { ...chi, [name]: value, bank_code: value?.value }
            : chi
        );
        // console.log(newListAll);
        setBulkList(newListAll);
        paginateFunc(newListAll, actionCtx?.perPageVal?.value, 1);
      }
      setEditObj((prev) => {
        return { ...prev, [name]: value };
      });
      //   setEditObj(obj);

      if (filterVal) {
        let listToUse;
        let listToUseAll;
        if (name === "bank") {
          const newListTow = paginatedList?.dataList.map((chi) =>
            chi?.id === editObj?.id
              ? {
                  ...chi,
                  [name]: value,
                  bank_code: value?.value,
                  error_message: "",
                }
              : { ...chi, error_message: "" }
          );
          const newListAll = bulkList?.map((chi) =>
            chi?.id === editObj?.id
              ? {
                  ...chi,
                  [name]: value,
                  bank_code: value?.value,
                  error_message: "",
                }
              : { ...chi, error_message: "" }
          );
          listToUse = newListTow;
          listToUseAll = newListAll;
        } else {
          const newList = paginatedList?.dataList.map((chi) =>
            chi?.id === editObj?.id
              ? { ...chi, [name]: value, error_message: "" }
              : { ...chi, error_message: "" }
          );
          const newListAll = bulkList?.map((chi) =>
            chi?.id === editObj?.id
              ? {
                  ...chi,
                  [name]: value,
                  bank_code: value?.value,
                  error_message: "",
                }
              : { ...chi, error_message: "" }
          );
          listToUse = newList;
          listToUseAll = newListAll;
        }
        // console.log(newList);
        // setListTableToSubmit(newList);
        setBulkList(listToUseAll);
        paginateFunc(listToUse, actionCtx?.perPageVal?.value, 1);
      } else {
        let listToUse;
        if (name === "bank") {
          const newList = bulkList?.map((chi) =>
            chi?.id === editObj?.id
              ? {
                  ...chi,
                  [name]: value,
                  bank_code: value?.value,
                  error_message: "",
                }
              : { ...chi, error_message: "" }
          );
          listToUse = newList;
        } else {
          const newList = bulkList?.map((chi) =>
            chi?.id === editObj?.id
              ? { ...chi, [name]: value, error_message: "" }
              : { ...chi, error_message: "" }
          );
          listToUse = newList;
          setBulkList(listToUse);
          paginateFunc(
            listToUse,
            actionCtx?.perPageVal?.value,
            paginatedList?.current_page
          );
        }

        // console.log(newList);
        // setListTableToSubmit(newList);
      }
    } else {
      const { name, value } = e.target;
      //   const obj = { ...editObj, [name]: value };
      if (name === "bank") {
        setEditObj((prev) => {
          return { ...prev, [name]: value, bank_code: value?.value };
        });
        return;
      }
      setEditObj((prev) => {
        return { ...prev, [name]: value };
      });
      //   setEditObj(obj);
    }
  };
  const retryIcon = (
    <svg
      className="img dull-bright-filter"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16935_15530)">
        <path
          className="white-black-color-stoke"
          d="M8.63812 15.1125L6.65436 13.1288M6.65436 13.1288L8.63812 11.145M6.65436 13.1288H9.96063C12.517 13.1288 14.5894 11.0564 14.5894 8.5C14.5894 6.65351 13.5082 5.05955 11.9444 4.31666M4.00934 12.6833C2.44551 11.9404 1.36432 10.3465 1.36432 8.5C1.36432 5.94359 3.4367 3.87122 5.9931 3.87122H9.29938M9.29938 3.87122L7.31561 1.88745M9.29938 3.87122L7.31561 5.85498"
          stroke=""
          strokeWidth="1.23434"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16935_15530">
          <rect
            width="15.8701"
            height="15.8701"
            fill="white"
            transform="translate(0.0418091 0.564941)"
          />
        </clipPath>
      </defs>
    </svg>
  );
  // const location = useLocation();

  // const decryptTokenFunc = (tok) => {
  //   const encryptedToken = tok;
  //   const secretKey =
  //     "ygb0728hnw7eyhidh7t762y2bdxr6abxjbaxr6wuetyehjwu73ehuyst7gduu";

  //   // Encrypt the token
  //   const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  //   const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
  //   // console.log(tok);
  //   // console.log(decryptedToken);
  //   return decryptedToken;
  // };

  useEffect(() => {
    if (
      location.pathname.includes("/dashboard-send-money-bank-bulk-auto") &&
      processingData
    ) {
      const socket = UseSocketFunction();
      socket.on("account_number_validation", (data) => {
        // dispatch(getUser());
        // console.log("yes-----------------------", data);
        // return;
        if (
          data?.business_email === business?.email &&
          data?.custom_unique_key ===
            localStorage.getItem("bank_name_custome_key")
        ) {
          if (data?.correction_unique_key === "1") {
            const newListNum = data?.transfer_data?.map((chi, idx) => {
              return {
                ...chi,
                meta_data: formatMetaData(chi?.meta_data),
                amount: formatMetaData(chi?.meta_data)?.amount,
                raven_username: formatMetaData(chi?.meta_data)?.mail,
                account_number: formatMetaData(chi?.meta_data)?.account_number,
                account_name: formatMetaData(chi?.meta_data)?.account_name,
                bank: {
                  label: formatMetaData(chi?.meta_data)?.bank,
                  value: formatMetaData(chi?.meta_data)?.bank_code,
                },
              };
            });
            let saveDataList;
            if (bulkList?.length < 1) {
              saveDataList = data?.saved_data;
              setPermanentTotal(data?.saved_data?.length);
            }
            if (bulkList?.length > 0) {
              saveDataList = bulkList;
            }
            const replacedArray = saveDataList?.map((objToUpdate) => {
              const matchingNewObject = newListNum?.find(
                (newObj) => newObj.unique_key === objToUpdate.unique_key
              );
              return matchingNewObject || objToUpdate;
            });
            const newListNumTwo = replacedArray?.map((chi, idx) => {
              return {
                ...chi,
                num:
                  replacedArray?.length - idx < 10
                    ? `0${replacedArray?.length - idx}`
                    : replacedArray?.length - idx,
              };
            });
            // console.log(replacedArray);
            // console.log(mergeData);
            setFilterVal("");
            setFilterList([]);
            setBulkList(reverseArrayFunc(newListNumTwo));
            paginateFunc(
              reverseArrayFunc(newListNumTwo),
              actionCtx?.perPageVal?.value,
              1
            );
            if (data?.completion_status === 1) {
              setDoneValidating(true);
              setProcessingData(false);
              localStorage.removeItem("bank_name_validation");
              localStorage.removeItem("bank_name_custome_key");
              const obj = {
                key: customUniqueKey,
              };
              dispatch(turnOffBulkValidationProcess(obj));
              if (getDetailNum(newListNumTwo)?.verfied > 0) {
                setFilterVal({ label: "Verified", value: "verified" });
                setFilterList(newListNumTwo);
              }
            }
          } else {
            setPermanentTotal(data?.saved_data?.length);
            const newArray = data?.transfer_data?.map((chi, idx) => {
              return {
                ...chi,
                meta_data: formatMetaData(chi?.meta_data),
                num:
                  data?.transfer_data?.length - idx < 10
                    ? `0${data?.transfer_data?.length - idx}`
                    : data?.transfer_data?.length - idx,
                amount: formatMetaData(chi?.meta_data)?.amount,
                account_number: formatMetaData(chi?.meta_data)?.account_number,
                account_name: formatMetaData(chi?.meta_data)?.account_name,
                bank: {
                  label: formatMetaData(chi?.meta_data)?.bank,
                  value: formatMetaData(chi?.meta_data)?.bank_code,
                },
              };
            });
            // console.log(newArray);
            // return
            setFilterVal("");
            setFirstList(newArray);
            setBulkList(reverseArrayFunc(newArray));
            paginateFunc(
              reverseArrayFunc(newArray),
              actionCtx?.perPageVal?.value,
              1
            );
            if (data?.completion_status === 1) {
              setDoneValidating(true);
              setProcessingData(false);
              localStorage.removeItem("bank_name_validation");
              localStorage.removeItem("bank_name_custome_key");
              setCustomUniqueKey("");
              const obj = {
                key: customUniqueKey,
              };
              dispatch(turnOffBulkValidationProcess(obj));
              if (getDetailNum(newArray)?.verfied > 0) {
                setFilterVal({ label: "Verified", value: "verified" });
                setFilterList(newArray);
              }
            }
          }
        }
      });
      return () => {
        socket.off("account_number_validation");
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processingData]);

  const handleDelete = (id) => {
    const newList = bulkList?.filter((chi) => chi?.id !== id);
    // setListTableToSubmit(newList);
    const newListNum = newList?.map((chi, idx) => {
      return {
        ...chi,
        num:
          bulkList?.length - idx < 10
            ? `0${bulkList?.length - idx}`
            : bulkList?.length - idx,
      };
    });
    if (getDetailNum(newList)?.failed === 0) {
      setFilterVal("");
    }
    setPermanentTotal(newList?.length);
    setBulkList(reverseArrayFunc(newListNum));
    paginateFunc(
      reverseArrayFunc(newListNum),
      actionCtx?.perPageVal?.value,
      paginatedList?.current_page
    );
  };

  const handleDeleteTwo = (id) => {
    const newList = bulkList?.filter((chi) => chi?.unique_key !== id);
    // setListTableToSubmit(newList);
    const newListNum = newList?.map((chi, idx) => {
      return {
        ...chi,
        num:
          bulkList?.length - idx < 10
            ? `0${bulkList?.length - idx}`
            : bulkList?.length - idx,
      };
    });
    if (getDetailNum(newList)?.failed === 0) {
      setFilterVal("");
    }
    setPermanentTotal(newList?.length);
    setBulkList(reverseArrayFunc(newListNum));
    paginateFunc(
      reverseArrayFunc(newListNum),
      actionCtx?.perPageVal?.value,
      paginatedList?.current_page
    );
  };

  const formatList = (list) => {
    const data = list?.map((chi) => {
      return {
        ...chi,
        amount: formatNumWithoutCommaNaira(chi?.amount),
        bank: chi?.bank?.label || chi?.bank,
        account_number: String(chi?.account_number),
        bank_code: chi?.bank_code,
      };
    });
    return data;
  };

  const formatListTwo = (list) => {
    const data = list?.map((chi) => {
      return {
        ...chi,
        amount: formatNumWithoutCommaNaira(chi?.amount),
        bank: chi?.bank,
        account_number: String(chi?.account_number),
        bank_code: chi?.bank_code,
      };
    });
    return data;
  };

  const validateData = async (list, reason) => {
    if (doneValidating) {
      return;
    }

    let val;
    if (reason === "true") {
      const obj = {
        transfer_data: formatList(list),
        type: "transfer",
        correction_unique_key: 1,
        custom_unique_key: customUniqueKey,
        saved_data: formatList(bulkList),
      };
      val = obj;
    } else {
      const obj = {
        transfer_data: formatList(list),
        type: "transfer",
        custom_unique_key: customUniqueKey,
        saved_data: formatList(list),
      };
      setBulkList([]);
      val = obj;
    }
    // console.log(val);
    // if(filterVal?.value === "failed"){
    //   console.log(val);
    //   return
    // }
    // console.log(val);
    // return

    if (!filterVal) {
      setPermanentTotal(val?.transfer_data?.length);
    }
    // console.log(val);
    
    // return
    const data = await dispatch(validateBulkTransferData(val));
    if (data?.payload?.data?.status === "success") {
      localStorage.setItem("bank_name_validation", "start");
      localStorage.setItem("bank_name_custome_key", customUniqueKey);
    } else {
      setDoneValidating(false);
      setProcessingData(false);
      setStep(2);
    }
    // console.log(obj);
  };

  useEffect(() => {
    if (parsedJson?.length > 2) {
      // console.log("222");
      if (
        expectedFormat[0] !== parsedJson[0][0] &&
        expectedFormat[1] !== parsedJson[1][1] &&
        expectedFormat[2] !== parsedJson[2][2] &&
        expectedFormat[3] !== parsedJson[3][3] &&
        expectedFormat[4] !== parsedJson[4][4]
      ) {
        toast.error(
          "Your bulk file is not a valid excel format, Download and use the template below."
        );
      } else {
        //  filter the array for empty objects
        // console.log("333");
        if (parsedJson.length > 1) {
          const filteredJson = parsedJson?.filter((element) => {
            // console.log(element, "elementi");
            if (Object.keys(element)?.length > 4) {
              return true;
            }

            return false;
          });

          // structure data for consumption
          const structuredData = filteredJson?.map(
            ({
              0: account_name,
              1: account_number,
              2: bank,
              3: bank_code,
              4: amount,
            }) => ({
              account_name,
              account_number,
              bank,
              bank_code,
              amount,
            })
          );
          // console.log(structuredData);
          const removeIndexOne = structuredData?.filter(
            (chi, index) => index !== 0
          );
          if (removeIndexOne?.length > 0) {
            const newList = removeIndexOne?.map((chi, idx) => {
              return {
                ...chi,
                unique_key: generateUniqueId(),
                id: generateUniqueId(),
                currency: "NGN",
                num: idx < 9 ? `0${idx + 1}` : idx + 1,
                bank: { label: chi?.bank, value: chi?.bank_code },
                status: "pending",
                account_number: String(chi?.account_number)?.length === 9 ? `0${chi?.account_number}` : chi?.account_number
              };
            });
            // // console.log(removeIndexOne);
            // console.log(newList);
            // return
            const uniqueKey = generateUniqueId();
            setCustomUniqueKey(uniqueKey);
            setStep(2);
            setDoneValidating(false);
            setPermanentTotal(newList?.length);
            // setBulkList(newList);
            // paginateFunc(newList, actionCtx?.perPageVal?.value, 1);
            performActionWithDuplicateCheckWithValidate(
              formatListTwo(newList),
              "account_number"
            );
          } else {
            toast.error("No data found in uploaded file");
            // console.log(structuredData);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedJson]);

  function getExtension(filename) {
    return filename.split(".").pop();
  }
  const [paginatedList, setPaginatedList] = useState({
    current_page: "",
    per_page: "",
    total: "",
    total_pages: "",
    dataList: [],
  });

  useEffect(() => {
    paginateFunc(bulkList, "", paginatedList?.current_page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVal]);

  function paginateFunc(array, page_size, current_page) {
    const start_index =
      (current_page - 1) * Number(actionCtx?.perPageVal?.value);
    const end_index = start_index + Number(actionCtx?.perPageVal?.value);
    const paginatedData = handleListToShowByStatus(array)?.slice(
      start_index,
      end_index
    );
    const info = {
      current_page: current_page,
      per_page: Number(actionCtx?.perPageVal?.value),
      total: handleListToShowByStatus(array)?.length,
      total_pages: Math?.ceil(
        handleListToShowByStatus(array)?.length /
          Number(actionCtx?.perPageVal?.value)
      ),
      dataList: paginatedData,
    };
    // console.log(info);
    setPaginatedList((prev) => {
      return {
        ...prev,
        current_page: info?.current_page,
        per_page: info?.per_page,
        total: info?.total,
        total_pages: info?.total_pages,
        dataList: info?.dataList,
      };
    });
  }

  // const avatarFormatWrap = (param) => {
  //   return (
  //     <div className="text-avatar-box">
  //       <div className="avatar-box">
  //         <span>{`${param?.split(" ")[0]?.charAt(0)} ${
  //           param?.split(" ")[1]?.charAt(0) || ""
  //         }`}</span>
  //       </div>
  //       <p className="text">{param}</p>
  //     </div>
  //   );
  // };

  const getAllAmount = (list) => {
    if (list.length > 0) {
      const amountList = list.map((chi) =>
        Number(formatNumWithoutCommaNaira(chi?.amount))
      );
      const sumList = sumAllNum(amountList);
      return sumList;
    }
  };

  const handleSubmit = async (pin, token) => {
    const newList = handleListToShowByStatus(bulkList)?.map((chi) => {
      return {
        currency: "NGN",
        bank_code: chi?.bank?.value || chi?.bank_code,
        bank: chi?.bank?.label,
        amount: formatNumWithoutCommaNaira(chi?.amount),
        account_number: chi?.account_number,
        account_name: chi?.account_name,
      };
    });
    // const filterList = newList.filter((chi) => {
    //   return
    // })
    // console.log(newList);
    const obj = {
      transfer_data: stringifyArrayOfObjects(newList),
      transfer_pin: pin,
      two_factor_token: token,
      template_title: details?.template_title,
      narration: details?.narration,
      schedule: fromDate ? 1 : 0,
      schedule_time: fromDate
        ? moment(fromDate).format("YYYY-MM-DD HH:mm:ss")
        : "",
      selected_account_number:
        details?.source_account?.account_number ||
        actionCtx?.currentAccount?.account_number,
      description: details?.description,
      document: details?.des_imges,
    };
    // console.log(obj);
    // return;
    const data = await dispatch(
      bulkTransfer(convertObjectToFormData(filterEmptyColumns(obj)))
    );
    // console.log(data);
    if (data?.payload?.data?.status === "success") {
      handleFinish();
    }
  };

  const handleFinish = () => {
    setShowModal((prev) => {
      return { ...prev, pin: false, success: true };
    });
    dispatch(getUser());
    dispatch(getAllAccounts());
    setBulkList([]);
    setStep(1);
    setDetails({
      narration: "Sent from raven",
      schedule: "",
      transfer_pin: "",
      template_title: "",
      schedule_time: "",
      description: "",
      des_imges: [],
    });
    navigate("/dashboard-overview");
  };

  // const size = useWindowSize();

  const filterOption = [
    { label: "All", value: "all" },
    { label: "Verified", value: "verified" },
    { label: "Failed", value: "failed" },
  ];

  const handleFilter = (param) => {
    if (param === "all") {
      paginateFunc(bulkList, actionCtx?.perPageVal?.value, 1);
      setFilterList(bulkList);
    }
    if (param === "failed") {
      const newList = bulkList?.filter((chi) => chi?.status === 2);
      paginateFunc(newList, bulkList?.length, 1);
      setFilterList(newList);
    }
    if (param === "verified") {
      const newList = bulkList?.filter((chi) => chi?.status === 1);
      paginateFunc(newList, bulkList?.length, 1);
      setFilterList(newList);
    }
  };

  const checkIfFailedExist = (list) => {
    if (list?.length > 0) {
      const check = list?.some((chi) => chi?.status === 2);
      // console.log(check);
      return check;
    }
  };

  const getDetailNum = (list) => {
    const failedNum = list?.filter((chi) => chi?.status === 2);
    const verifyNum = list?.filter((chi) => chi?.status === 1);
    const allVerifiedAmount = verifyNum?.map((chi) => {
      return Number(formatNumWithoutCommaNaira(chi?.amount));
    });
    // console.log(sumAllNum(allVerifiedAmount));
    return {
      failed: failedNum?.length || "0",
      verfied: verifyNum?.length || "0",
      verifyNumSum: sumAllNum(allVerifiedAmount),
      verifyList: verifyNum,
      failedList: failedNum,
    };
  };

  const handleListToShowByStatus = (list) => {
    if (list?.length > 0) {
      let listToReturn;
      if (filterVal?.value === "failed") {
        const newList = list?.filter((chi) => chi?.status === 2);
        listToReturn = newList;
      }
      if (filterVal?.value === "verified") {
        const newList = list?.filter((chi) => chi?.status === 1);
        listToReturn = newList;
      }
      if (filterVal?.value === "all" || !filterVal) {
        listToReturn = list;
      }
      return listToReturn;
    }
  };

  useEffect(() => {
    dispatch(fetchBanks());
    dispatch(getAllSaveRecordsBank());
    // console.log("987654----------------------------");
    // getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportTableIcon = (
    <svg
      className="img "
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dull-bright-filter"
      />
    </svg>
  );

  const CsvHeader = [
    { label: "S/N", key: "num" },
    { label: "Account Name", key: "account_name" },
    { label: "Bank", key: "bank" },
    { label: "Account Number", key: "account_number" },
    { label: "Amount", key: "amount" },
    { label: "Status", key: "status" },
  ];

  const formatExportData = (data) => {
    const list = data?.map((chi, idx) => {
      const { status, meta_data } = chi;
      return {
        num: chi?.num || idx < 10 ? `0${idx + 1}` : idx + 1,
        amount: formatNumWithCommaNairaSymbol(meta_data?.amount),
        account_number: meta_data?.mail || meta_data?.account_number,
        bank: meta_data?.mail || meta_data?.bank,
        account_name: meta_data?.mail || meta_data?.account_name,
        status: status === 0 ? "Pending" : status === 1 ? "Verified" : "Failed",
      };
    });
    // console.log(data);
    return list;
  };

  const addAllsumFunc = () => {
    let listToUse;
    if (filterVal?.value === "verified") {
      listToUse = paginatedList?.dataList;
    } else {
      listToUse = bulkList;
    }
    const sumVal = listToUse?.map((chi) =>
      returnCbnTransferFee(
        user?.transfer_fee_details?.transfer_fee,
        Number(formatNumWithoutCommaNaira(chi?.amount))
      )
    );
    const sumValTotal = sumAllNum(sumVal);
    return formatNumWithCommaNairaSymbol(sumValTotal);
  };

  const arrowRightIcon = (
    <svg
      className="img white-black-color-stoke"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 12H20M20 12L14 6M20 12L14 18"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  function performActionWithDuplicateCheckWithValidate(array, columnName) {
    const lowerCaseValues = {};
    // let val;
    // console.log(array);
    for (let i = 0; i < array.length; i++) {
      const columnValue = formatNumWithoutCommaNaira(
        array[i][columnName]?.toLowerCase()
      );

      if (lowerCaseValues[columnValue] !== undefined) {
        toast.error(
          `Duplicate found for account number '${columnValue}' at number ${
            i + 1 < 10 ? `0${i + 1}` : i + 1
          } and ${
            lowerCaseValues[columnValue] + 1 < 10
              ? `0${lowerCaseValues[columnValue] + 1}`
              : lowerCaseValues[columnValue] + 1
          }`
        );
        const newList = array?.map((chi) =>
          formatNumWithoutCommaNaira(chi?.account_number) ===
          formatNumWithoutCommaNaira(columnValue)
            ? { ...chi, error_message: "Duplicate account number" }
            : chi
        );
        setBulkList(newList);
        paginateFunc(newList, actionCtx?.perPageVal?.value, 1);
        return; // Show error toast for duplicate and return early
      } else {
        lowerCaseValues[columnValue] = i;
      }
    }

    setBulkList(array);
    paginateFunc(array, actionCtx?.perPageVal?.value, 1);
  }

  useEffect(() => {
    if (
      actionCtx?.currentAccount?.authorization_level !==
        ("initiator_approver" || "initiator") &&
      actionCtx?.currentAccount?.authorization_level !==
        ("initiator" || "initiator_approver")
    ) {
      navigate("/dashboard-send-money");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardLayout>
        {/* title box start */}
        <TitletextBox
          title={`Bulk Transfer`}
          text={`Send funds to a multiple users at once.`}
          back
          nextHoverText={
            step === 3
              ? filterVal.value === "verified"
                ? `Proceed with ${filterVal?.label} list`
                : "Procced with all list"
              : step === 2
              ? "Proceed to validation"
              : ""
          }
          onBack={() => {
            navigate(`/dashboard-send-money`);
          }}
          next={
            step === 1 && bulkList?.length < 1
              ? false
              : step === 4
              ? false
              : editState
              ? false
              : filterVal && filterVal?.value === "failed"
              ? false
              : getDetailNum(bulkList)?.failed > 0 &&
                step === 3 &&
                filterVal?.value !== "verified"
              ? false
              : !processingData
              ? step === 2 || step === 3
              : false
          }
          onNext={() => {
            if (step === 2) {
              setStep(3);
              setProcessingData(true);
              validateData(bulkList);
              if (filterVal || filterList?.length > 0) {
                setFirstList([]);
                setBulkList([]);
              }
              return;
            }
            if (step === 3) {
              if (filterVal?.value === "verified") {
                setStep(4);
              }
              if (filterVal?.value === "all" || !filterVal) {
                if (checkIfFailedExist(bulkList)) {
                  toast.error(
                    "List contain unverified details, filter by 'Verify' to proceed with verified data or filter by 'Failed' to retry verification of failed data. "
                  );
                } else {
                  setStep(4);
                }
              }
            }
          }}
        />
        {/* title box end */}
        <ContainerWrapper
          width={`100%`}
          back={step > 1}
          onBack={() => {
            step > 1 && setStep(step - 1);
          }}
          stepClickable={bulkList?.length > 0}
          onStepClick={(e) => {
            if (!doneValidating) {
              return;
            }
            bulkList?.length > 0 && setStep(e);
          }}
          activeStep={step}
          stepList={stepList}
          btnText={step === 4 && `Complete Transfer`}
          onBtnClick={() => {
            setShowModal((prev) => {
              return { ...prev, pin: true };
            });
          }}
          disabled={
            step === 4 &&
            actionCtx?.currentAccount?.authorization_level === "initiator" &&
            !details?.description
              ? true
              : step === 4 && details?.save_template && !details?.template_title
              ? true
              : false
          }
          pagination={
            paginatedList?.dataList?.length > 0 &&
            step === 2 &&
            firstList?.length < 1 ? (
              <RavenPagination
                color={`black-light`}
                blackHover
                currentPage={paginatedList?.current_page}
                totalPage={paginatedList?.total_pages}
                onNumView={(num) =>
                  paginateFunc(bulkList, actionCtx?.perPageVal?.value, num)
                }
                prevPage={paginatedList?.current_page > 1}
                nextPage={
                  paginatedList?.current_page < paginatedList?.total_pages
                }
                onNext={() =>
                  paginateFunc(
                    bulkList,
                    actionCtx?.perPageVal?.value,
                    paginatedList?.current_page + 1
                  )
                }
                onPrev={() =>
                  paginateFunc(
                    bulkList,
                    actionCtx?.perPageVal?.value,
                    paginatedList?.current_page - 1
                  )
                }
              />
            ) : paginatedList?.dataList?.length > 0 &&
              step === 3 &&
              firstList?.length > paginatedList?.per_page ? (
              <RavenPagination
                color={`black-light`}
                blackHover
                currentPage={paginatedList?.current_page}
                totalPage={paginatedList?.total_pages}
                onNumView={(num) => {
                  !processingData &&
                    paginateFunc(bulkList, actionCtx?.perPageVal?.value, num);
                }}
                prevPage={paginatedList?.current_page > 1}
                nextPage={
                  paginatedList?.current_page < paginatedList?.total_pages
                }
                onNext={() =>
                  !processingData &&
                  paginateFunc(
                    bulkList,
                    actionCtx?.perPageVal?.value,
                    paginatedList?.current_page + 1
                  )
                }
                onPrev={() =>
                  !processingData &&
                  paginateFunc(
                    bulkList,
                    actionCtx?.perPageVal?.value,
                    paginatedList?.current_page - 1
                  )
                }
              />
            ) : (
              ""
            )
          }
        >
          <div
            style={{ paddingBottom: filterVal ? "unset" : "6rem" }}
            className="send-money-wrap-box-bank-auto send-money-bulk-transfer-new-verify"
          >
            {/* step 1 start ----------------------------------- */}
            {step === 1 && (
              <>
                <div className="upload-doc-wrap">
                  {/* download info box start */}
                  <div className="download-info-box grey-bg">
                    <div className="text-icon-box">
                      <figure className="img-box">
                        <img src={infoIcon} alt="" className="img" />
                      </figure>
                      <p
                        style={{ display: "flex", gap: ".6rem" }}
                        className="text grey-white-color"
                      >
                        Getting started with bulk transfer{" "}
                        <a
                          href={templateCsv}
                          download="raven_bulk_upload_transfer_file_format.xlsx"
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          {" "}
                          <span>Download sample Template</span>
                        </a>
                      </p>
                    </div>
                    <div className="dot-text">
                      <p className="dot grey-white-color">•</p>
                      <p className="text grey-white-color">
                        You can upload .xlsx or .csv file
                      </p>
                    </div>
                    <div className="dot-text">
                      <p className="dot grey-white-color">•</p>
                      <p className="text grey-white-color">
                        Make sure your file has correct column headers such as{" "}
                        <span>
                          Bank Account No,Bank Name, Account Name and Amount
                        </span>
                      </p>
                    </div>
                    <div className="dot-text">
                      <p className="dot grey-white-color">•</p>
                      <p className="text grey-white-color">
                        Numbers of row should not exceed 1,000
                      </p>
                    </div>
                  </div>
                  {/* download info box end */}
                  {/* upload box start */}
                  <div className="upload-wrapper">
                    {/* <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://docs.google.com/spreadsheets/d/1g0RuZwumoX3qVKfT79J5tDw8Yc2h9_cf/edit?usp=sharing&ouid=114408454696079379125&rtpof=true&sd=true"
                    >
                      {" "}
                      <p className="upload-text-pro">Use Online Template</p>
                    </a> */}
                    <p className="upload-text-pro">Use Older Template</p>
                    <RavenInputField
                      onChange={(e) => {
                        getExtension(e["name"]) === "xlsx"
                          ? handleFileUpload(e)
                          : toast.error(
                              "File format not supported, Kindly download supportted format above"
                            );
                      }}
                      value=""
                      type={`upload-two`}
                      color={`black-light`}
                    />
                  </div>
                  {/* upload box end */}
                </div>
              </>
            )}
            {/* step 1 end ----------------------------------- */}
            {/* step 2 start ----------------------------------- */}
            {step === 2 && (
              <>
                <div
                  className="table-wrapper-bulk-transfer table-wrapper-bulk-transfer-raven-bulk"
                  // style={{ padding: "unset" }}
                >
                  <div className="filter-export-processing-wrap">
                    {/* name numb box start */}
                    <div className="name-numb-box">
                      <p className="text">
                        {`All Recipient(s)`}{" "}
                        <span className="grey-bg-two">
                          {permanentTotal || "0"}
                        </span>{" "}
                      </p>
                      {/* <p className="text">
                        {`Verified Recipient(s)`}{" "}
                        <span className="verified grey-bg-two">
                          {getDetailNum(bulkList)?.verfied}
                        </span>{" "}
                      </p>
                      <p className="text">
                        {`Failed Recipient(s)`}{" "}
                        <span className="failed grey-bg-two">
                          {getDetailNum(bulkList)?.failed}
                        </span>{" "}
                      </p> */}
                    </div>
                    {/* name numb box end */}
                    {/* export table content start */}
                    <CSVLink
                      // ref={download}
                      className="export-table-content-box tooltip-hover-wrap grey-bg"
                      filename={"Raven Business Banking Transfer list.csv"}
                      data={
                        formatExportData(
                          filterList?.length > 0 ? filterList : bulkList
                        ) || ""
                      }
                      headers={CsvHeader}
                    >
                      {" "}
                      <RavenToolTip
                        color="black-light"
                        text="Export Table Content"
                        position={`right`}
                      />
                      <figure className="img-box">{exportTableIcon}</figure>
                    </CSVLink>
                    {/* export table content endd */}
                    {!doneValidating && (
                      <div className="retry-wrap-icon">
                        <RavenButton
                          onClick={() => {
                            setStep(3);
                            setProcessingData(true);
                            validateData(bulkList);
                          }}
                          className="raven-btn"
                          // size={`small`}
                          color="black-light"
                        >
                          {" "}
                          <span>Proceed for validation</span>
                          <figure className="img-box">{arrowRightIcon}</figure>
                        </RavenButton>
                      </div>
                    )}
                  </div>
                  <div className="table-wrap ">
                    <RavenTable
                      className={
                        formatMetaData(user?.theme_display_settings)
                          ?.table_font === "bold" && "table-business"
                      }
                      headerList={headerList}
                      action
                    >
                      {processingData ? (
                        <></>
                      ) : (
                        <>
                          {" "}
                          {paginatedList?.dataList?.map((chi, idx) => {
                            const {
                              amount,
                              num,
                              id,
                              account_number,
                              account_name,
                              bank,
                              unique_key,
                            } = chi;
                            return (
                              <ManualEditTable
                                key={id || idx || num}
                                one={num}
                                two={account_number}
                                four={amount}
                                six={bank}
                                five={"pending"}
                                three={lowerCaseWrap(account_name)}
                                errorMessage={chi?.error_message}
                                onEditFininsh={() => {
                                  // setError((prev) => {
                                  //   return { ...prev, account_number: false };
                                  // });
                                  setEditState(false);
                                  setEditObj({
                                    account_number: "",
                                    account_name: "",
                                    status: "pending",
                                    amount: "",
                                    id: "",
                                  });
                                  // handleSetEditChild();
                                }}
                                showEditButton
                                editMode={editObj?.id === id}
                                handleChange={(e) => {
                                  // console.log(e);
                                  handleChange(e, id);
                                }}
                                // loading={editObj?.id === id}
                                // showError={error?.account_number && editObj?.id === id}
                                // setError={(e) => {
                                //   error?.account_number && setError(e);
                                // }}
                                selectId={unique_key}
                                onDelete={() => {
                                  handleDelete(id);
                                }}
                                onEdit={() => {
                                  setEditState(true);
                                  setEditObj(chi);
                                }}
                              />
                              // <RavenTableRow
                              //   key={idx}
                              //   one={raven_username}
                              //   two={amount}
                              // />
                            );
                          })}
                        </>
                      )}
                    </RavenTable>
                    {/* button start */}

                    {/* button end */}
                  </div>

                  {/* pagination start */}
                  {/* <div className="table-pagination-box">
                    <RavenPagination
                      color={`black-light`}
                      blackHover
                      currentPage={1}
                      totalPage={7}
                    />
                  </div> */}
                  {/* pagination end */}
                </div>
              </>
            )}
            {/* step 2 end ----------------------------------- */}
            {/* step 3 start ----------------------------------- */}
            {step === 3 && (
              <>
                <div
                  className="table-wrapper-bulk-transfer table-wrapper-bulk-transfer-raven-bulk"
                  // style={{ padding: "unset" }}
                >
                  {/* filter export processing wrap start */}
                  {firstList?.length > 0 && (
                    <div className="filter-export-processing-wrap">
                      {/* name numb box start */}
                      <div
                        className="name-numb-box"
                        style={{ marginRight: processingData && "auto" }}
                      >
                        <p
                          onClick={() => {
                            setFilterVal({ label: "All", value: "all" });
                            handleFilter("all");
                          }}
                          className="text"
                        >
                          {`All Recipient(s)`}{" "}
                          <span className="grey-bg-two">
                            {permanentTotal || "0"}
                          </span>{" "}
                        </p>
                        <p
                          onClick={() => {
                            setFilterVal({
                              label: "Verified",
                              value: "verified",
                            });
                            handleFilter("verified");
                          }}
                          className="text"
                        >
                          {`Verified `}{" "}
                          <span className="verified grey-bg-two">
                            {getDetailNum(bulkList)?.verfied}
                          </span>{" "}
                        </p>
                        <p
                          onClick={() => {
                            setFilterVal({ label: "Failed", value: "failed" });
                            handleFilter("failed");
                          }}
                          className="text"
                        >
                          {`Failed `}{" "}
                          <span className="failed grey-bg-two">
                            {getDetailNum(bulkList)?.failed}
                          </span>{" "}
                        </p>
                      </div>
                      {/* name numb box end */}
                      {/* Processing content start */}
                      {processingData && (
                        <>
                          {/* filter  wrap start*/}
                          <form
                            action=""
                            autoComplete="off"
                            className="filter-wrap-box"
                          >
                            <RavenInputField
                              // disabled={processingData}
                              selectStyles={reactSelectStyle}
                              onChange={(e) => {
                                setFilterVal(e);
                                handleFilter(e.value);
                              }}
                              selectOption={filterOption}
                              value={filterVal}
                              color="black-light"
                              type="select"
                              placeholder="Filter by Status"
                            />
                          </form>
                          {/* filter  wrap end*/}{" "}
                          <div className="processing-content-box">
                            {processingData && (
                              <RavenButton
                                loaderText="Validating"
                                label=""
                                loading={true}
                                // size={`small`}
                                color={`black-light`}
                              />
                            )}
                          </div>
                        </>
                      )}
                      {/* processing content end */}
                      {
                        <>
                          {!processingData && (  
                            <>
                              {" "}
                              {/* export table content start */}
                              <CSVLink
                                // ref={download}
                                className="export-table-content-box tooltip-hover-wrap grey-bg"
                                filename={
                                  "Raven Business Banking Transfer list.csv"
                                }
                                data={
                                  formatExportData(
                                    handleListToShowByStatus(bulkList)
                                  ) || ""
                                }
                                headers={CsvHeader}
                              >
                                {" "}
                                <RavenToolTip
                                  color="black-light"
                                  text="Export Table Content"
                                  position={`right`}
                                />
                                <figure className="img-box">
                                  {exportTableIcon}
                                </figure>
                              </CSVLink>
                              {/* export table content endd */}
                              {/* filter  wrap start*/}
                              <form
                                action=""
                                autoComplete="off"
                                className="filter-wrap-box"
                              >
                                <RavenInputField
                                  disabled={processingData}
                                  selectStyles={reactSelectStyle}
                                  onChange={(e) => {
                                    setFilterVal(e);
                                    handleFilter(e.value);
                                  }}
                                  selectOption={filterOption}
                                  value={filterVal}
                                  color="black-light"
                                  type="select"
                                  placeholder="Filter by Status"
                                />
                              </form>
                              {/* filter  wrap end*/}
                              {getDetailNum(bulkList)?.failed > 0 &&
                              !processingData &&
                              filterVal.value !== "verified" ? (
                                <div className="retry-wrap-icon">
                                  <RavenButton
                                    onClick={() => {
                                      const newList = getDetailNum(
                                        bulkList
                                      )?.failedList?.map((chi) => {
                                        // console.log(chi);
                                        return {
                                          bank: chi?.bank,
                                          bank_code: chi?.bank?.value,
                                          amount: formatNumWithoutCommaNaira(
                                            chi?.amount
                                          ),
                                          unique_key: chi?.unique_key,
                                          account_number: String(
                                            chi?.account_number
                                          ),
                                          currency: "NGN",
                                        };
                                      });
                                      // console.log(newList, "------------");
                                      // return
                                      setProcessingData(true);
                                      validateData(newList, "true");
                                      setFilterList([]);
                                    }}
                                    className="raven-btn"
                                    // size={`small`}
                                    color="black-light"
                                  >
                                    {" "}
                                    <span>Retry failed validation</span>
                                    <figure className="img-box">
                                      {retryIcon}
                                    </figure>
                                  </RavenButton>
                                </div>
                              ) : (
                                ""
                              )}
                              {/* retyr wrap end */}
                              {/* retyr wrap start */}
                              {getDetailNum(bulkList)?.failed < 1 &&
                              !processingData &&
                              !filterVal ? (
                                <div className="retry-wrap-icon">
                                  <RavenButton
                                    onClick={() => {
                                      setStep(4);
                                    }}
                                    className="raven-btn"
                                    // size={`small`}
                                    color="black-light"
                                  >
                                    {" "}
                                    <span>Confirm Transaction</span>
                                    <figure className="img-box">
                                      {arrowRightIcon}
                                    </figure>
                                  </RavenButton>
                                </div>
                              ) : (
                                ""
                              )}
                              {filterVal?.value === "verified" && filterVal ? (
                                <div className="retry-wrap-icon">
                                  <RavenButton
                                    onClick={() => {
                                      setStep(4);
                                    }}
                                    className="raven-btn"
                                    // size={`small`}
                                    color="black-light"
                                  >
                                    {" "}
                                    <span>Confirm Transaction</span>
                                    <figure className="img-box">
                                      {arrowRightIcon}
                                    </figure>
                                  </RavenButton>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </>
                      }
                    </div>
                  )}
                  {/* filter export processing wrap end */}
                  {processingData && firstList.length < 1 ? (
                    <>
                      <div
                        style={{
                          width: "100%",
                          minHeight: "60rem",
                          display: "grid",
                          placeItems: "center",
                          // backgroundColor: "blue"
                        }}
                        className="spinner-raven-logo-wrap"
                      >
                        <NoContentBox
                          loading={true}
                          // title={`No Sub Account`}
                          // text="Add Sub Account by clicking on the Create a sub account button above"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {filterVal && filterList?.length < 1 ? (
                        <>
                          {" "}
                          <NoContentBox
                            loading={processingData}
                            title={`No ${filterVal?.label} Transfer detail found`}
                            text="Select all filter or reupload data to populate list for your transation"
                          />
                        </>
                      ) : (
                        <div
                          style={{ paddingBottom: "10rem" }}
                          className="table-wrap"
                        >
                          <RavenTable
                            className={
                              formatMetaData(user?.theme_display_settings)
                                ?.table_font === "bold" && "table-business"
                            }
                            headerList={headerList}
                            action
                          >
                            {paginatedList?.dataList?.map((chi, idx) => {
                              const {
                                amount,
                                num,
                                id,
                                account_number,
                                account_name,
                                bank,
                                status,
                              } = chi;
                              return (
                                <ManualEditTable
                                  key={id || idx || num}
                                  one={num}
                                  errorMessage={chi?.error_message}
                                  two={account_number}
                                  four={amount}
                                  six={bank}
                                  five={status === 1 ? "verified" : "failed"}
                                  three={lowerCaseWrap(account_name) || "---"}
                                  processLoading={processingData}
                                  onEditFininsh={() => {
                                    // setError((prev) => {
                                    //   return { ...prev, account_number: false };
                                    // });
                                    setEditState(false);
                                    setEditObj({
                                      account_number: "",
                                      account_name: "",
                                      status: "pending",
                                      amount: "",
                                      id: "",
                                    });
                                    // handleSetEditChild();
                                  }}
                                  showEditButton
                                  editMode={editObj?.id === id}
                                  handleChange={(e) => handleChange(e, id)}
                                  // loading={editObj?.id === id}
                                  // showError={error?.account_number && editObj?.id === id}
                                  // setError={(e) => {
                                  //   error?.account_number && setError(e);
                                  // }}
                                  selectId={idx}
                                  onDelete={() => {
                                    handleDelete(id);
                                  }}
                                  onEdit={() => {
                                    setEditState(true);
                                    setEditObj(chi);
                                  }}
                                />
                                // <RavenTableRow
                                //   key={idx}
                                //   one={raven_username}
                                //   two={amount}
                                // />
                              );
                            })}
                          </RavenTable>
                        </div>
                      )}
                    </>
                  )}

                  {/* button start */}
                  {/* <div className="btn-upload-box">
                    {!filterVal && !processingData && (
                      <RavenButton
                        onClick={() => {
                          if (step === 2) {
                            setStep(3);
                            setProcessingData(true);
                            validateData(bulkList);
                            if (filterVal || filterList?.length > 0) {
                              setFirstList([]);
                              setBulkList([]);
                            }
                            return;
                          }
                          if (step === 3) {
                            if (filterVal?.value === "verified") {
                              setStep(4);
                            }
                            if (filterVal?.value === "all" || !filterVal) {
                              if (checkIfFailedExist(bulkList)) {
                                toast.error(
                                  "List contain unverified details, filter by 'Verify' to proceed with verified data or filter by 'Failed' to retry verification of failed data. "
                                );
                              } else {
                                setStep(4);
                              }
                            }
                          }
                        }}
                        label={`Proceed`}
                        color={`black-light`}
                      />
                    )}
                  </div> */}
                  {/* button end */}
                </div>
              </>
            )}
            {/* step 3 end ----------------------------------- */}
            {/* step 4 start ----------------------------------- */}
            {step === 4 && (
              <>
                <>
                  <div
                    style={{
                      margin: "2rem auto",
                      paddingBottom: "20rem",
                      display: "flex",
                      flexDirection: "column",
                      gap: "2rem",
                    }}
                    className=""
                  >
                    {" "}
                    <NewPreviewDetailBoxAuto
                      onReset={() => setFromDate("")}
                      valueSchedule={fromDate}
                      onChangeSchedule={(e) => {
                        setFromDate(e);
                      }}
                      value={details?.beneficiary}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, beneficiary: e.target.checked };
                        });
                        //  handleAddBeneficiaryAll(e.target.checked);
                      }}
                      list={
                        filterVal?.value === "verified"
                          ? getDetailNum(bulkList)?.verifyList
                          : bulkList
                      }
                      collapse
                      bulkAmount={
                        filterVal?.value === "verified"
                          ? getDetailNum(bulkList)?.verifyNumSum
                          : bulkList.length > 0
                          ? formatNumWithCommaNairaSymbol(
                              getAllAmount(bulkList)
                            )
                          : formatNumWithCommaNairaSymbol(0)
                      }
                      handleRemoveFromList={(obj) => {
                        handleDeleteTwo(obj?.unique_key);
                        // console.log(obj);
                        
                      }}
                      account_name={details?.raven_username}
                      account_num={details?.account_num}
                      bank={details?.bank?.label}
                      amount={formatNumWithoutCommaNaira(details?.amount)}
                      name={details?.raven_username}
                      narration={details?.narration}
                      checked={details?.beneficiary}
                      totalFee={
                        filterVal?.value === "verified"
                          ? user?.transfer_fee_details?.transfer_fee_style ===
                            "cbn"
                            ? addAllsumFunc()
                            : formatNumWithCommaNairaSymbol(
                                user?.transfer_fee_details?.transfer_fee?.flat *
                                  getDetailNum(bulkList)?.verfied
                              )
                          : user?.transfer_fee_details?.transfer_fee_style ===
                            "cbn"
                          ? addAllsumFunc()
                          : formatNumWithCommaNairaSymbol(
                              user?.transfer_fee_details?.transfer_fee?.flat *
                                bulkList?.length
                            )
                      }
                      onViewAll={() => setStep(3)}
                      details={details}
                      setDetails={setDetails}
                      handleChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, narration: e.target.value };
                        });
                      }}
                      onSourceSelect={(param) => {
                        setDetails((prev) => {
                          return { ...prev, source_account: param };
                        });
                      }}
                      sourceAccount={
                        details?.source_account || actionCtx?.currentAccount
                      }
                    />
                    {/* initiar attachment start */}
                    {actionCtx?.currentAccount?.authorization_level ===
                      "initiator" && (
                      <>
                        <div
                          style={{
                            maxWidth: "40rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: "2rem",
                          }}
                          className="initiator-input-box-wrapper"
                        >
                          <RavenInputField
                            placeholder="Enter description"
                            color="black-light"
                            label="Add description *"
                            name="description"
                            onChange={(e) => {
                              setDetails((prev) => {
                                return { ...prev, description: e.target.value };
                              });
                            }}
                            value={details?.description}
                          />
                          {/* add and preview images box start */}
                          <DescriptionPreviewBox
                            onAdd={(param) => {
                              const newList = [param, ...details?.des_imges];
                              setDetails((prev) => {
                                return { ...prev, des_imges: newList };
                              });
                            }}
                            imgList={details?.des_imges}
                            onRemove={(param) => {
                              setDetails((prev) => {
                                return { ...prev, des_imges: param };
                              });
                            }}
                            onView={(param) => {
                              actionCtx.setImgPreviewVal(
                                URL.createObjectURL(param)
                              );
                            }}
                          />
                          {/* add and preview images box end */}
                        </div>
                      </>
                    )}
                    {/* intitiator attachment end */}
                  </div>
                </>
              </>
            )}
            {/* step 4 end ----------------------------------- */}
          </div>
        </ContainerWrapper>
      </DashboardLayout>
      {/* pin modal start */}

      <TransactionPinModal
        pin={details?.transfer_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: e };
          });
        }}
        onPay={(pin, token) => {
          // console.log(pin, token);
          handleSubmit(pin, token);
        }}
        visible={showModal.pin}
        onCancel={() =>
          setShowModal((prev) => {
            return { ...prev, pin: false };
          })
        }
        loading={loading}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Transaction Successful."
        text={`You should also receive a confirmation email with the details of the transaction. We appreciate your business and look forward to serving you in the future`}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default DashboardSendMoneyBulkAuto;
