import {
  // RavenButton,
  RavenInputField,
  RavenModal,
} from "@ravenpay/raven-bank-ui";
import React, { useState, useRef, useEffect } from "react";
import "../../styles/reusables/DeleteModal.css";
import { useSelector } from "react-redux";

const TransactionPinModal = ({
  visible,
  text,
  title,
  onCancel,
  onDelete,
  loading,
  value,
  onChange,
  onComplete,
  onPay,
  pin,
  setPin,
  btnLabel,
}) => {
  const [completePin, setCompletePin] = useState(false);
  // const [pin, setPin] = useState("");
  const { user } = useSelector((state) => state.user);
  const [details, setDetails] = useState({
    two_factor_token: "",
  });
  const pinRefVal = useRef();
  // const location =
   
  useEffect(() => {
    setCompletePin(false);
    if (details?.two_factor_token) {
      setDetails((prev) => {
        return { ...prev, two_factor_token: "" };
      });
    }
    pinRefVal.current.forEach((input) => (input.value = ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    const newObj = { ...details, [name]: value };
    setDetails(newObj);
  };

  return (
    <RavenModal
      className={`transaction-pin-modal-reuse-wrap`}
      visble={visible}
      effect={`fadeInUp`}
      onBtnClick={() => {
        onPay(pin, details?.two_factor_token);
      }}
      onClose={() => {
        setCompletePin(false);
        if (details?.two_factor_token) {
          setDetails((prev) => {
            return { ...prev, two_factor_token: "" };
          });
        }
        onCancel && onCancel();
        pinRefVal.current.forEach((input) => (input.value = ""));
      }}
      btnLabel={btnLabel || `Pay`}
      loading={loading}
      btnColor={`black-light`}
      disabled={
        String(user?.two_factor) === "1"
          ? !completePin || !details?.two_factor_token
          : String(user?.two_factor) === "0"
          ? !completePin
          : false
      }
    >
      <div
        // onClick={() => {
        //   console.log(user);
        // }}
        className="container-wrap"
      >
        <p className="title">{title || "Enter your transaction PIN"}</p>
        <p className="text grey-white-color pin-test">
          {text ||
            "For security reasons we recommend that you enter your 6 digit passcode to authenticate this request"}
        </p>
        <RavenInputField
          pinRef={pinRefVal}
          type={`pin`}
          color={`black-light`}
          onComplete={(num) => {
            setCompletePin(true);
            onComplete && onComplete(num);
          }}
          id="transafer-Pin"
          value={value}
          onChange={(num) => {
            onChange && onChange(num);
            setPin && setPin(num);
            setCompletePin(false);
          }}
        />

        {/* two factor code start */}
        {String(user?.two_factor) === "1" && (
          <div className="two-factor-box-wrap">
            <div className="text-line">
              <span></span>
              <p className="text">Enter 6 digit 2fa Code</p>
              <span></span>
            </div>
            <form action="" autoComplete="off">
              <RavenInputField
                name={`two_factor_token`}
                type="number"
                placeholder="6 digit code"
                color="black-light"
                onChange={handleChange}
                value={details?.two_factor_token}
              />
            </form>
          </div>
        )}
        {/* two factor code end */}
      </div>

      {/* <div className="two-button-wrap">
        <p onClick={onCancel} className="cancel grey-white-color">
          Cancel
        </p>
        <RavenButton
          color={`black-light`}
          label="Pay"
          loading={loading}
          size={`small`}
          onClick={onDelete || onPay}
          disabled={!completePin}
        />
      </div> */}
    </RavenModal>
  );
};

export default TransactionPinModal;
