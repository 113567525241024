import { RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadTransactionReciept } from "../../../../redux/transaction";
import "../../../../styles/dashboard/transaction/DashboardTransaction.css";
import {
  capitalizeFirstLetter,
  formatDate,
  formatNUmPan,
  formatNumWithCommaNairaSymbol,
  formatTypeFunction,
  lowerCaseWrap,
  trimLongString,
} from "../../../../utils/helper/Helper";
import { FaCheck } from "react-icons/fa";
import { useDarkMode } from "../../../../utils/themes/useDarkMode";

const DownloadReceiptModal = ({ onClose, visible, param, detail }) => {
  // eslint-disable-next-line no-unused-vars
  const [theme, toggleTheme] = useDarkMode(localStorage.getItem("theme"));
  const { loadingDownload } = useSelector((state) => state.transaction);
  const dispatch = useDispatch();
  const formatMetaData = (param) => {
    if (param) {
      const obj = JSON?.parse(param);
      // console.log(obj);
      return obj;
    }
  };

  const formatTypeWrap = (param, chi) => {
    return (
      <div
        className={`status-type-wrap ${
          param === "0" && "status-type-wrap-pending"
        } ${param === "3" && "status-type-wrap-success"} ${
          chi?.direction === "credit" && "status-type-wrap-success"
        } ${param === "paid" && "status-type-wrap-success"} ${
          chi?.direction === "debit" && "status-type-wrap-fail"
        } ${param === "overdue" && "status-type-wrap-pending"} ${
          param === "1" && "status-type-wrap-pending"
        } ${param === "pending" && "status-type-wrap-pending"} ${
          chi?.direction === "credit" && "status-type-wrap-success"
        } ${param === "failed" && "status-type-wrap-disabled"} ${
          chi?.direction === null && "status-type-wrap-disabled"
        }`}
      >
        <span
          style={{ textTransform: "capitalize" }}
          className={`${param === "failed" && "dull-bright-filter"}`}
        >
          {param === "1"
            ? "Processing"
            : param === "0"
            ? "Pending"
            : param === "3"
            ? "Success"
            : param === "2"
            ? "Failed"
            : param === "11"
            ? "Reversal"
            : param === "failed"
            ? "Failed"
            : chi?.direction === null
            ? "Failed"
            : chi?.direction}
        </span>
      </div>
    );
  };

  const handleSubmit = async () => {
    const obj = {
      transaction_id: String(detail?.id),
    };
    // console.log(obj);
    // return
    
    const data = await dispatch(downloadTransactionReciept(obj));
    if (data?.payload?.data?.status === "success") {
      // setDownloadCsvId("");
      downloadCsv(data?.payload?.data?.data?.url);
      // downloadCsvId("");
      // console.log(data);
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  const [isCopied, setIsCopied] = useState("");

  const handleCopied = async (param, copy) => {
    setIsCopied(copy);
    await navigator.clipboard.writeText(param);
    setTimeout(() => {
      setIsCopied("");
    }, 1000);
  };

  const newCopyIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="dull-bright-filter"
        d="M16 8V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.0799 2 5.2V12.8C2 13.9201 2 14.4802 2.21799 14.908C2.40973 15.2843 2.71569 15.5903 3.09202 15.782C3.51984 16 4.0799 16 5.2 16H8M11.2 22H18.8C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V11.2C22 10.0799 22 9.51984 21.782 9.09202C21.5903 8.71569 21.2843 8.40973 20.908 8.21799C20.4802 8 19.9201 8 18.8 8H11.2C10.0799 8 9.51984 8 9.09202 8.21799C8.71569 8.40973 8.40973 8.71569 8.21799 9.09202C8 9.51984 8 10.0799 8 11.2V18.8C8 19.9201 8 20.4802 8.21799 20.908C8.40973 21.2843 8.71569 21.5903 9.09202 21.782C9.51984 22 10.0799 22 11.2 22Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <RavenModal
      btnColor={`black-light`}
      visble={visible}
      btnLabel={`Download Reciept`}
      className="transaction-download-receipt-modal"
      onClose={onClose}
      onBtnClick={handleSubmit}
      loading={loadingDownload}
      // dontShowClose={true}
    >
      <div className="wrap-modal">
        <p
          // onClick={() => {
          //   console.log(formatMetaData(detail?.meta_data));
          //   console.log(detail);
          // }}
          className="title"
        >
          Transaction Reciept
        </p>
        <div className="underline"></div>
        {/* label-value-status box start */}
        <div className="label-value-status-box">
          {/* box start */}
          {detail?.type === "Reversal" && (
            <div className="box">
              <p className="label-download grey-white-color">Recipient</p>
              <p className="value-download">{`System Reversal`}</p>
            </div>
          )}
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label-download grey-white-color">Amount:</p>
            <p className="value-download">
              {formatNumWithCommaNairaSymbol(detail?.amount || 0)}
            </p>
          </div>
          {/* box end */}
          {/* box start */}
          {formatMetaData(detail?.meta_data)?.provider && (
            <div className="box">
              <p className="label-download grey-white-color">Provider:</p>
              <p className="value-download">
                {capitalizeFirstLetter(
                  formatMetaData(detail?.meta_data)?.provider
                ) || "---"}
              </p>
            </div>
          )}
          {/* box end */}
          {/* box start */}
          {formatMetaData(detail?.meta_data)?.smart_card_number && (
            <div className="box">
              <p className="label-download grey-white-color">Card Number:</p>
              <p className="value-download">
                {formatMetaData(detail?.meta_data)?.smart_card_number || "---"}
              </p>
            </div>
          )}
          {/* box end */}
          {/* type start ----------------- */}
          {detail?.type === "airtime" || detail?.type === "data" ? (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Provider:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.data?.network ||
                    formatMetaData(detail?.meta_data)?.network ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Phone number</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.data?.phone_number ||
                    formatMetaData(detail?.meta_data)?.phone_number ||
                    "---"}
                </p>
              </div>
              {/* box end */}
            </>
          ) : (
            ""
          )}
          {/* type end ----------------- */}
          {/* type start ----------------- */}
          {detail?.type === "bet" || detail?.type === "data" ? (
            <>
              {/* <div className="box">
                <p className="label-download grey-white-color">Provider:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.type || "---"}
                </p>
              </div> */}
              {/* box end */}
              {/* box start */}
        {formatMetaData(detail?.meta_data)?.name ?      <div className="box">
                <p className="label-download grey-white-color">Customer name</p>
                <p className="value-download">
                  {lowerCaseWrap(formatMetaData(detail?.meta_data)?.name) ||
                    "---"}
                </p>
              </div> : ''}
              {/* box end */}
            </>
          ) : (
            ""
          )}
          {/* type end ----------------- */}
          {/* type start ----------------- */}
          {detail?.type === "electricity" && (
            <>
              {/* <div className="box">
                <p className="label-download grey-white-color">Provider:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.meta?.service ||
                    formatMetaData(detail?.meta_data)?.provider ||
                    "---"}
                </p>
              </div> */}
              {/* box end electricity_token*/}
              <div className="box">
                <p className="label-download grey-white-color">Plan</p>
                <p
                  style={{ textTransform: "capitalize" }}
                  className="value-download"
                >
                  {formatMetaData(detail?.meta_data)?.meta?.meter_type ||
                    formatMetaData(detail?.meta_data)?.meter_type ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Meter number</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.meta?.meter_number ||
                    formatMetaData(detail?.meta_data)?.meter_no ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              {formatMetaData(detail?.meta_data)?.meta?.electricity_token && (
                <div className="box">
                  <p className="label-download grey-white-color">Token</p>
                  <p className="value-download">
                    {formatMetaData(detail?.meta_data)?.meta
                      ?.electricity_token || "---"}
                  </p>
                </div>
              )}
              {/* box end */}
              {/* box start */}
              {formatMetaData(detail?.meta_data)?.meta?.reference && (
                <div className="box">
                  <p className="label-download grey-white-color">Reference</p>
                  <div className="value-download value-download-two ref">
                    <p>
                      {" "}
                      {formatMetaData(detail?.meta_data)?.meta?.reference ||
                        detail?.merchant_ref ||
                        "---"}
                    </p>
                    {isCopied === "ref" ? (
                      <div
                        style={{
                          backgroundColor:
                            theme === "light" ? "#020202" : "#ffffff",
                        }}
                        className="copied-box-reciept "
                      >
                        <FaCheck
                          style={{
                            fill: theme === "dark" ? "#020202" : "#ffffff",
                          }}
                          className="icon"
                        />
                      </div>
                    ) : (
                      <figure
                        onClick={() =>
                          handleCopied(
                            formatMetaData(detail?.meta_data)?.meta
                              ?.reference || detail?.merchant_ref,
                            "ref"
                          )
                        }
                        className="img-box-reciept"
                        style={{
                          backgroundColor:
                            theme === "light"
                              ? "#f9f9f9"
                              : "rgba(249, 249, 249, .3)",
                        }}
                      >
                        {newCopyIcon}
                      </figure>
                    )}
                  </div>
                </div>
              )}
              {/* box end */}
              {/* box start */}
              {/* <div className="box">
                <p className="label-download grey-white-color">Customer Name</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_name || "---"}
                </p>
              </div> */}
              {/* box end */}
            </>
          )}
          {/* type end ----------------- */}
          {detail?.type === "card" && (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Card Number:</p>
                <p className="value-download">
                  {`${formatNUmPan(
                    formatMetaData(detail?.meta_data)?.card_pan
                  )}` || "---"}
                </p>
              </div>
              {/* box end electricity_token*/}
              <div className="box">
                <p className="label-download grey-white-color">RRN</p>
                <p
                  style={{ textTransform: "capitalize" }}
                  className="value-download"
                >
                  {formatMetaData(detail?.meta_data)?.rrn || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box end electricity_token*/}
              {detail?.type === "transfer_in" &&
                Number(detail?.amount) >= 10000 && (
                  <div className="box">
                    <p className="label-download grey-white-color">
                      Stamp Duty
                    </p>
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="value-download"
                    >
                      {formatMetaData(detail?.meta_data)?.rrn || "---"}
                    </p>
                  </div>
                )}
              {/* box end */}
              {/* box end electricity_token*/}
              <div className="box">
                <p className="label-download grey-white-color">Stan</p>
                <p
                  style={{ textTransform: "capitalize" }}
                  className="value-download"
                >
                  {formatMetaData(detail?.meta_data)?.stan || "---"}
                </p>
              </div>
              {/* box end */}

              {detail?.direction === "credit" && (
                <>
                  {/* box end electricity_token*/}
                  <div className="box">
                    <p className="label-download grey-white-color">Card Fee</p>
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="value-download"
                    >
                      {formatNumWithCommaNairaSymbol(detail?.fee || 0)}
                    </p>
                  </div>
                  {/* box end */}
                </>
              )}
              {detail?.direction === null && (
                <>
                  {/* box end electricity_token*/}
                  <div className="box">
                    <p className="label-download grey-white-color">
                      Response Code
                    </p>
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="value-download"
                    >
                      {formatMetaData(detail?.meta_data)?.response_code ||
                        "---"}
                    </p>
                  </div>
                  {/* box end */}
                  {/* box end electricity_token*/}
                  <div className="box">
                    <p className="label-download grey-white-color">
                      Response meaning
                    </p>
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="value-download"
                    >
                      {formatMetaData(detail?.meta_data)?.response_meaning ||
                        "---"}
                    </p>
                  </div>
                  {/* box end */}
                </>
              )}
              {/* box start */}
              {/* <div className="box">
                <p className="label-download grey-white-color">Customer Name</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_name || "---"}
                </p>
              </div> */}
              {/* box end */}
            </>
          )}
          {detail?.type === "pdon_card_raven" ||
          detail?.type === "pdon_card" ? (
            <>
              <div className="box">
                <p className="label-download grey-white-color">
                  Affilaite App:
                </p>
                <p className="value-download">
                  {`${
                    formatMetaData(detail?.pdon_meta)?.app_profile
                      ?.affiliate_app_name
                  }` || "---"}
                </p>
              </div>
              <div className="box">
                <p className="label-download grey-white-color">Card Number:</p>
                <p className="value-download">
                  {`${formatNUmPan(
                    formatMetaData(detail?.meta_data)?.card_pan
                  )}` || "---"}
                </p>
              </div>
              {/* box end electricity_token*/}
              <div className="box">
                <p className="label-download grey-white-color">RRN</p>
                <p
                  style={{ textTransform: "capitalize" }}
                  className="value-download"
                >
                  {formatMetaData(detail?.meta_data)?.rrn || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box end electricity_token*/}
              {detail?.type === "transfer_in" &&
                Number(detail?.amount) >= 10000 && (
                  <div className="box">
                    <p className="label-download grey-white-color">
                      Stamp Duty
                    </p>
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="value-download"
                    >
                      {formatMetaData(detail?.meta_data)?.rrn || "---"}
                    </p>
                  </div>
                )}
              {/* box end */}
              {/* box end electricity_token*/}
              <div className="box">
                <p className="label-download grey-white-color">Stan</p>
                <p
                  style={{ textTransform: "capitalize" }}
                  className="value-download"
                >
                  {formatMetaData(detail?.meta_data)?.stan || "---"}
                </p>
              </div>
              {/* box end */}

              {detail?.direction === "credit" && (
                <>
                  {/* box end electricity_token*/}
                  <div className="box">
                    <p className="label-download grey-white-color">Card Fee</p>
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="value-download"
                    >
                      {formatNumWithCommaNairaSymbol(detail?.fee || 0)}
                    </p>
                  </div>
                  {/* box end */}
                </>
              )}
              {detail?.direction === null && (
                <>
                  {/* box end electricity_token*/}
                  <div className="box">
                    <p className="label-download grey-white-color">
                      Response Code
                    </p>
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="value-download"
                    >
                      {formatMetaData(detail?.meta_data)?.response_code ||
                        "---"}
                    </p>
                  </div>
                  {/* box end */}
                  {/* box end electricity_token*/}
                  <div className="box">
                    <p className="label-download grey-white-color">
                      Response meaning
                    </p>
                    <p
                      style={{ textTransform: "capitalize" }}
                      className="value-download"
                    >
                      {formatMetaData(detail?.meta_data)?.response_meaning ||
                        "---"}
                    </p>
                  </div>
                  {/* box end */}
                </>
              )}
              {/* box start */}
              {/* <div className="box">
                <p className="label-download grey-white-color">Customer Name</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_name || "---"}
                </p>
              </div> */}
              {/* box end */}
            </>
          ) : (
            ""
          )}
          {/* type end ----------------- */}

          {/* type start ----------------- */}
          {detail?.type === "Cable Recharge" && (
            <>
              <div className="box">
                <p className="label-download grey-white-color">Provider:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.provider || "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Meter No.</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.smart_card_number ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Customer Name</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.customer_name || "---"}
                </p>
              </div>
              {/* box end */}
            </>
          )}
          {/* type end ----------------- */}
          {detail?.type === "Money Received" &&
            formatMetaData(detail?.meta_data)?.first_name &&
            formatMetaData(detail?.meta_data)?.last_name && (
              <>
                <div className="box">
                  <p className="label-download grey-white-color">Sent By:</p>
                  <p className="value-download">
                    {`${
                      formatMetaData(detail?.meta_data)?.first_name || "---"
                    } ${formatMetaData(detail?.meta_data)?.last_name || "---"}`}
                  </p>
                </div>
                {/* box end electricity_token*/}
              </>
            )}
          {detail?.type === "transfer_in" && (
            <>
              {" "}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Source:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.bank ||
                    formatMetaData(detail?.meta_data)?.source?.bank ||
                    formatMetaData(detail?.meta_data)?.business_banking_account
                      ?.bank ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Account No.</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.source?.account_number ||
                    formatMetaData(detail?.meta_data)?.business_banking_account
                      ?.account_number ||
                    "---"}
                </p>
              </div>
              {/* box end */}
              <div className="box">
                <p className="label-download grey-white-color">Sent By:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.business_banking_account
                    ?.account_name ||
                    `${
                      formatMetaData(detail?.meta_data)?.source?.first_name ||
                      "---"
                    } ${
                      formatMetaData(detail?.meta_data)?.source?.last_name ||
                      "---"
                    }`}
                </p>
              </div>
            </>
          )}
          {/* type start ----------------- */}
          {detail?.type === "transfer_out" ||
          detail?.type === "Reversal" ||
          detail?.type === "Transaction Charge" ? (
            <>
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">Bank:</p>
                <p className="value-download">
                  {formatMetaData(detail?.meta_data)?.bank ||
                    formatMetaData(detail?.meta_data)
                      ?.business_destination_account?.bank ||
                    "---"}
                </p>
              </div>
              {/* box end */}

              {detail?.type === "transfer_out" && (
                <>
                  {" "}
                  {/* box start */}
                  <div className="box">
                    <p className="label-download grey-white-color">Recipient</p>
                    <p className="value-download">
                      {formatMetaData(detail?.meta_data)?.account_name ||
                        formatMetaData(detail?.meta_data)
                          ?.business_destination_account?.account_name ||
                        "---"}
                    </p>
                  </div>
                  {/* box end */}
                </>
              )}
              {/* box start */}
              {formatMetaData(detail?.meta_data)?.session_id ? (
                <div className="box">
                  <p className="label-download grey-white-color">Session ID</p>
                  <p className="value-download">
                    {formatMetaData(detail?.meta_data)?.session_id || "---"}
                  </p>
                </div>
              ) : (
                ""
              )}
              {/* box end */}
              {/* box end */}
            </>
          ) : (
            ""
          )}
          {/* type end ----------------- */}

          {/* box start */}
          {detail?.type !== "Reversal" && (
            <div className="box">
              <p className="label-download grey-white-color">
                Transaction Type{" "}
              </p>
              <p
                style={{ textTransform: "capitalize" }}
                className="value-download"
              >
                {formatTypeFunction(detail?.type) === "Pdon card" ||
                formatTypeFunction(detail?.type) === "Pdon card raven"
                  ? "Bankbox Payment"
                  : formatTypeFunction(detail?.type) || "---"}
              </p>
            </div>
          )}
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label-download grey-white-color">Transaction date </p>
            <p className="value-download">
              {formatDate(detail?.created_at) || "---"}
            </p>
          </div>
          {/* box end */}
          {detail?.type !== "Reversal" && detail?.type !== "card" && (
            <>
              {" "}
              {/* box start */}
              <div className="box">
                <p className="label-download grey-white-color">
                  Transaction Ref.
                </p>
                <div className="value-download value-download-two ref">
                  <p>
                    {" "}
                    {formatMetaData(detail?.meta_data)?.data?.reference ||
                      detail?.merchant_ref ||
                      trimLongString(detail?.reference, 23) ||
                      "---"}
                  </p>
                  {isCopied === "ref" ? (
                    <div
                      style={{
                        backgroundColor:
                          theme === "light" ? "#020202" : "#ffffff",
                      }}
                      className="copied-box-reciept "
                    >
                      <FaCheck
                        style={{
                          fill: theme === "dark" ? "#020202" : "#ffffff",
                        }}
                        className="icon"
                      />
                    </div>
                  ) : (
                    <figure
                      onClick={() =>
                        handleCopied(
                          formatMetaData(detail?.meta_data)?.meta?.reference ||
                            detail?.reference ||
                            detail?.merchant_ref,
                          "ref"
                        )
                      }
                      className="img-box-reciept"
                      style={{
                        backgroundColor:
                          theme === "light"
                            ? "#f9f9f9"
                            : "rgba(249, 249, 249, .3)",
                      }}
                    >
                      {newCopyIcon}
                    </figure>
                  )}
                </div>
                {/* <p className="value-download ref">
                  <span>
                    {" "}
                    {formatMetaData(detail?.meta_data)?.data?.reference ||
                      detail?.reference ||
                      "---"}
                  </span>
                </p> */}
              </div>
              {/* box end */}
            </>
          )}
          {/* box start */}
          <div className="box">
            <p className="label-download grey-white-color">Status </p>
            {formatTypeWrap(detail?.status, detail)}
            {/* <div
              className={`status-type-wrap ${
                detail?.status === "0" && "status-type-wrap-pending"
              } ${detail?.status === "3" && "status-type-wrap-success"} ${
                detail?.status === "11" && "status-type-wrap-success"
              } ${detail?.status === "success" && "status-type-wrap-success"} ${
                detail?.status === "paid" && "status-type-wrap-success"
              } ${detail?.status === "failed" && "status-type-wrap-fail"} ${
                detail?.status === "overdue" && "status-type-wrap-pending"
              } ${detail?.status === "1" && "status-type-wrap-pending"} ${
                detail?.status === "pending" && "status-type-wrap-pending"
              } ${detail?.direction === null && "status-type-wrap-disabled"}`}
            >
              <span>
                {" "}
                {detail?.status === "1"
                  ? "Processing"
                  : detail?.status === "0"
                  ? "Pending"
                  : detail?.status === "3"
                  ? "Success"
                  : detail?.status === "2"
                  ? "Failed"
                  : detail?.status === "11"
                  ? "Success"
                  : detail?.direction === null
                  ? "Failed"
                  : detail?.status}
              </span>
            </div> */}
          </div>
          {/* box end */}
        </div>
        {/* label-value-status box end */}
      </div>
    </RavenModal>
  );
};

export default DownloadReceiptModal;
