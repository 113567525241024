import React, { useContext, useEffect, useState } from "react";
import TitletextBox from "../../../components/reusables/TitletextBox";
// import { useContext } from "react";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import {
  RavenButton,
  RavenInputField,
  RavenToolTip,
  toast,
} from "@ravenpay/raven-bank-ui";
// import { RavenButton } from "@ravenpay/raven-bank-ui";
import { invoiceIcon } from "../../../assets/icon/icons";
import moment from "moment";
import {
  capitalizeFirstLetter,
  // formatDate,
  formatNumWithCommaNairaSymbol,
  formatNumWithoutCommaNaira,
  formatPhoneNumber,
  // removeLeadingZeroFromString,
  // reactSelectStyle,
  sumAllNum,
  trimLongString,
} from "../../../utils/helper/Helper";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewInvoice,
  createNewInvoiceDraft,
  createNewInvoiceDraftAuto,
  getInvoice,
  getInvoiceSettings,
} from "../../../redux/invoice";
import SuccessModal from "../../../components/reusables/SuccessModal";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../../utils/helper/ValidateInput";
import ChangeSourceAccount from "../../../components/reusables/ChangeSourceAccount";
import ActionContext from "../../../context/ActionContext";
import useWindowSize from "../../../utils/helper/UseWindowSize";
import {
  getCustomer,
  // getCustomers,
  getCustomersWithoutPagination,
} from "../../../redux/customers";
import { FaCheck } from "react-icons/fa";
import ViewAllCustomersModal from "./ViewAllCustomersModal";
require("../../../styles/dashboard/invoice/new_invoice.css");
require("@ravenpay/raven-bank-ui/dist/esm/styles/index.css");

function NewInvoice() {
  const { all_customers } = useSelector((state) => state.customers);
  const dispatch = useDispatch();
  const { loading, invoiceSetting, loading_single_draft, loading_auto } =
    useSelector((state) => state.invoice);
  const navigate = useNavigate();
  // const location = useLocation();
  const next = () => {
    setStage(stage + 1);
  };
  const [showModal, setShowModal] = useState({
    success: false,
    view: false,
  });
  const { business, user_resource_and_permissions, all_account } = useSelector(
    (state) => state.user
  );
  const actionCtx = useContext(ActionContext);
  const [option, setOption] = useState(false);
  const [stage, setStage] = useState(1);
  const [item, setItem] = useState({
    name: "",
    quantity: "",
    price: "",
  });
  const [itemArr, setItemArr] = useState([]);
  const [viewAll, setViewAll] = useState(false);
  const [editState, setEditState] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    startDate: "",
    endDate: "",
    note: "",
    cust_fullname: "",
    cust_email_address: "",
    cust_phone_no: "",
    discount: 0,
    vat: 0,
    shipping: "",
    discount_val: "",
    vat_val: "",
    totalPrice: "",
    totalSum: "",
    address: "",
    invoice_token: "",
  });

  useEffect(() => {
    if (
      !user_resource_and_permissions?.allowed_permissions?.includes(
        "can_create_invoice"
      )
    ) {
      navigate("/dashboard-invoice");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_resource_and_permissions]);

  useEffect(() => {
    const id = localStorage.getItem("customer-inv-id");
    if (id) {
      getSingleLink();
    }
    dispatch(getCustomersWithoutPagination());
    // const obj = {
    //   per_page: actionCtx?.perPageVal?.value,
    //   current_page: 1,
    // };
    // dispatch(getCustomers(obj));
    dispatch(getInvoiceSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [subAccount, setSubAccount] = useState({});

  useEffect(() => {
    if (Object?.keys(invoiceSetting).length > 0) {
      // console.log(invoiceSetting);
      setFormData((prev) => {
        return {
          ...prev,
          discount: invoiceSetting?.discount,
          vat: invoiceSetting?.tax,
          shipping: invoiceSetting?.shipping_fee,
          address: invoiceSetting?.address,
        };
      });
      if (invoiceSetting?.account_number_id) {
        // setDetails(invoiceSetting);
        // console.log(invoiceSetting);
        invoiceSetting?.selected_sub_account
          ? setSubAccount(invoiceSetting?.selected_sub_account)
          : setSubAccount(actionCtx?.currentAccount);
      }
    }
    if (Object?.keys(invoiceSetting).length < 1) {
      setFormData((prev) => {
        return {
          ...prev,
          discount: 0,
          vat: 0,
          shipping: 0,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceSetting]);

  const getSingleLink = async () => {
    const id = localStorage.getItem("customer-inv-id");
    const data = await dispatch(getCustomer(id));
    if (data?.payload?.data?.status === "success") {
      // console.log(data);
      const obj = data?.payload?.data?.data;
      setFormData((prev) => {
        return {
          ...prev,
          cust_email_address: obj?.customer_email,
          cust_fullname: obj?.customer_name,
          cust_phone_no: obj?.customer_phone,
        };
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...formData, [name]: value };
    setFormData(obj);
  };

  const handleChangeItem = (e) => {
    const { name, value } = e.target;
    const obj = { ...item, [name]: value };
    // console.log(obj);
    setItem(obj);
  };

  function addObjectToArray(newObject, array) {
    const index = array.findIndex((obj) => obj.id === newObject.id);
    if (index !== -1) {
      array[index] = newObject; // replace existing object
    } else {
      array.unshift(newObject); // add new object to the beginning of the array
    }
    return array;
  }

  const onItemAdd = () => {
    const uniqueId =
      Date.now().toString(36) + Math.random().toString(36).substr(2);
    const newObj = {
      ...item,
      id: item?.id ? item?.id : uniqueId,
      total_price: Math.round(
        Number(formatNumWithoutCommaNaira(item?.price)) *
          Number(formatNumWithoutCommaNaira(item?.quantity))
      ),
      uniqueId: uniqueId,
    };
    // console.log(itemArr);
    // console.log(updateOrAdd(itemArr, newObj))
    const newList =
      itemArr?.length > 0
        ? addObjectToArray(newObj, itemArr)
        : [...itemArr, newObj];
    setItemArr(newList);
    setItem((prev) => {
      return { ...prev, name: "", quantity: "", price: "" };
    });
    editState && setEditState(false);
  };

  useEffect(() => {
    calculatePreviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.discount, formData?.vat, item?.price, item?.quantity]);

  const calculatePreviewData = () => {
    if (itemArr?.length > 0) {
      // let listToUse;
      // name: chi?.product,
      //       quantity: chi?.quantity,
      //       price: chi?.unit_price,
      //       total_price: chi?.amount,
      //       id: chi?.id,
      const totalList = itemArr.map((chi) =>
        Number(formatNumWithoutCommaNaira(chi?.total_price))
      );
      const sumTotal = sumAllNum(totalList);
      const discountPrice =
        Number(formData?.discount) > 0
          ? Math.round((Number(formData?.discount) * sumTotal) / 100)
          : 0;
      const taxPrice =
        Number(formData?.vat) > 0
          ? Math.round((Number(formData?.vat) * sumTotal) / 100)
          : 0;
      const finalValue = Math.round(sumTotal + +taxPrice - discountPrice);
      setFormData((prev) => {
        return {
          ...prev,
          totalPrice: finalValue,
          discount_val: discountPrice,
          totalSum: sumTotal,
          vat_val: taxPrice,
        };
      });
    } else {
      const discountPrice =
        Number(formData?.discount) > 0
          ? Math.round(
              (Number(formData?.discount) *
                Number(
                  formatNumWithoutCommaNaira(item?.price) *
                    Number(item?.quantity)
                )) /
                100
            )
          : 0;
      const taxPrice =
        Number(formData?.vat) > 0
          ? Math.round(
              (Number(formData?.vat) *
                Number(
                  formatNumWithoutCommaNaira(item?.price) *
                    Number(item?.quantity)
                )) /
                100
            )
          : 0;
      const finalValue = Math.round(
        Number(
          formatNumWithoutCommaNaira(item?.price) * Number(item?.quantity)
        ) +
          taxPrice -
          discountPrice
      );
      // console.log(itemArr);
      // if (item?.name && item?.price && item?.quantity) {
      //   setItemArr([
      //     {
      //       ...item,
      //       total_price: Math.round(
      //         Number(formatNumWithoutCommaNaira(item?.price)) *
      //           Number(formatNumWithoutCommaNaira(item?.quantity))
      //       ),
      //     },
      //   ]);
      // }
      setFormData((prev) => {
        return {
          ...prev,
          totalPrice: finalValue,
          discount_val: discountPrice,
          totalSum: Number(
            formatNumWithoutCommaNaira(item?.price) * Number(item?.quantity)
          ),
          vat_val: taxPrice,
        };
      });
    }
  };

  const formatBeneficiary = (list) => {
    const newList = list?.map((chi) => {
      return {
        ...chi,
        label: `${trimLongString(chi?.customer_email, 22)} `,
        value: chi?.id,
      };
    });
    return newList;
  };

  const removeBeneficiaryOption = () => {
    setBeneficiarySelect("");
    setFormData((prev) => {
      return {
        ...prev,
        cust_email_address: "",
        cust_fullname: "",
        cust_phone_no: "",
      };
    });
  };

  const [tokenToUse, setTokenToUse] = useState("");
  const getTokenToUse = () => {
    const val = Date.now().toString(36) + Math.random().toString(36).substr(2);
    let value;
    if (tokenToUse) {
      value = tokenToUse;
    }
    if (!tokenToUse) {
      value = val;
      setTokenToUse(val);
    }
    return value;
  };

  const handleCreate = async () => {
    const newArry = itemArr?.map((chi) => {
      return chi?.id && chi?.uniqueId !== chi?.id
        ? {
            unit_price: formatNumWithoutCommaNaira(chi?.price),
            quantity: chi?.quantity,
            product_name: chi?.name,
            type: "1",
            id: chi?.id || "",
          }
        : {
            unit_price: formatNumWithoutCommaNaira(chi?.price),
            quantity: chi?.quantity,
            product_name: chi?.name,
            type: "1",
          };
    });
    const inv_token =
      Date.now().toString(36) + Math.random().toString(36).substr(2);
    const obj = {
      invoice_token: formData?.invoice_token || inv_token,
      title: formData?.title,
      customer_email: formData?.cust_email_address,
      customer_name: formData?.cust_fullname,
      start_date: formData?.startDate,
      items_to_delete: draftIdToremove?.length > 0 ? draftIdToremove : [],
      due_date: formData?.endDate,
      discount: formData?.discount,
      tax: formData?.vat,
      customer_phone_number: formData?.cust_phone_no
        ? `0${formatNumWithoutCommaNaira(formData?.cust_phone_no)}`
        : "",
      items:
        itemArr?.length > 0
          ? newArry
          : [
              item?.id && item?.uniqueId !== item?.id
                ? {
                    unit_price: formatNumWithoutCommaNaira(item?.price),
                    quantity: item?.quantity,
                    product_name: item?.name,
                    type: "1",
                    id: item?.id || "",
                  }
                : {
                    unit_price: formatNumWithoutCommaNaira(item?.price),
                    quantity: item?.quantity,
                    product_name: item?.name,
                    type: "1",
                  },
            ],
      shipping_fee: formatNumWithoutCommaNaira(formData?.shipping),
      note: formData?.note,
      send_mail: 1,
      invoice_id: formData?.id ? formData?.id : "",
      address: formData?.address,
      selected_account_number: subAccount?.account_number,
      account_number: subAccount?.account_number,
    };
    // console.log(obj);
    // return;
    const data = await dispatch(createNewInvoice(obj));
    if (data?.payload?.data?.status === "success") {
      handleFinish();
    }
  };
  // const [saveToDraft, setSaveToDraft] = useState(false);
  const handleCreateDraft = async () => {
    const newArry = itemArr?.map((chi) => {
      return chi?.id && chi?.uniqueId !== chi?.id
        ? {
            unit_price: formatNumWithoutCommaNaira(chi?.price),
            quantity: chi?.quantity,
            product_name: chi?.name,
            type: "1",
            id: chi?.id || "",
          }
        : {
            unit_price: formatNumWithoutCommaNaira(chi?.price),
            quantity: chi?.quantity,
            product_name: chi?.name,
            type: "1",
          };
    });
   
    const obj = {
      invoice_token: formData?.invoice_token || getTokenToUse(),
      title: formData?.title,
      customer_email: formData?.cust_email_address,
      customer_name: formData?.cust_fullname,
      start_date: formData?.startDate,
      due_date: formData?.endDate,
      discount: formData?.discount,
      tax: formData?.vat,
      send_mail: "0",
      invoice_id: formData?.id ? formData?.id : "",
      customer_phone_number: formData?.cust_phone_no
        ? `0${formatNumWithoutCommaNaira(formData?.cust_phone_no)}`
        : "",
      items:
        itemArr?.length > 0
          ? newArry
          : [
              item?.id && item?.uniqueId !== item?.id
                ? {
                    unit_price: formatNumWithoutCommaNaira(item?.price),
                    quantity: item?.quantity,
                    product_name: item?.name,
                    type: "1",
                    id: item?.id || "",
                  }
                : {
                    unit_price: formatNumWithoutCommaNaira(item?.price),
                    quantity: item?.quantity,
                    product_name: item?.name,
                    type: "1",
                  },
            ],
      shipping_fee: formatNumWithoutCommaNaira(formData?.shipping),
      note: formData?.note,
      address: formData?.address,
      selected_account_number: subAccount?.account_number,
      account_number: subAccount?.account_number,
      items_to_delete: draftIdToremove?.length > 0 ? draftIdToremove : [],
    };
    // console.log(obj);
    // return;
    // setSaveToDraft(true);
    const data = await dispatch(createNewInvoiceDraft(obj));
    if (data?.payload?.data?.status === "success") {
      handleFinishDraft();
    }
  };

  const handleCreateDraftAuto = async () => {
    const newArry = itemArr?.map((chi) => {
      return chi?.id && chi?.uniqueId !== chi?.id
        ? {
            unit_price: formatNumWithoutCommaNaira(chi?.price),
            quantity: chi?.quantity,
            product_name: chi?.name,
            type: "1",
            id: chi?.id || "",
          }
        : {
            unit_price: formatNumWithoutCommaNaira(chi?.price),
            quantity: chi?.quantity,
            product_name: chi?.name,
            type: "1",
          };
    });

    const obj = {
      invoice_token: formData?.invoice_token || getTokenToUse(),
      title: formData?.title,
      customer_email: formData?.cust_email_address,
      customer_name: formData?.cust_fullname,
      start_date: formData?.startDate,
      due_date: formData?.endDate,
      discount: formData?.discount,
      tax: formData?.vat,
      send_mail: "0",
      invoice_id: formData?.id ? formData?.id : "",
      customer_phone_number: formData?.cust_phone_no
        ? `0${formatNumWithoutCommaNaira(formData?.cust_phone_no)}`
        : "",
      items:
        itemArr?.length > 0
          ? newArry
          : [
              item?.id && item?.uniqueId !== item?.id
                ? {
                    unit_price: formatNumWithoutCommaNaira(item?.price),
                    quantity: item?.quantity,
                    product_name: item?.name,
                    type: "1",
                    id: item?.id || "",
                  }
                : {
                    unit_price: formatNumWithoutCommaNaira(item?.price),
                    quantity: item?.quantity,
                    product_name: item?.name,
                    type: "1",
                  },
            ],
      shipping_fee: formatNumWithoutCommaNaira(formData?.shipping),
      note: formData?.note,
      address: formData?.address,
      selected_account_number: subAccount?.account_number,
      account_number: subAccount?.account_number,
      items_to_delete: draftIdToremove?.length > 0 ? draftIdToremove : [],
    };
    // console.log(obj);
    // return;
    // setSaveToDraft(true);
    !loading_auto && dispatch(createNewInvoiceDraftAuto(obj));
    // if (data?.payload?.data?.status === "success") {
    //   handleFinishDraft();
    // } else {
    //   setSaveToDraft(false);
    // }
  };

  const [beneficiarySelect, setBeneficiarySelect] = useState("");
  const handleFinish = () => {
    localStorage.removeItem("customer-inv-id");
    localStorage.removeItem("invoice-draft-single-id");
    setFormData({
      title: "",
      startDate: "",
      endDate: "",
      note: "",
      cust_fullname: "",
      cust_email_address: "",
      cust_phone_no: "",
      discount: invoiceSetting?.discount,
      vat: invoiceSetting?.vat,
      shipping: invoiceSetting?.shipping_fee,
      discount_val: "",
      vat_val: "",
      totalPrice: "",
      totalSum: "",
      shipping_fee: "",
      address: "",
      id: "",
    });
    setItem({
      name: "",
      quantity: "",
      price: "",
    });
    setStage(1);
    setItemArr([]);
    setShowModal((prev) => {
      return { ...prev, success: true };
    });
  };

  const handleFinishDraft = () => {
    localStorage.removeItem("customer-inv-id");
    localStorage.removeItem("invoice-draft-single-id");
    toast.success("Invoice saved to draft", {});
    setFormData({
      title: "",
      startDate: "",
      endDate: "",
      note: "",
      cust_fullname: "",
      cust_email_address: "",
      cust_phone_no: "",
      discount: invoiceSetting?.discount,
      vat: invoiceSetting?.vat,
      shipping: invoiceSetting?.shipping_fee,
      discount_val: "",
      vat_val: "",
      totalPrice: "",
      totalSum: "",
      shipping_fee: "",
      address: "",
      id: "",
    });
    setItem({
      name: "",
      quantity: "",
      price: "",
    });
    setStage(1);
    setItemArr([]);
    // setSaveToDraft(false);
    navigate("/dashboard-invoice");
    // setShowModal((prev) => {
    //   return { ...prev, success: true };
    // });
  };
  const [draftIdToremove, setDraftIdToRemove] = useState([]);
  // const handleFinishDraftAuto = () => {
  //   localStorage.removeItem("customer-inv-id");
  //   localStorage.removeItem("invoice-draft-single-id");
  //   // toast.success("Invoice saved to draft", {});
  //   setFormData({
  //     title: "",
  //     startDate: "",
  //     endDate: "",
  //     note: "",
  //     cust_fullname: "",
  //     cust_email_address: "",
  //     cust_phone_no: "",
  //     discount: invoiceSetting?.discount,
  //     vat: invoiceSetting?.vat,
  //     shipping: invoiceSetting?.shipping_fee,
  //     discount_val: "",
  //     vat_val: "",
  //     totalPrice: "",
  //     totalSum: "",
  //     shipping_fee: "",
  //     address: "",
  //     id: "",
  //   });
  //   setItem({
  //     name: "",
  //     quantity: "",
  //     price: "",
  //   });
  //   setStage(1);
  //   setItemArr([]);
  //   setSaveToDraft(false);
  //   navigate("/dashboard-invoice");
  //   // setShowModal((prev) => {
  //   //   return { ...prev, success: true };
  //   // });
  // };

  //delete items from array
  const handleRemoveFromList = (i, obj) => {
    // console.log(obj);
    if (obj?.id && obj?.uniqueId !== obj?.id) {
      // console.log(obj);
      if (!draftIdToremove?.includes(obj?.id)) {
        const newList = [...draftIdToremove, obj?.id];
        setDraftIdToRemove(newList);
      }
    }
    const newList = itemArr?.filter((chi) => chi?.id !== i);
    setItemArr(newList);
  };

  const stepList = [
    "Invoice details",
    "Customer details",
    "Add products",
    "Preview invoice",
  ];

  const [showError, setShowError] = useState({
    num: false,
    email: false,
    amount: false,
    meter: false,
  });

  const size = useWindowSize();

  const handleBeneficiarySelect = (obj) => {
    // console.log(obj);
    const newObj = {
      ...formData,
      cust_email_address: obj?.customer_email,
      cust_phone_no: obj?.customer_phone,
      cust_fullname: obj?.customer_name,
    };
    setFormData(newObj);
    // console.log(obj);
    setBeneficiarySelect(obj);
  };

  const customerIcons = (
    <svg
      className="img"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0711 16.1024V18.2707C19.0711 18.5282 18.8411 18.7373 18.5577 18.7373H10.2966C10.0135 18.7373 9.78314 18.5282 9.78314 18.2707V16.1024C9.78314 14.5416 10.6388 13.2764 12.8913 13.2764C12.9666 13.2784 13.046 13.2794 13.1309 13.2794C13.4982 13.2811 13.9469 13.2663 14.4275 13.2603C14.9066 13.2663 15.3564 13.2811 15.724 13.2794C15.8078 13.2794 15.8879 13.2794 15.9636 13.2764C18.2164 13.2764 19.0711 14.5416 19.0711 16.1024Z"
        fill="#E3E3E3"
      />
      <path
        d="M14.7339 12.3513C16.3518 12.0937 17.4505 10.5984 17.1879 9.01155C16.9253 7.42466 15.4008 6.34705 13.7828 6.60464C12.1649 6.86223 11.0662 8.35747 11.3289 9.94436C11.5915 11.5312 13.116 12.6089 14.7339 12.3513Z"
        fill="#E3E3E3"
      />
      <path
        d="M13.4193 15.668V18.1485C13.4193 18.4429 13.1565 18.6823 12.8327 18.6823H3.38249C3.0587 18.6823 2.79584 18.4429 2.79584 18.1485V15.668C2.79584 13.8827 3.77337 12.4355 6.35103 12.4355C6.43683 12.4377 6.5281 12.4388 6.62485 12.4388C7.04516 12.4412 7.55823 12.424 8.10826 12.417C8.65761 12.424 9.16931 12.4412 9.59099 12.4388C9.68717 12.4388 9.77856 12.4388 9.86481 12.4355C12.4418 12.4355 13.4193 13.8827 13.4193 15.668Z"
        fill="#333333"
      />
      <path
        d="M8.45737 11.3772C10.308 11.0826 11.5647 9.37232 11.2643 7.55722C10.9639 5.74212 9.22015 4.50954 7.36955 4.80417C5.51895 5.0988 4.26226 6.80908 4.56265 8.62418C4.86305 10.4393 6.60677 11.6719 8.45737 11.3772Z"
        fill="#333333"
      />
    </svg>
  );

  const editIcon = (
    <svg
      className="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 18.0002L19.9999 19.0943C19.4695 19.6744 18.7501 20.0002 18.0001 20.0002C17.2501 20.0002 16.5308 19.6744 16.0004 19.0943C15.4692 18.5154 14.75 18.1903 14.0002 18.1903C13.2504 18.1903 12.5311 18.5154 12 19.0943M3 20.0002H4.67454C5.16372 20.0002 5.40832 20.0002 5.63849 19.945C5.84256 19.896 6.03765 19.8152 6.2166 19.7055C6.41843 19.5818 6.59138 19.4089 6.93729 19.063L19.5 6.50023C20.3285 5.6718 20.3285 4.32865 19.5 3.50023C18.6716 2.6718 17.3285 2.6718 16.5 3.50023L3.93726 16.063C3.59136 16.4089 3.4184 16.5818 3.29472 16.7837C3.18506 16.9626 3.10425 17.1577 3.05526 17.3618C3 17.5919 3 17.8365 3 18.3257V20.0002Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dull-bright-filter"
      />
    </svg>
  );

  const deleteIcon = (
    <svg
      className="img"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 2.5H12.5M2.5 5H17.5M15.8333 5L15.2489 13.7661C15.1612 15.0813 15.1174 15.7389 14.8333 16.2375C14.5833 16.6765 14.206 17.0294 13.7514 17.2497C13.235 17.5 12.5759 17.5 11.2578 17.5H8.74221C7.42409 17.5 6.76503 17.5 6.24861 17.2497C5.79396 17.0294 5.41674 16.6765 5.16665 16.2375C4.88259 15.7389 4.83875 15.0813 4.75107 13.7661L4.16667 5M8.33333 8.75V12.9167M11.6667 8.75V12.9167"
        stroke="#FF0F00"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    const draftId = localStorage.getItem("invoice-draft-single-id");
    if (draftId) {
      getDraftDetails(draftId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDraftDetails = async (id) => {
    const obj = {
      id: id,
    };
    const data = await dispatch(getInvoice(obj));
    //   setLoading(true);
    if (data?.payload?.data?.status === "success") {
      // setLoading(false);
      // let totalVal; invoice_token
      const obj = data?.payload?.data?.data;
      // console.log(obj, "------from BE--------");
      if (obj?.items?.length < 2) {
        setItemArr([
          {
            name: obj?.items[0]?.product,
            quantity: obj?.items[0]?.quantity,
            price: obj?.items[0]?.unit_price,
            total_price: obj?.items[0]?.amount,
            id: obj?.items[0]?.id,
          },
        ]);
        // totalVal = obj?.items[0]?.unit_price * obj?.items[0]?.quantity;
      }
      if (obj?.items?.length > 1) {
        const newArr = obj?.items?.map((chi) => {
          return {
            name: chi?.product,
            quantity: chi?.quantity,
            price: chi?.unit_price,
            total_price: chi?.amount,
            id: chi?.id,
          };
        });
        setItemArr(newArr);
      }
      setFormData((prev) => {
        return {
          ...prev,
          invoice_token: obj?.invoice_token || "",
          title: obj?.title,
          startDate: moment(obj?.created_at).format("YYYY-MM-DD"),
          endDate: moment(obj?.due_date).format("YYYY-MM-DD"),
          note: obj?.note,
          cust_fullname: obj?.customer_name,
          cust_email_address: obj?.customer_email,
          cust_phone_no: formatPhoneNumber(obj?.customer_phone),
          discount: obj?.discount || 0,
          vat: obj?.tax || 0,
          shipping: obj?.shipping_fee || 0,
          id: obj?.id,
          discount_val: "",
          vat_val: "",
          totalPrice: obj?.gross,
          totalSum: "",
          item_id: obj?.items[0]?.id,
        };
      });
      setTokenToUse(obj?.invoice_token)
      setTimeout(() => {
        calculatePreviewData();
      }, 1000);
    } else {
      // setLoading(false);
    }
  };

  // useEffect(() => {
  //   const unlisten = navigate((location, action) => {
  //     if (location.pathname !== "/dashboard-invoice-new" && action === "PUSH") {
  //       localStorage.removeItem("customer-inv-id");
  //       localStorage.removeItem("invoice-draft-single-id");
  //       // Add your action here
  //     }
  //   });

  //   return unlisten;
  // }, [navigate]);

  return (
    <>
      <DashboardLayout loadingContent={stage === 1 && loading}>
        <TitletextBox
          title={`Create Invoice`}
          back={true}
          onBack={() => {
            localStorage.removeItem("customer-inv-id");
            localStorage.removeItem("invoice-draft-single-id");
            navigate(-1);
          }}
          text={`Create an invoice to share for later.`}
          // onAdd={() => navigate("/dashboard-invoices-new")}
        ></TitletextBox>
        <ContainerWrapper
          loading={
            stage === 4 && loading_single_draft
              ? true
              : stage === 1 && loading
              ? true
              : false
          }
          width="100%"
          // className="mt-16"
          disabled={
            stage === 1
              ? !formData.title ||
                // !formData.note ||
                !formData?.startDate ||
                !formData?.endDate
              : stage === 2
              ? !formData.cust_fullname ||
                (!formData.cust_email_address && !formData.cust_phone_no)
              : // !formData.cust_phone_no
              stage === 3
              ? itemArr.length > 0
                ? itemArr?.length < 1
                : !item.name || !item.quantity || !item.price
              : false
          }
          stepClickable={
            formData.title &&
            formData.note &&
            formData?.startDate &&
            formData?.endDate &&
            formData.cust_fullname &&
            (formData.cust_email_address || formData.cust_phone_no) &&
            itemArr.length > 0
              ? itemArr?.length > 0
              : item.name && item.quantity && item.price
          }
          onStepClick={(e) => {
            // eslint-disable-next-line no-unused-expressions
            itemArr.length > 0
              ? itemArr?.length > 0 &&
                formData.title &&
                // formData.note &&
                formData?.startDate &&
                formData?.endDate &&
                formData.cust_fullname &&
                (formData.cust_email_address || formData.cust_phone_no) &&
                setStage(e)
              : item.name &&
                item.quantity &&
                item.price &&
                formData.title &&
                // formData.note &&
                formData?.startDate &&
                formData?.endDate &&
                formData.cust_fullname &&
                formData.cust_email_address &&
                formData.cust_phone_no &&
                setStage(e);
          }}
          back={stage > 1}
          onBack={() => {
            stage > 1 && setStage(stage - 1);
          }}
          onBtnClick={() => {
            if (stage === 2) {
              // if()
              if (
                formData?.cust_email_address &&
                !validateEmail(formData?.cust_email_address)
              ) {
                toast.error("Invalid email address!", {
                  position: "top-right",
                });
                setShowError((prev) => {
                  return { ...prev, email: true };
                });
                return;
              }
            }
            if (item?.name && item?.price && item?.quantity) {
              onItemAdd();
            }
            stage < 4 ? next() : handleCreate();
            if (stage === 3) {
              calculatePreviewData();
              setTimeout(() => {
                !loading_auto && handleCreateDraftAuto();
              }, 1000);
            }
            // stage === 3 && calculatePreviewData();
          }}
          btnText={
            stage === 4 && all_account?.length > 1
              ? false
              : stage === 4 && all_account?.length < 2
              ? " Create & send Invoice now"
              : stage === 1
              ? "Next customer detail"
              : stage === 2
              ? "Next add product"
              : stage === 3
              ? "Preview Invoice"
              : "Create & send Invoice now"
          }
          activeStep={stage}
          stepList={stepList}
        >
          <div
            style={{
              marginTop:
                stage === 4 && all_account?.length < 2 ? "0rem" : "2rem",
            }}
            className="container_content_wrapper"
          >
            <div
              // onClick={() => {
              //   console.log(customers);
              // }}
              className="content_contain"
            >
              {stage === 1 && (
                <React.Fragment>
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      label={"Title*"}
                      color={`black-light`}
                      labelColor={`purple-light`}
                      type={`text`}
                      name={`title`}
                      placeholder={`Invoice title.`}
                      onChange={handleChange}
                      value={capitalizeFirstLetter(formData?.title)}
                      required={true}
                    />
                  </form>
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      label={"Start Date*"}
                      color={`black-light`}
                      placeholder={`Select a start date`}
                      type={`date`}
                      name="startDate"
                      // dateOptions={{ minDate: new Date() }}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          startDate: moment(e[0]).format("YYYY-MM-DD"),
                        });
                      }}
                      value={formData?.startDate}
                      required={true}
                    />
                  </form>
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      label={"End Date*"}
                      color={`black-light`}
                      placeholder={`Select a end date`}
                      type={`date`}
                      name="endDate"
                      dateOptions={{ minDate: formData?.startDate }}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          endDate: moment(e[0]).format("YYYY-MM-DD"),
                        });
                      }}
                      value={formData?.endDate}
                      required={true}
                    />
                  </form>

                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      label={"Add a note*"}
                      color={`black-light`}
                      placeholder={`Write your thoughts here... `}
                      type={`textarea`}
                      name="note"
                      textareaRow={5}
                      onChange={handleChange}
                      value={formData?.note}
                      required={true}
                      capitalize
                    />
                  </form>
                </React.Fragment>
              )}

              {stage === 2 && (
                <React.Fragment>
                  {all_customers?.length > 0 && (
                    <>
                      {/* recepient box start */}
                      <div
                        className={`beneficiary-box ${
                          all_customers?.length > 0 && "beneficiary-box-show"
                        }`}
                      >
                        <div className="view-total-box">
                          <span
                            onClick={() => {
                              // console.log(details);
                            }}
                          >
                            Select customers*{" "}
                          </span>
                          {all_customers?.length > 3 && (
                            <div
                              onClick={() => {
                                setShowModal((prev) => {
                                  return { ...prev, view: true };
                                });
                              }}
                              className="view-wrap"
                            >
                              <figure className="img-box">
                                {customerIcons}
                              </figure>
                              <p className="text grey-white-color-white">
                                View all customers
                              </p>
                              {/* {all_customers?.length > 1 && (
                              <form
                                autoComplete="off"
                                style={{ width: "100%" }}
                                action=""
                              >
                                {" "}
                                <RavenInputField
                                  selectStyles={reactSelectStyle}
                                  type={`select`}
                                  placeholder={`See all`}
                                  selectOption={formatBeneficiary(
                                    all_customers
                                  )}
                                  color={`black-light`}
                                  className={"select-bene-wrap"}
                                  value={beneficiarySelect}
                                  onChange={(e) => {
                                    handleBeneficiarySelect(e);
                                    // handleMoveToPreview(e);
                                  }}
                                />
                              </form>
                            )} */}
                            </div>
                          )}
                        </div>
                        {/* end of view total box */}
                        {/* preview list start */}
                        <div className="preview-box">
                          {formatBeneficiary(all_customers)
                            ?.slice(0, size.width < 900 ? 2 : 3)
                            ?.map((chi, idx) => {
                              const {
                                customer_name,
                                customer_email,
                                // customer_phone,
                              } = chi;
                              return (
                                <div
                                  className={`box box-card remove-dark-border hover-white-class ${
                                    beneficiarySelect?.customer_name ===
                                      customer_name &&
                                    beneficiarySelect?.customer_email ===
                                      customer_email &&
                                    "box-card-active"
                                  }`}
                                  key={idx}
                                  onClick={() => {
                                    beneficiarySelect?.customer_name ===
                                      customer_name &&
                                    beneficiarySelect?.customer_email ===
                                      customer_email
                                      ? removeBeneficiaryOption()
                                      : handleBeneficiarySelect(chi);
                                  }}
                                  style={{ borderWidth: ".15rem" }}
                                >
                                  <div className="check-box">
                                    <FaCheck className="icon" />
                                  </div>
                                  <div className="avatar-box grey-bg">
                                    <span className="black-white-color">
                                      {customer_name?.split(" ")[0]?.charAt(0)}
                                    </span>
                                  </div>
                                  <p className="name ">
                                    {trimLongString(customer_name, 18)}
                                  </p>
                                  <p className="text grey-white-color">{`${customer_email}`}</p>
                                </div>
                              );
                            })}
                        </div>
                        {/* preview list end */}
                      </div>
                      {/* recepient box end */}
                      <div className="text-line">
                        <span></span>
                        <p
                          className="text"
                          style={{ textTransform: "uppercase" }}
                        >
                          Or
                        </p>
                        <span></span>
                      </div>
                    </>
                  )}
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      label={"Customer full name*"}
                      color={`black-light`}
                      placeholder={`e.g Timi Ayeni`}
                      labelColor={`purple-light`}
                      type={`text`}
                      name={`cust_fullname`}
                      onChange={handleChange}
                      value={formData?.cust_fullname}
                      required={true}
                      capitalize
                    />
                  </form>

                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      label={"Customer email address*"}
                      color={`black-light`}
                      placeholder={`e.g timiAyeni@gmail.com`}
                      name={`cust_email_address`}
                      type={`email`}
                      showError={showError?.email}
                      onChange={(e) => {
                        setShowError((prev) => {
                          return { ...prev, email: false };
                        });
                        handleChange(e);
                      }}
                      value={formData?.cust_email_address}
                    />
                  </form>

                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      label={"Customer phone number* "}
                      color={`black-light`}
                      placeholder={`e.g 9064xxxxxx`}
                      type={`phone`}
                      name={`cust_phone_no`}
                      onChange={handleChange}
                      value={formData?.cust_phone_no}
                      required={true}
                    />
                  </form>
                </React.Fragment>
              )}

              {stage === 3 && (
                <React.Fragment>
                  <form action="" autoComplete="off" style={{ width: "100%" }}>
                    {" "}
                    <RavenInputField
                      label={"Item name*"}
                      color={`black-light`}
                      placeholder={`e.g Yoga candle`}
                      labelColor={`purple-light`}
                      type={`text`}
                      name="name"
                      onChange={handleChangeItem}
                      value={item?.name}
                      required={true}
                      capitalize
                    />
                  </form>

                  <div className="grouped_input wp-100 flex flex-row">
                    <div className="quantity_contain">
                      <form
                        action=""
                        autoComplete="off"
                        style={{ width: "100%" }}
                      >
                        {" "}
                        <RavenInputField
                          style={{ width: "100%" }}
                          placeholder={"0"}
                          numberUp={() => {
                            setItem((prev) => {
                              return {
                                ...prev,
                                quantity: Number(item?.quantity) + 1,
                              };
                            });
                          }}
                          // className={`wp-30`}
                          numberSpinner
                          numberDown={() => {
                            item?.quantity >= 1 &&
                              setItem((prev) => {
                                return {
                                  ...prev,
                                  quantity: Number(item?.quantity) - 1,
                                };
                              });
                          }}
                          label={"Item quantity* "}
                          color={`black-light`}
                          name={`quantity`}
                          type={`number`}
                          onChange={handleChangeItem}
                          value={item?.quantity <= -1 ? 0 : item?.quantity}
                          required={true}
                        />
                      </form>
                    </div>
                    <form
                      action=""
                      autoComplete="off"
                      style={{ width: "100%" }}
                    >
                      {" "}
                      <RavenInputField
                        // style={{ width: "70%" }}
                        label={"Price per quantity*"}
                        type={`number`}
                        color={`black-light`}
                        name={`price`}
                        value={item?.price}
                        onChange={handleChangeItem}
                        placeholder={`How much is this item?`}
                        thousandFormat
                        numberPrefix={`₦`}
                      />
                    </form>
                  </div>

                  {item?.quantity && item?.price && item?.name && (
                    <div
                      onClick={() => {
                        if (item?.name && item?.price && item.quantity) {
                          onItemAdd();
                        }
                      }}
                      className="add_another_item"
                      style={{
                        opacity:
                          item?.name && item?.price && item.quantity
                            ? "1"
                            : ".5",
                        cursor:
                          item?.name && item?.price && item.quantity
                            ? "pointer"
                            : "unset",
                      }}
                    >
                      <figure>{invoiceIcon.add}</figure>
                      {editState ? (
                        <span>Update item</span>
                      ) : (
                        <span>
                          {item?.quantity && item?.price && item?.name
                            ? `Add item`
                            : `Add another item`}
                        </span>
                      )}
                    </div>
                  )}

                  {item?.quantity && item?.price && item?.name ? (
                    <div className="items_break">
                      <p />
                      <span>Items</span>
                      <p />
                    </div>
                  ) : itemArr.length > 0 ? (
                    <div className="items_break">
                      <p />
                      <span>Items</span>
                      <p />
                    </div>
                  ) : (
                    ""
                  )}

                  {itemArr.length < 1 &&
                    item?.quantity &&
                    item?.price &&
                    item?.name && (
                      <div className="no_items_wrapper grey-bg">
                        <div>
                          <figure>{invoiceIcon.noInvoice}</figure>
                          <span className="grey-white-color-white">
                            Added items would show here
                          </span>
                        </div>
                      </div>
                    )}
                  {itemArr.length > 0 && (
                    <React.Fragment>
                      {/* view all box wrapper end */}
                      <div className="top_indicator new-invoice-top-indicator flex justify-between align-center wp-99">
                        <span className="font-400 text-14">
                          Total Added ({itemArr.length})
                        </span>
                        <span
                          onClick={() => {
                            setViewAll(!viewAll);
                          }}
                          className="font-600 view-text text-14 "
                        >
                          {viewAll ? `Close` : ` View All`}
                        </span>
                      </div>

                      <div className="items_wrapper">
                        {/* view all box wrapper start */}
                        {/* view all box start */}
                        <div
                          className={`view-all-box card-bg ${
                            viewAll && "view-all-box-show"
                          }`}
                        >
                          <div className="wrap">
                            {itemArr?.map((chi, idx) => {
                              return (
                                <div key={idx} className="child-row grey-bg">
                                  <div className="detail-wrap">
                                    <p className="font-600 name text-14">{`${chi.quantity} x ${chi.name}`}</p>
                                    <p className="grey-white-color price">{`${chi.quantity} x ${chi.price}`}</p>
                                  </div>
                                  <div className="two-icon-box">
                                    {chi?.id === item?.id && editState ? (
                                      <>
                                        <p className="grey-white-color">
                                          Editing
                                        </p>
                                        <figure
                                          onClick={() => {
                                            setEditState(false);
                                            setItem({
                                              name: "",
                                              quantity: "",
                                              price: "",
                                            });
                                            setViewAll(false);
                                          }}
                                          className="img-box tooltip-hover-wrap"
                                        >
                                          <RavenToolTip
                                            color={`black-light`}
                                            text={`Cancel Edit`}
                                            position={`bottom-left`}
                                          />
                                          {invoiceIcon.cancel}
                                        </figure>
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        <figure
                                          onClick={() => {
                                            // console.log(itemArr);
                                            setEditState(true);
                                            setViewAll(false);
                                            setItem((prev) => {
                                              return {
                                                ...prev,
                                                ...chi,
                                                name: chi?.name,
                                              };
                                            });
                                          }}
                                          className="img-box tooltip-hover-wrap"
                                        >
                                          {editIcon}
                                          <RavenToolTip
                                            color={`black-light`}
                                            text={`Edit item ${trimLongString(
                                              chi?.name,
                                              10
                                            )}`}
                                            position={`left`}
                                          />
                                        </figure>
                                        <figure
                                          onClick={() => {
                                            handleRemoveFromList(chi?.id, chi);
                                          }}
                                          className="img-box tooltip-hover-wrap"
                                        >
                                          {deleteIcon}
                                          <RavenToolTip
                                            color={`black-light`}
                                            text={`Delete item ${trimLongString(
                                              chi?.name,
                                              10
                                            )}`}
                                            position={`bottom-left`}
                                          />
                                        </figure>
                                      </>
                                    )}
                                  </div>
                                  {/* <figure
                                    onClick={() => {
                                      // console.log(chi?.id);
                                      handleRemoveFromList(chi?.id);
                                    }}
                                  >
                                    {invoiceIcon.cancel}
                                  </figure> */}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* view all box end */}
                      </div>

                      {/* preview box start */}
                      <div className="invoice-create-preview-box">
                        {itemArr?.slice(0, 2)?.map((chi, idx) => {
                          return (
                            <div key={idx} className="child-row grey-bg">
                              <div className="detail-wrap">
                                <p className="font-600 name text-14">{`${chi.quantity} x ${chi.name}`}</p>
                                <p className="grey-white-color price">{`${chi.quantity} x ${chi.price}`}</p>
                              </div>
                              <div className="two-icon-box">
                                {chi?.id === item?.id && editState ? (
                                  <>
                                    <p className="grey-white-color">Editing</p>
                                    <figure
                                      onClick={() => {
                                        setEditState(false);
                                        setItem({
                                          name: "",
                                          quantity: "",
                                          price: "",
                                        });
                                      }}
                                      className="img-box tooltip-hover-wrap"
                                    >
                                      <RavenToolTip
                                        color={`black-light`}
                                        text={`Cancel Edit`}
                                        position={`top-left`}
                                      />
                                      {invoiceIcon.cancel}
                                    </figure>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <figure
                                      onClick={() => {
                                        // console.log(itemArr);
                                        setEditState(true);
                                        setItem((prev) => {
                                          return {
                                            ...prev,
                                            ...chi,
                                            name: chi?.name,
                                          };
                                        });
                                      }}
                                      className="img-box tooltip-hover-wrap"
                                    >
                                      {editIcon}
                                      <RavenToolTip
                                        color={`black-light`}
                                        text={`Edit item ${trimLongString(
                                          chi?.name,
                                          10
                                        )}`}
                                        position={`left`}
                                      />
                                    </figure>
                                    <figure
                                      onClick={() => {
                                        handleRemoveFromList(chi?.id, chi);
                                      }}
                                      className="img-box tooltip-hover-wrap"
                                    >
                                      {deleteIcon}
                                      <RavenToolTip
                                        color={`black-light`}
                                        text={`Delete item ${trimLongString(
                                          chi?.name,
                                          10
                                        )}`}
                                        position={`top-left`}
                                      />
                                    </figure>
                                  </>
                                )}
                              </div>
                              {/* <figure
                                onClick={() => {
                                  // console.log(chi?.id);
                                  handleRemoveFromList(chi?.id);
                                }}
                              >
                                {invoiceIcon.cancel}
                              </figure> */}
                            </div>
                          );
                        })}
                      </div>
                      {/* preview box start */}
                    </React.Fragment>
                  )}

                  {(item?.quantity && item?.price && item?.name) ||
                  itemArr.length > 0 ? (
                    <div
                      onClick={() => setOption(!option)}
                      className="add_another_item"
                    >
                      <figure>
                        {option ? invoiceIcon.minus : invoiceIcon.add}
                      </figure>
                      <span>
                        {option
                          ? "Hide additional option"
                          : "Show additional options"}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className={`additional_option-wrap ${
                      option ? "show" : "hide"
                    }`}
                  >
                    {" "}
                    <div
                      className={`additional_option  ${
                        option ? "show" : "hide"
                      }`}
                    >
                      <RavenInputField
                        label={"Add a discount"}
                        showValue
                        type="range"
                        onChange={(e) => {
                          setFormData((prev) => {
                            return { ...prev, discount: e.target.value };
                          });
                        }}
                        range={formData?.discount}
                        additionalValue={
                          formData?.discount
                            ? `  (${formatNumWithCommaNairaSymbol(
                                formData?.discount_val || "0"
                              )}) `
                            : ""
                        }
                      />

                      <RavenInputField
                        additionalValue={
                          formData?.vat
                            ? ` (${formatNumWithCommaNairaSymbol(
                                formData?.vat_val || "0"
                              )})`
                            : ""
                        }
                        range={formData?.vat}
                        onChange={(e) => {
                          setFormData((prev) => {
                            return { ...prev, vat: e.target.value };
                          });
                        }}
                        label={"V.A.T"}
                        showValue
                        type="range"
                      />

                      <RavenInputField
                        label={"Shipping fee*"}
                        placeholder="e.g NGN 2,000"
                        type={`number`}
                        color={`black-light`}
                        labelClassName={"range-label"}
                        name={`shipping`}
                        value={formData?.shipping}
                        onChange={handleChange}
                        thousandFormat
                        numberPrefix={`₦`}
                      />

                      <form
                        action=""
                        autoComplete="off"
                        style={{ width: "100%" }}
                      >
                        {" "}
                        <RavenInputField
                          label={"Address"}
                          color={`black-light`}
                          labelColor={`purple-light`}
                          type={`text`}
                          name={`address`}
                          placeholder={`Address`}
                          onChange={handleChange}
                          value={capitalizeFirstLetter(formData?.address)}
                          required={true}
                        />
                      </form>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>

            {stage === 4 && (
              <>
                <div className="invoice-step-four-preview-wrap">
                  {all_account?.length < 2 ? (
                    <>
                      {" "}
                      <div
                        style={{ width: size.width < 900 ? "100%" : "65rem" }}
                        className="invoice-preview-detail-wrap  remove-dark-border"
                      >
                        {/* box start */}
                        <div className="box grey-bg top-info">
                          <div className="left-box">
                            <p className="label">Invoice Name:</p>
                            <p className="value grey-white-color">
                              {formData?.title || "---"}
                            </p>
                          </div>
                          <div className="right-box">
                            {/* <p className="label">Date:</p>
                            <p className="value grey-white-color">
                              {formData?.startDate && formData?.endDate
                                ? `${
                                    formatDate(formData?.startDate)?.split(
                                      "—"
                                    )[0]
                                  } — ${
                                    formatDate(formData?.endDate)?.split("—")[0]
                                  }`
                                : "---"}
                            </p> */}
                          </div>
                        </div>
                        {/* box end */}
                        {/* box start */}
                        <div className="box middle-info">
                          <div className="left-box">
                            <p className="label">Bill From:</p>
                            <p className="value grey-white-color">
                              {business?.business_name || "---"}
                            </p>
                            <p className="value grey-white-color">
                              {business?.business_address || "---"}
                            </p>
                            <p className="value grey-white-color">
                              {business?.business_email || "---"}
                            </p>
                          </div>
                          <div className="right-box">
                            <p className="label">Bill To:</p>
                            <p className="value grey-white-color">
                              {formData?.cust_fullname || "---"}
                            </p>
                            <p className="value grey-white-color">
                              {formData?.cust_email_address || "---"}
                            </p>
                            <p className="value grey-white-color">
                              +234 {formData?.cust_phone_no || "---"}
                            </p>
                          </div>
                        </div>
                        {/* box end */}
                        {/* box start */}
                        <div className="box grey-bg top-info detail-info">
                          <div className="left-box">
                            <p className="label">Items</p>
                          </div>
                          <div className="right-box">
                            <p className="label">Cost</p>
                            <p className="label">Qty</p>
                            <p className="label">Price</p>
                          </div>
                        </div>
                        {/* box end */}

                        {/* box start */}
                        {itemArr?.length > 0 &&
                          itemArr?.map((chi, idx) => {
                            const { name, quantity, price, id, total_price } =
                              chi;
                            return (
                              <div
                                key={id || idx}
                                className="box sum-detail-box"
                              >
                                <div className="left-box">
                                  <p className="name">{name || "---"}</p>
                                </div>
                                <div className="right-box">
                                  <p className="cost">
                                    {formatNumWithCommaNairaSymbol(
                                      Number(
                                        formatNumWithoutCommaNaira(price)
                                      ) || "0"
                                    )}
                                  </p>
                                  <p className="quantity">
                                    {quantity || "---"}
                                  </p>
                                  <p className="total-price">
                                    {formatNumWithCommaNairaSymbol(
                                      total_price || "0"
                                    )}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        {itemArr?.length < 1 && (
                          <div className="box sum-detail-box">
                            <div className="left-box">
                              <p className="name">{item?.name || "---"}</p>
                            </div>
                            <div className="right-box">
                              <p className="cost">
                                {formatNumWithCommaNairaSymbol(
                                  Number(
                                    formatNumWithoutCommaNaira(item?.price)
                                  ) || "0"
                                )}
                              </p>
                              <p className="quantity">
                                {item?.quantity || "---"}
                              </p>
                              <p className="total-price">
                                {formatNumWithCommaNairaSymbol(
                                  Number(item?.quantity) *
                                    Number(
                                      formatNumWithoutCommaNaira(item?.price)
                                    ) || "0"
                                )}
                              </p>
                            </div>
                          </div>
                        )}
                        {/* box end */}
                        {/* box start */}
                        <div className="box sum-detail-box sum-detail-box-underline">
                          <div className="left-box"></div>
                          <div className="right-box">
                            <div className="underline border-theme"></div>
                          </div>
                        </div>
                        {/* box end */}
                        {/* box start summary start */}
                        <div className="box sum-detail-box">
                          <div className="left-box">
                            <p className=""></p>
                          </div>
                          <div className="right-box">
                            <p className="cost grey-white-color">Subtotal:</p>
                            <p className="quantity">
                              {" "}
                              {itemArr?.length > 0
                                ? sumAllNum(
                                    itemArr?.map((chi) => Number(chi?.quantity))
                                  )
                                : item?.quantity || "1"}
                            </p>
                            <p className="total-price">
                              {formatNumWithCommaNairaSymbol(
                                formData?.totalSum || "0"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="box sum-detail-box">
                          <div className="left-box">
                            <p className=""></p>
                          </div>
                          <div className="right-box">
                            <p className="cost grey-white-color">
                              Discount ({formData?.discount}%)
                            </p>
                            <p className="quantity"></p>
                            <p className="total-price">
                              -{" "}
                              {formatNumWithCommaNairaSymbol(
                                formData?.discount_val || "0"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="box sum-detail-box">
                          <div className="left-box">
                            <p className=""></p>
                          </div>
                          <div className="right-box">
                            <p className="cost grey-white-color">
                              Tax ({formData?.vat}%)
                            </p>
                            <p className="quantity"></p>
                            <p className="total-price">
                              {" "}
                              {formatNumWithCommaNairaSymbol(
                                formData?.vat_val || "0"
                              )}
                            </p>
                          </div>
                        </div>
                        {formData?.shipping ? (
                          <div className="box sum-detail-box">
                            <div className="left-box">
                              <p className=""></p>
                            </div>
                            <div className="right-box">
                              <p className="cost grey-white-color">
                                Shipping Fee
                              </p>
                              <p className="quantity"></p>
                              <p className="total-price">
                                {" "}
                                {formData?.shipping || ""}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* box start */}
                        <div className="box sum-detail-box sum-detail-box-underline">
                          <div className="left-box"></div>
                          <div className="right-box">
                            <div className="underline border-theme"></div>
                          </div>
                        </div>
                        {/* box end */}
                        <div className="box sum-detail-box">
                          <div className="left-box">
                            <p className=""></p>
                          </div>
                          <div className="right-box">
                            <p className="cost grey-white-color">
                              Invoice Total
                            </p>
                            <p className="quantity"></p>
                            <p
                              className="total-price"
                              style={{ fontWeight: "bold" }}
                            >
                              {formatNumWithCommaNairaSymbol(
                                formData?.totalPrice +
                                  Number(
                                    formatNumWithoutCommaNaira(
                                      formData?.shipping
                                    )
                                  ) || "0"
                              )}
                            </p>
                          </div>
                        </div>

                        {/* box end summary end */}
                        <div className="underline-box">
                          <div className="underline border-theme"></div>
                        </div>
                        {/* note wrap start */}
                        <div className="note-wrap-box">
                          <p className="note">Note:</p>
                          <p className="text grey-white-color">
                            {`Hi ${formData?.cust_fullname}, ${formData?.note}`}
                          </p>
                        </div>
                        {/* note wrap end */}
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className="left-wrap">
                        <div className="invoice-preview-detail-wrap  remove-dark-border">
                          {/* box start */}
                          <div className="box grey-bg top-info">
                            <div className="left-box">
                              <p className="label">Invoice Name:</p>
                              <p className="value grey-white-color">
                                {formData?.title || "---"}
                              </p>
                            </div>
                            <div className="right-box">
                              {/* <p className="label">Date:</p>
                              <p className="value grey-white-color">
                                {formData?.startDate && formData?.endDate
                                  ? `${
                                      formatDate(formData?.startDate)?.split(
                                        "—"
                                      )[0]
                                    } — ${
                                      formatDate(formData?.endDate)?.split(
                                        "—"
                                      )[0]
                                    }`
                                  : "---"}
                              </p> */}
                            </div>
                          </div>
                          {/* box end */}
                          {/* box start */}
                          <div className="box middle-info">
                            <div className="left-box">
                              <p className="label">Bill From:</p>
                              <p className="value grey-white-color">
                                {business?.business_name || "---"}
                              </p>
                              <p className="value grey-white-color">
                                {business?.business_address || "---"}
                              </p>
                              <p className="value grey-white-color">
                                {business?.business_email || "---"}
                              </p>
                            </div>
                            <div className="right-box">
                              <p className="label">Bill To:</p>
                              <p className="value grey-white-color">
                                {formData?.cust_fullname || "---"}
                              </p>
                              <p className="value grey-white-color">
                                {formData?.cust_email_address || "---"}
                              </p>
                              <p className="value grey-white-color">
                                +234 {formData?.cust_phone_no || "---"}
                              </p>
                            </div>
                          </div>
                          {/* box end */}
                          {/* box start */}
                          <div className="box grey-bg top-info detail-info">
                            <div className="left-box">
                              <p className="label">Items</p>
                            </div>
                            <div className="right-box">
                              <p className="label">Cost</p>
                              <p className="label">Qty</p>
                              <p className="label">Price</p>
                            </div>
                          </div>
                          {/* box end */}

                          {/* box start */}
                          {itemArr?.length > 0 &&
                            itemArr?.map((chi, idx) => {
                              const { name, quantity, price, id, total_price } =
                                chi;
                              return (
                                <div
                                  key={id || idx}
                                  className="box sum-detail-box"
                                >
                                  <div className="left-box">
                                    <p className="name">{name || "---"}</p>
                                  </div>
                                  <div className="right-box">
                                    <p className="cost">
                                      {formatNumWithCommaNairaSymbol(
                                        Number(
                                          formatNumWithoutCommaNaira(price)
                                        ) || "0"
                                      )}
                                    </p>
                                    <p className="quantity">
                                      {quantity || "---"}
                                    </p>
                                    <p className="total-price">
                                      {formatNumWithCommaNairaSymbol(
                                        total_price || "0"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          {itemArr?.length < 1 && (
                            <div className="box sum-detail-box">
                              <div className="left-box">
                                <p className="name">{item?.name || "---"}</p>
                              </div>
                              <div className="right-box">
                                <p className="cost">
                                  {formatNumWithCommaNairaSymbol(
                                    Number(
                                      formatNumWithoutCommaNaira(item?.price)
                                    ) || "0"
                                  )}
                                </p>
                                <p className="quantity">
                                  {item?.quantity || "---"}
                                </p>
                                <p className="total-price">
                                  {formatNumWithCommaNairaSymbol(
                                    Number(item?.quantity) *
                                      Number(
                                        formatNumWithoutCommaNaira(item?.price)
                                      ) || "0"
                                  )}
                                </p>
                              </div>
                            </div>
                          )}
                          {/* box end */}
                          {/* box start */}
                          <div className="box sum-detail-box sum-detail-box-underline">
                            <div className="left-box"></div>
                            <div className="right-box">
                              <div className="underline border-theme"></div>
                            </div>
                          </div>
                          {/* box end */}
                          {/* box start summary start */}
                          <div className="box sum-detail-box">
                            <div className="left-box">
                              <p className=""></p>
                            </div>
                            <div className="right-box">
                              <p className="cost grey-white-color">Subtotal:</p>
                              <p className="quantity">
                                {" "}
                                {itemArr?.length > 0
                                  ? sumAllNum(
                                      itemArr?.map((chi) =>
                                        Number(chi?.quantity)
                                      )
                                    )
                                  : item?.quantity || "1"}
                              </p>
                              <p className="total-price">
                                {formatNumWithCommaNairaSymbol(
                                  formData?.totalSum || "0"
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="box sum-detail-box">
                            <div className="left-box">
                              <p className=""></p>
                            </div>
                            <div className="right-box">
                              <p className="cost grey-white-color">
                                Discount ({formData?.discount}%)
                              </p>
                              <p className="quantity"></p>
                              <p className="total-price">
                                -{" "}
                                {formatNumWithCommaNairaSymbol(
                                  formData?.discount_val || "0"
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="box sum-detail-box">
                            <div className="left-box">
                              <p className=""></p>
                            </div>
                            <div className="right-box">
                              <p className="cost grey-white-color">
                                Tax ({formData?.vat}%)
                              </p>
                              <p className="quantity"></p>
                              <p className="total-price">
                                {" "}
                                {formatNumWithCommaNairaSymbol(
                                  formData?.vat_val || "0"
                                )}
                              </p>
                            </div>
                          </div>
                          {formData?.shipping ? (
                            <div className="box sum-detail-box">
                              <div className="left-box">
                                <p className=""></p>
                              </div>
                              <div className="right-box">
                                <p className="cost grey-white-color">
                                  Shipping Fee
                                </p>
                                <p className="quantity"></p>
                                <p className="total-price">
                                  {" "}
                                  {formData?.shipping || ""}
                                </p>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {/* box start */}
                          <div className="box sum-detail-box sum-detail-box-underline">
                            <div className="left-box"></div>
                            <div className="right-box">
                              <div className="underline border-theme"></div>
                            </div>
                          </div>
                          {/* box end */}
                          <div className="box sum-detail-box">
                            <div className="left-box">
                              <p className=""></p>
                            </div>
                            <div className="right-box">
                              <p className="cost grey-white-color">
                                Invoice Total
                              </p>
                              <p className="quantity"></p>
                              <p
                                className="total-price"
                                style={{ fontWeight: "bold" }}
                              >
                                {formatNumWithCommaNairaSymbol(
                                  formData?.totalPrice +
                                    Number(
                                      formatNumWithoutCommaNaira(
                                        formData?.shipping
                                      )
                                    ) || "0"
                                )}
                              </p>
                            </div>
                          </div>

                          {/* box end summary end */}
                          <div className="underline-box">
                            <div className="underline border-theme"></div>
                          </div>
                          {/* note wrap start */}
                          <div className="note-wrap-box">
                            <p className="note">Note:</p>
                            <p className="text grey-white-color">
                              {`Hi ${formData?.cust_fullname}, ${formData?.note}`}
                            </p>
                          </div>
                          {/* note wrap end */}
                        </div>
                      </div>
                      {/* account source box start */}
                      <div className="preview-account-source-wrap border-theme-left">
                        <div className="wrap border-theme">
                          {/* account drop wrap start */}
                          <ChangeSourceAccount
                            onObjChange={(e) => {
                              setSubAccount(e);
                            }}
                            downDropType
                            darkType={true}
                            // onChange={() => onClose()}
                            title={`Invoice Settlement Account.`}
                            defaultObj={subAccount}
                          />
                          {/* account drop wrap end */}
                          {/* Submit button start */}
                          <div className="submit-btn-box">
                            {" "}
                            <RavenButton
                              className="submit-btn"
                              label="Create & send Invoice now"
                              color="black-light"
                              id="6589"
                              onClick={handleCreate}
                              loading={loading_single_draft  }
                            />
                          </div>
                          {/* Submit button end */}
                          {/* Save to draft box start */}
                          {!loading || !loading_single_draft ? (
                            <div className="save-to-draft-box">
                              <p onClick={handleCreateDraft} className="text">
                                Save to draft
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {/* save to draft box end */}
                        </div>
                      </div>
                      {/* account source box end */}
                    </>
                  )}
                </div>
              </>
            )}
            {/* {viewAll && <div className="space-bottom"></div>} */}
          </div>
          {/* <div className="space-bottom"></div> */}
        </ContainerWrapper>
      </DashboardLayout>
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Invoice Created Successfully."
        text={`The invoice has been sent to the recipient's email address, We appreciate your business and look forward to serving you again in the future.`}
        btnText={`Close`}
        onBtnClick={() => {
          navigate("/dashboard-invoice");
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
      {/* all customers modal start */}
      <ViewAllCustomersModal
        setDetails={(param) => {
          if (param) {
            handleBeneficiarySelect(param);
          } else {
            removeBeneficiaryOption();
          }
          setShowModal((prev) => {
            return { ...prev, view: false };
          });
        }}
        visible={showModal.view}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, view: false };
          });
        }}
      />
      {/* all customers modal end */}
    </>
  );
}

export default NewInvoice;
