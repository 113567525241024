import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerWrapper from "../../../components/reusables/ContainerWrapper";
import TitletextBox from "../../../components/reusables/TitletextBox";
import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../styles/dashboard/pos/RequestPos.css";
// import accountIcon from "../../../assets/pos-settle-account.svg";
// import bankIcon from "../../../assets/pos-settle-bank.svg";
// import infoIcon from "../../../assets/info-black-icon.svg";
import { FaCheck } from "react-icons/fa";
// import yellowInfo from "../../../assets/yellow-info.svg";
import {
  RavenCheckBox,
  RavenInputField,
  RavenNumberFormat,
  //   RavenToggleSlide,
  //   RavenToolTip,
} from "@ravenpay/raven-bank-ui";
import {
  getTerminalBusinessInfo,
} from "../../../redux/pos";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBanks,
  quickTransfer,
  validateBankAccount,
  withinSubAccountTransfer,
} from "../../../redux/transaction";
import {
  convertObjectToFormData,
  filterEmptyColumns,
  // formatNumWithCommaNairaSymbol,
  // // formatNumWithoutCommaNaira,
  reactSelectStyle,
  removeSpace,
  trimLongString,
} from "../../../utils/helper/Helper";
import { ThreeDots } from "react-loader-spinner";
import ActionContext from "../../../context/ActionContext";
import TransactionPinModal from "../../../components/reusables/TransactionPinModal";
import SuccessModal from "../../../components/reusables/SuccessModal";
import { getUser } from "../../../redux/user";
import WithdrawPreviewDetailBox from "./WithdrawPreviewDetailBox";
import DescriptionPreviewBox from "../../../components/initiator-des-box/DescriptionPreviewBox";
// import Skeleton from "react-loading-skeleton";

const WithdrawPosPage = () => {
  const { loadingSettlement, terminalBusinessInfo } = useSelector((state) => state.pos);
  const [step, setStep] = useState(1);
  const actionCtx = useContext(ActionContext);
  const { user, all_account } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState({
    pin: false,
    success: false,
  });
  const optionList = [
    {
      value: "1",
      name: "My Accounts",
      icon: (
        <svg
          width="22"
          height="18"
          viewBox="0 0 22 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="img dull-bright-filter"
        >
          <path
            d="M21 5.5H1M1 9.5H4.54668C5.08687 9.5 5.35696 9.5 5.61813 9.54655C5.84995 9.58788 6.0761 9.65635 6.29191 9.75056C6.53504 9.85669 6.75977 10.0065 7.20924 10.3062L7.79076 10.6938C8.24023 10.9935 8.46496 11.1433 8.70809 11.2494C8.9239 11.3437 9.15005 11.4121 9.38186 11.4534C9.64304 11.5 9.91313 11.5 10.4533 11.5H11.5467C12.0869 11.5 12.357 11.5 12.6181 11.4534C12.85 11.4121 13.0761 11.3437 13.2919 11.2494C13.535 11.1433 13.7598 10.9935 14.2092 10.6938L14.7908 10.3062C15.2402 10.0065 15.465 9.85669 15.7081 9.75056C15.9239 9.65635 16.15 9.58788 16.3819 9.54655C16.643 9.5 16.9131 9.5 17.4533 9.5H21M1 4.2L1 13.8C1 14.9201 1 15.4802 1.21799 15.908C1.40973 16.2843 1.71569 16.5903 2.09202 16.782C2.51984 17 3.07989 17 4.2 17L17.8 17C18.9201 17 19.4802 17 19.908 16.782C20.2843 16.5903 20.5903 16.2843 20.782 15.908C21 15.4802 21 14.9201 21 13.8V4.2C21 3.0799 21 2.51984 20.782 2.09202C20.5903 1.7157 20.2843 1.40974 19.908 1.21799C19.4802 1 18.9201 1 17.8 1L4.2 1C3.0799 1 2.51984 1 2.09202 1.21799C1.7157 1.40973 1.40973 1.71569 1.21799 2.09202C1 2.51984 1 3.07989 1 4.2Z"
            // stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      value: "0",
      name: "Other Recipient",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="img dull-bright-filter"
        >
          <path
            d="M3 7.00002V15M7.5 7.00002V15M12.5 7.00002V15M17 7.00002V15M1 16.6L1 17.4C1 17.9601 1 18.2401 1.10899 18.454C1.20487 18.6422 1.35785 18.7952 1.54601 18.891C1.75992 19 2.03995 19 2.6 19H17.4C17.9601 19 18.2401 19 18.454 18.891C18.6422 18.7952 18.7951 18.6422 18.891 18.454C19 18.2401 19 17.9601 19 17.4V16.6C19 16.04 19 15.7599 18.891 15.546C18.7951 15.3579 18.6422 15.2049 18.454 15.109C18.2401 15 17.9601 15 17.4 15H2.6C2.03995 15 1.75992 15 1.54601 15.109C1.35785 15.2049 1.20487 15.3579 1.10899 15.546C1 15.7599 1 16.04 1 16.6ZM9.65291 1.07715L2.25291 2.7216C1.80585 2.82094 1.58232 2.87062 1.41546 2.99082C1.26829 3.09685 1.15273 3.24092 1.08115 3.40759C1 3.59654 1 3.82553 1 4.28349L1 5.40002C1 5.96007 1 6.2401 1.10899 6.45401C1.20487 6.64217 1.35785 6.79515 1.54601 6.89103C1.75992 7.00002 2.03995 7.00002 2.6 7.00002H17.4C17.9601 7.00002 18.2401 7.00002 18.454 6.89103C18.6422 6.79515 18.7951 6.64217 18.891 6.45401C19 6.2401 19 5.96007 19 5.40002V4.2835C19 3.82553 19 3.59655 18.9188 3.40759C18.8473 3.24092 18.7317 3.09685 18.5845 2.99082C18.4177 2.87062 18.1942 2.82094 17.7471 2.7216L10.3471 1.07715C10.2176 1.04837 10.1528 1.03398 10.0874 1.02824C10.0292 1.02314 9.97077 1.02314 9.91264 1.02824C9.8472 1.03398 9.78244 1.04837 9.65291 1.07715Z"
            // stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ];
  const [settleOption, setSettleoption] = useState(optionList[0]?.name);
  const [details, setDetails] = useState({
    type: "1",
    auto: "yes",
    bank_name: "",
    bank_code: "",
    account_num: "",
    account_name: "",
    bank: "",
    amount: "",
    transfer_pin: "",
    narration: "",
    description: "",
    des_imges: [],
  });

  const { bankList, loadingVerify } = useSelector((state) => state.transaction);
  const [myAccount, setMyAccount] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const formatSelectOption = (param) => {
    const list = param.map((chi) => {
      const { code, name } = chi;
      return { label: name, value: code };
    });
    return list;
  };

  useEffect(() => {
    dispatch(fetchBanks());
    // dispatch(getPosDashboardInfo());
    // dispatch(getPosAdminInfo());

    if (Object?.keys(terminalBusinessInfo)?.length < 1) {
      dispatch(getTerminalBusinessInfo());
    }
    // dispatch(getPosDashboardInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   // info?.settlement_type === "manual"
  //   const info = adminInfo?.getInfo;
  //   if (info?.settlement_bank_code) {
  //     // console.log(info);
  //     info?.settlement_account_number === account_details?.account_number
  //       ? setSettleoption("Your Account")
  //       : setSettleoption("Other Banks");
  //     setDetails((prev) => {
  //       return {
  //         ...prev,
  //         auto: info?.settlement_type === "auto" ? "yes" : "no",
  //         type:
  //           info?.settlement_account_number === account_details?.account_number
  //             ? "1"
  //             : "0",
  //         bank_name: info?.settlement_bank_name,
  //         bank_code: info?.settlement_bank_code,
  //         account_number: info?.settlement_account_number,
  //         account_name: info?.settlement_account_name,
  //         bank: {
  //           label: info?.settlement_bank_name,
  //           value: info?.settlement_bank_code,
  //         },
  //       };
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [adminInfo]);

  // validate bank account details
  // const validateBankDetails = async (e) => {
  //   const payload = {
  //     bank_code: e || details?.bank.value || details?.bank?.bank_code,
  //     account_number: details.account_number,
  //   };
  //   // console.log(payload);
  //   if (removeSpace(payload?.account_number)?.length > 9) {
  //     const data = await dispatch(validateBankAccount(payload));
  //     // console.log(data);
  //     if (data?.payload?.data?.status === "success") {
  //       // console.log(data);
  //       setDetails((prev) => {
  //         return { ...prev, account_name: data?.payload?.data?.data };
  //       });
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (details?.bank && details?.account_number?.length > 9) {
  //     validateBankDetails();
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [details?.account_number || details?.bank]);
  const [loadingTrans, setLoadingTrans] = useState(false);
  const handleWidthrawSubmit = async (pin, token) => {
    // let val;
    setLoadingTrans(true);
    if (settleOption === optionList[1]?.name) {
      const obj = {
        bank: details?.bank?.label,
        bank_code: details?.bank?.value || details?.bank?.bank_code,
        amount: RavenNumberFormat(details?.amount, { convertToNumber: true }),
        currency: "NGN",
        account_number: details?.account_num || details?.account_number,
        account_name: validateName || details?.account_name,
        narration: details?.narration,
        transfer_pin: pin,
        two_factor_token: token,
        // schedule: fromDate ? "1" : "0",
        save_beneficiary: details?.beneficiary ? "1" : "0",
        // selected_account_number:
        //   details?.source_account?.account_number ||
        //   actionCtx?.currentAccount?.account_number,
        description: details?.description,
        document: details?.des_imges,
        is_bank_box: 1,
      };

      const data = await dispatch(
        quickTransfer(convertObjectToFormData(filterEmptyColumns(obj)))
      );
      if (data?.payload?.data?.status === "success") {
        handleFinish();
      } else {
        setLoadingTrans(false);
      }
    } else {
      const obj = {
        // source_account_number: details?.sender_account?.account_number,
        destination_account_number:
          details?.account_num || details?.account_number,
        amount: RavenNumberFormat(details?.amount, { convertToNumber: true }),
        narration: details?.narration,
        transfer_pin: pin,
        two_factor_token: token,
        // schedule: fromDate ? "1" : "0",
        // schedule_time: fromDate ? fromDate : "",
        // template_title: details?.save_template ? details?.template_title : "",
        // selected_account_number: actionCtx?.currentAccount?.account_number,
        description: details?.description,
        document: details?.des_imges,
        is_bank_box: 1,
      };
      
      const data = await dispatch(
        withinSubAccountTransfer(
          convertObjectToFormData(filterEmptyColumns(obj))
        )
      );
      if (data?.payload?.data?.status === "success") {
        handleFinish();
      } else {
        setLoadingTrans(false);
      }
    }
    // console.log(val);
    // return;
    // const data = await dispatch(quickTransferPos(filterEmptyColumns(val)));
    // if (data?.payload?.data?.status === "success") {
    //   handleFinish();
    // }
  };

  const handleFinish = () => {
    setShowModal((prev) => {
      return { ...prev, success: true, pin: false };
    });
    setLoadingTrans(false);
    dispatch(getUser());
    // dispatch(getAllTerminals());
    setDetails({
      type: "1",
      auto: "yes",
      bank_name: "",
      bank_code: "",
      account_num: "",
      account_name: "",
      bank: "",
      amount: "",
      transfer_pin: "",
      narration: "",
      description: "",
      des_imges: [],
    });
    navigate("/dashboard-pos");
  };

  const stepList = ["Amount", "Receipient", "Confirm transaction"];
  const amountOption = [500, 1000, 3000, 5000, 10000];

  const handleDisable = () => {
    let val;
    if (step === 1) {
      val = !details?.amount;
    }
    if (step === 2) {
      if (optionList[0]?.name === settleOption) {
        val = !myAccount || !details?.narration;
      } else {
        val =
          !details?.account_num ||
          !details?.narration ||
          !details?.bank_code ||
          !details?.account_name;
      }
    }
    return val;
  };

  const [validateName, setValidatedName] = useState("");
  // validate bank account details
  const validateBankDetails = async (e) => {
    const payload = {
      bank_code: e || details?.bank.value || details?.bank?.bank_code,
      account_number: removeSpace(details.account_num),
    };
    // console.log(payload);

    if (payload?.bank_code && payload?.account_number?.length > 9) {
      const data = await dispatch(validateBankAccount(payload));
      // console.log(data);
      if (data?.payload?.data?.status === "success") {
        // console.log(data?.payload?.data.data);
        setDetails((prev) => {
          return { ...prev, account_name: data?.payload?.data.data };
        });
        setValidatedName(data?.payload?.data.data);
        // setStage(3);
      }
    }
  };

  // const selectValidateAccount = async (e) => {
  //   if (details?.account_num.replace?.length > 9) {
  //     validateBankDetails(e);
  //   }
  // };

  useEffect(() => {
    if (details?.bank && details?.account_num?.length > 9) {
      validateBankDetails();
    }
    removeSpace(details?.account_num);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.account_num || details?.bank]);

  return (
    <>
      <DashboardLayout>
        <TitletextBox
          title={`Withdraw your balance`}
          text={`Ready to be a bank to the less banked people, request for terminal now.`}
          back
          onBack={() => {
            navigate(-1);
          }}
        />
        <ContainerWrapper
          back={step > 1}
          loading={loadingSettlement}
          onBtnClick={() => {
            if (step < 3) {
              setStep(step + 1);
            } else {
              if (user?.pin_status === 0) {
                actionCtx.setCheckShow("nine");
              } else {
                setShowModal((prev) => {
                  return { ...prev, pin: true };
                });
              }
            }
          }}
          onBack={() => {
            if (step > 1) {
              setStep(step - 1);
            }
          }}
          activeStep={step}
          disabled={handleDisable()}
          btnText={step < 3 ? `Proceed` : `Withdraw now.`}
          // dontShowHeader
          stepList={stepList}
        >
          <div
            onClick={() => {
              //   console.log(adminInfo);
            }}
            className="settle-balance-pos-wrap bankbox-withraw-index-wrapper-box"
          >
            {/* step ==== 1 start ----------------------------- */}
            {step === 1 && (
              <>
                <div className="title-text-box">
                  <p className="title">How much do you want to withdraw</p>
                  <p className="text grey-white-color">
                    Enter the amount you would love to withdraw <br /> from your
                    balance.{" "}
                  </p>
                </div>
                <div autoComplete="off" style={{ width: "100%" }} action="">
                  {" "}
                  <RavenInputField
                    type={`number`}
                    color={`black-light`}
                    label={`Amount* `}
                    name={`amount`}
                    value={details?.amount}
                    onChange={handleChange}
                    placeholder={`How much are you  withdrawing`}
                    labelColor={`black-light`}
                    labelSpanText={
                      actionCtx?.showAmount
                        ? `Bal: ${RavenNumberFormat(
                          terminalBusinessInfo?.account_details?.balance || "0.00"
                        )}`
                        : "Bal: ₦ ••••••"
                    }
                    labelClassName={`label-span-amount label-span-theme`}
                    thousandFormat
                    numberPrefix={`₦`}
                    onActionClick={() => {
                      actionCtx?.setShowAmount(!actionCtx?.showAmount);
                    }}
                  />
                </div>
                {/* amount option box start */}
                <div className="amount-option-list-box">
                  {amountOption?.map((chi, idx) => {
                    return (
                      <div
                        onClick={() => {
                          setDetails((prev) => {
                            return { ...prev, amount: chi };
                          });
                        }}
                        key={idx}
                        className="amount-box border-theme card-bg"
                      >
                        <p className="amount">
                          {RavenNumberFormat(chi, { hideDecimal: true })}
                        </p>
                      </div>
                    );
                  })}
                </div>
                {/* amount option box end */}
              </>
            )}
            {/* step ==== 1 end ----------------------------- */}
            {/* step ==== 2 start ----------------------------- */}
            {step === 2 && (
              <>
                <div className="select-title-wrap-index">
                  <p className="title">Recipient</p>
                  <div className="select-wrap-box ">
                    {optionList?.map((chi, idx) => {
                      const { name, icon } = chi;
                      return (
                        <div
                          key={idx}
                          className={`box grey-bg hover-white-class select-option-class ${
                            settleOption === name &&
                            "select-option-class-active"
                          }`}
                          onClick={() => {
                            // console.log(details);
                            if (settleOption !== name) {
                              setMyAccount("");
                              setValidatedName("");
                              setDetails((prev) => {
                                return {
                                  ...prev,
                                  bank: "",
                                  bank_code: "",
                                  bank_name: "",
                                  account_name: "",
                                  account_num: "",
                                };
                              });
                              setSettleoption(name);
                            }
                          }}
                        >
                          <div className="check-box">
                            <FaCheck className="icon" />
                          </div>
                          <figure className="img-box">{icon}</figure>
                          <p className="name">{name}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* selected view start */}
                {optionList[0]?.name === settleOption ? (
                  <>
                    <div className="account-drop-option-box border-theme">
                      {all_account?.map((chi, idx) => {
                        return (
                          <div
                            key={idx}
                            className={`account-item border-theme-bottom select-option-class`}
                            onClick={() => {
                              if (chi?.id !== myAccount?.id) {
                                setMyAccount(chi);

                                setDetails((prev) => {
                                  return {
                                    ...prev,
                                    bank: "",
                                    bank_code: "",
                                    bank_name: chi?.bank,
                                    account_name: chi?.custom_account_name,
                                    account_num: chi?.account_number,
                                  };
                                });
                              }
                            }}
                          >
                            <div className="name-acc-num-box">
                              <p className="name">
                                {trimLongString(chi?.custom_account_name, 20)}
                              </p>
                              <p className="account-num grey-white-color">
                                {` ${chi?.account_number || ""} • ${
                                  chi?.bank || ""
                                }`}
                              </p>
                            </div>
                            {/* check wrap start */}
                            <div
                              className={`check-wrap ${
                                chi?.id === myAccount?.id &&
                                "check-wrap-active "
                              }`}
                            ></div>
                            {/* check wrap end */}
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      autoComplete="off"
                      style={{
                        width: "100%",
                        position: "relative",
                        zIndex: 1000,
                      }}
                      action=""
                    >
                      {" "}
                      <RavenInputField
                        color={`black-light`}
                        type={`select`}
                        label="Select bank* "
                        placeholder="Choose a bank"
                        // selectComponents={{ Option: CustomOption }}
                        onChange={(e) => {
                          validateName && setValidatedName("");
                          // selectValidateAccount(e.bank_code);
                          if (details?.account_num?.length > 9) {
                            validateBankDetails(e.value);
                          }
                          setDetails((prev) => {
                            return { ...prev, bank: e, bank_code: e.value };
                          });
                        }}
                        selectValue={details?.bank}
                        name={`bank`}
                        value={details?.bank}
                        className={`select-react`}
                        selectOption={formatSelectOption(bankList)}
                        selectStyles={reactSelectStyle}
                      />
                    </div>
                    <div autoComplete="off" style={{ width: "100%" }} action="">
                      {" "}
                      <RavenInputField
                        color={`black-light`}
                        type={`account-number`}
                        label="Account number* "
                        placeholder="Recipient account no."
                        onChange={(e) => {
                          setValidatedName("");
                          handleChange(e);
                        }}
                        value={details?.account_num}
                        name={`account_num`}
                        maxLength={10}
                        labelSpanText={
                          loadingVerify ? (
                            <div
                              style={{
                                padding: "0rem",
                                display: "flex",
                                justifyContent: "flex-end",
                                transform: "translateX(1.5rem)",
                              }}
                              className="load-wrap"
                            >
                              <ThreeDots
                                height="10"
                                width="80"
                                radius="9"
                                // color="#020202"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </div>
                          ) : (
                            "Verify"
                          )
                        }
                        labelColor={`purple-light`}
                        labelClassName={`label-class-span ${
                          details?.account_num?.length === 10 &&
                          "label-class-span-active"
                        }`}
                        onActionClick={() => {
                          details?.account_num?.length === 10 &&
                            validateBankDetails();
                        }}
                      />
                    </div>
                    {/* verify name box start */}
                    <div
                      className={`verify-name-box ${
                        validateName && "verify-name-box-show"
                      }`}
                    >
                      <RavenCheckBox
                        checked={true}
                        color={`black-light`}
                        className={`check`}
                      />
                      <p className="text">{validateName}</p>
                    </div>
                    {/* verify name box end */}
                  </>
                )}
                {/* selected view end */}

                <RavenInputField
                  type={`text`}
                  color={`black-light`}
                  label={`Narration* `}
                  value={details?.narration}
                  onChange={handleChange}
                  placeholder={`E.g From timi`}
                  name={`narration`}
                  capitalize
                />
              </>
            )}
            {/* step ==== 2 end ----------------------------- */}
            {/* step ==== 3 start ----------------------------- */}
            {step === 3 && (
              <>
                <WithdrawPreviewDetailBox
                  account_name={details?.account_name}
                  bank={details?.bank_name || details?.bank?.label}
                  account_num={details?.account_num}
                  narration={details?.narration}
                  amount={details?.amount}
                  imgCode={details?.bank_code || details?.bank?.value}
                  value={details?.beneficiary}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, beneficiary: e.target.checked };
                    });
                  }}
                />
                {/* initiar attachment start */}
                {actionCtx?.currentAccount?.authorization_level ===
                  "initiator" && (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "2rem",
                      }}
                      className="initiator-input-box-wrapper"
                    >
                      <RavenInputField
                        placeholder="Enter description"
                        color="black-light"
                        label="Add description *"
                        name="description"
                        onChange={handleChange}
                        value={details?.description}
                      />
                      {/* add and preview images box start */}
                      <DescriptionPreviewBox
                        onAdd={(param) => {
                          const newList = [param, ...details?.des_imges];
                          setDetails((prev) => {
                            return { ...prev, des_imges: newList };
                          });
                        }}
                        imgList={details?.des_imges}
                        onRemove={(param) => {
                          setDetails((prev) => {
                            return { ...prev, des_imges: param };
                          });
                        }}
                        onView={(param) => {
                          actionCtx.setImgPreviewVal(
                            URL.createObjectURL(param)
                          );
                        }}
                      />
                      {/* add and preview images box end */}
                    </div>
                  </>
                )}
                {/* intitiator attachment end */}
              </>
            )}
            {/* step ==== 3 end ----------------------------- */}
          </div>
        </ContainerWrapper>
      </DashboardLayout>
      {/* pin modal start */}

      <TransactionPinModal
        pin={details?.transfer_pin}
        setPin={(e) => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: e };
          });
        }}
        onPay={(pin, token) => {
          handleWidthrawSubmit(pin, token);
        }}
        value={details?.transfer_pin}
        visible={showModal.pin}
        onCancel={() => {
          setDetails((prev) => {
            return { ...prev, transfer_pin: "" };
          });
          setShowModal((prev) => {
            return { ...prev, pin: false };
          });
        }}
        loading={loadingTrans}
      />
      {/* pin modal end */}
      {/* sign up modal start */}
      <SuccessModal
        visible={showModal.success}
        onClose={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
        title="Transaction Successful."
        text={`You should also receive a confirmation email with the details of the transaction. We appreciate your business and look forward to serving you in the future`}
        btnText={`Close`}
        onBtnClick={() => {
          setShowModal((prev) => {
            return { ...prev, success: false };
          });
        }}
      />
      {/* sign up modal end */}
    </>
  );
};

export default WithdrawPosPage;
