import {
  RavenButton,
//   RavenInputField,
  RavenModal,
} from "@ravenpay/raven-bank-ui";
import React from "react";
import "../../../../styles/dashboard/pos/AddAlertRecievers.css";
import { useSelector } from "react-redux";
import ImageFragment from "../../../../components/fragments/ImageFragment";
import { openLinkInNewTab } from "../../../../utils/helper/Helper";
import NoContentBox from "../../../../components/reusables/NoContentBox";
// import { useSelector } from "react-redux";

const ViewTerminalFlierModal = ({ visible, onClose, onShare }) => {
  //   const { user } = useSelector((state) => state.user);
  const { flier_to_use, loadingSettlement } = useSelector((state) => state.pos);
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <>
      <RavenModal onClose={handleClose} btnColor="black-light" visble={visible}>
        <div
          onClick={() => {
            console.log(flier_to_use);
          }}
          className="edit-single-terminal-label-wrapper-index-box"
        >
          <div className="title-text-box border-theme-bottom">
            <p className="title">Share terminal flier</p>
            <p className="text grey-white-color">
              You can share and print your flier
            </p>
          </div>
          {/* flier box wrap start */}
          {loadingSettlement ? (
            <>
              <NoContentBox loading={true} />
            </>
          ) : (
            <>
              {" "}
              <ImageFragment
                key={`vbnm,sm`}
                // url={`https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg`}
                url={flier_to_use}
                loadSize={700}
                //   className={`raven-big-content-view-text-box raven-big-content-view-text-box-two`}
                className={`flier-box-wrap`}
              >
                <div className="flier-box-wrap"></div>
              </ImageFragment>
              {/* flier box wrap end */}
              {flier_to_use && (
                <div className="two-button-wrap grey-bg">
                  <RavenButton
                    onClick={() => {
                      openLinkInNewTab(flier_to_use);
                    }}
                    color="black-light"
                    label="Download"
                  />
                  <RavenButton
                    className="btn-white border-theme"
                    color="white-light"
                    label="Share"
                    onClick={onShare}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </RavenModal>
    </>
  );
};

export default ViewTerminalFlierModal;
