import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../../components/reusables/DeleteModal";
import {
  deleteAlertReciever,
  getAlertReceiverFunc,
} from "../../../../redux/pos";
import { trimLongString } from "../../../../utils/helper/Helper";

const DeleteAlertRecieverModal = ({ visible, onClose, detail, onFinish }) => {
  const dispatch = useDispatch();
  const { loading_alert, terminalInfoSingle } = useSelector((state) => state.pos);

  const handleSubmit = async () => {
    const obj = {
      id: String(detail?.id),
    };

    const data = await dispatch(deleteAlertReciever(obj));
    if (data?.payload?.data?.status === "success") {
      const obj = {
        tid: terminalInfoSingle?.tid,
      };
      dispatch(getAlertReceiverFunc(obj));
      onFinish();
    }
  };

  return (
    <DeleteModal
      title={`Delete Alert Receiver (${
        detail?.fname?.length + detail?.lname?.length > 22
          ? trimLongString(`${detail?.fname || ""} ${detail?.lname || ""}`, 22)
          : `${detail?.fname || ""} ${detail?.lname || ""}`
      })`}
      text={`This terminal won’t be able to receive or process transaction any more, unless you reach our customer care agent to unblock your terminal. do you wish to proceed`}
      deleteText={`Proceed`}
      visible={visible}
      onCancel={onClose}
      onDelete={handleSubmit}
      loading={loading_alert}
    />
  );
};

export default DeleteAlertRecieverModal;
