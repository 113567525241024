import { RavenDateTime, RavenModal } from "@ravenpay/raven-bank-ui";
import React from "react";
import "../../../../styles/dashboard/pos/TrackDeliveryModal.css";
import ProgressBar from "../../../../utils/helper/ProgressBar";

const TrackDeliveryModal = ({ visible, onClose, details }) => {
  const receivedIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className="img"
    >
      <path
        fill="#646464"
        d="M13.473 5.213L8.34 8.187a.687.687 0 01-.68 0L2.527 5.213C2.16 5 2.067 4.5 2.347 4.187c.193-.22.413-.4.646-.527l3.614-2c.773-.433 2.026-.433 2.8 0l3.613 2c.234.127.454.313.647.527.267.313.173.813-.194 1.026zM7.62 9.427v4.546c0 .507-.513.84-.967.62-1.373-.673-3.686-1.933-3.686-1.933-.814-.46-1.48-1.62-1.48-2.573v-3.44c0-.527.553-.86 1.006-.6l4.794 2.78c.2.126.333.353.333.6zM8.38 9.427v4.546c0 .507.513.84.967.62 1.373-.673 3.686-1.933 3.686-1.933.814-.46 1.48-1.62 1.48-2.573v-3.44c0-.527-.553-.86-1.006-.6l-4.794 2.78a.717.717 0 00-.333.6z"
      ></path>
    </svg>
  );

  const orderShipIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
      className="img"
    >
      <path
        fill="#646464"
        d="M4.8 9.9a.9.9 0 11-1.8 0 .9.9 0 011.8 0zM9 9.9a.9.9 0 11-1.8 0 .9.9 0 011.8 0z"
      ></path>
      <path
        fill="#646464"
        d="M1.8 2.4a.6.6 0 00-.6.6v6a.6.6 0 00.6.6h.63a1.5 1.5 0 012.94 0H6a.6.6 0 00.6-.6V3a.6.6 0 00-.6-.6H1.8zM8.4 4.2a.6.6 0 00-.6.6v3.63A1.5 1.5 0 019.57 9.6h.63a.6.6 0 00.6-.6V6a.6.6 0 00-.176-.424l-1.2-1.2A.6.6 0 009 4.2h-.6z"
      ></path>
    </svg>
  );

  const deliverdIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
      className="img"
    >
      <path
        fill="#646464"
        fillRule="evenodd"
        d="M1.2 6a4.8 4.8 0 119.6 0 4.8 4.8 0 01-9.6 0zm7.324-.776a.6.6 0 00-.848-.848L5.4 6.65l-.776-.775a.6.6 0 10-.848.848l1.2 1.2a.6.6 0 00.848 0l2.7-2.7z"
        clipRule="evenodd"
      ></path>
    </svg>
  );

  const trackList = [
    {
      text: "Order Received",
      icon: receivedIcon,
      date: <RavenDateTime hideAgo date={details?.created_at} />,
      alignItems: "flex-start",
    },
    {
      text: "Order Shipped",
      icon: orderShipIcon,
      date: "Est 3days",
      alignItems: "center",
    },
    {
      text: "Delivered",
      icon: deliverdIcon,
      date: "Pending",
      alignItems: "flex-end",
    },
  ];

  return (
    <>
      <RavenModal
        className={`track-delivery-terminal-bankbox-wrapper-box-modal`}
        btnLabel="Close"
        btnColor="black-light"
        onClose={onClose}
        visble={visible}
        onBtnClick={() => {
          // console.log(details);
          onClose();
        }}
      >
        <div className="track-delivery-terminal-bankbox-wrapper-box">
          <div className="title-text-box">
            <p className="title">Track your delivery</p>
            <p className="text grey-white-color">
              You can see the current stage of your delivery here.{" "}
            </p>
          </div>
          {/* default progress bar grey box start */}
          <div className="default-progress-bar-grey-box">
            <ProgressBar
              className={`grey-bg`}
              bgcolor={`rgba(31, 31, 31, 1)`}
              completed={
                details?.status === 0
                  ? 13
                  : details?.status === 1
                  ? 70
                  : details?.status === 2
                  ? 100
                  : 0
              }
              height={35}
            />
            {/* track list satrt */}
            <div className="track-list-box">
              {trackList?.map((chi, idx) => {
                return (
                  <div
                    style={{ alignItems: chi.alignItems }}
                    key={idx}
                    className={`icon-text-date-box ${
                      chi?.alignItems === "flex-end" && "icon-text-date-box-end"
                    }`}
                  >
                    <div className="icon-box">
                      <figure className="img-box">{chi?.icon}</figure>
                    </div>
                    <p className="text">{chi?.text}</p>
                    <p className="date grey-white-color">{chi?.date}</p>
                  </div>
                );
              })}
            </div>
            {/* track list end */}
          </div>
          {/* default progress bar grey box end */}
        </div>
      </RavenModal>
    </>
  );
};

export default TrackDeliveryModal;
