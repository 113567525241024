import {
  RavenCheckBox,
  RavenInputField,
  RavenModal,
  toast,
} from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import "../../../../styles/dashboard/pos/AddAlertRecievers.css";
import { useDispatch, useSelector } from "react-redux";
// import RootState from "../../../../redux/types";

import { validateEmail } from "../../../../utils/helper/ValidateInput";
import {
  addAlertReceiverFunc,
  getAlertReceiverFunc,
} from "../../../../redux/pos";

const AddAlertReceiver = ({ visible, onClose, onFinish, detail }) => {
  const optionList = [
    { name: "WhatsApp", value: "whatsapp" },
    { name: "Email", value: "email" },
    { name: "SMS", value: "sms" },
  ];
  const [selectedOption, setSelectedOption] = useState([]);
  const dispatch = useDispatch();
  const { loading_alert, terminalInfoSingle } = useSelector(
    (state) => state.pos
  );

  const noteIcon = (
    <svg
      className="img"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16536_99374)">
        <path
          d="M8.09961 10.8002V8.10022M8.09961 5.40022H8.10636M14.8496 8.10022C14.8496 11.8281 11.8275 14.8502 8.09961 14.8502C4.37169 14.8502 1.34961 11.8281 1.34961 8.10022C1.34961 4.3723 4.37169 1.35022 8.09961 1.35022C11.8275 1.35022 14.8496 4.3723 14.8496 8.10022Z"
          stroke="#EA872D"
          strokeWidth="1.35"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16536_99374">
          <rect
            width="16.2"
            height="16.2"
            fill="white"
            transform="translate(0 0.000244141)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  // interface formComponent {
  //   email: string;
  //   full_name: string;
  //   phone: string;
  // }
  const [details, setDetails] = useState({
    email: "",
    full_name: "",
    phone: "",
  });

  const [showError, setShowError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { ...details, [name]: value };
    setDetails(obj);
  };

  const handleSubmit = async () => {
    // console.log(insight_terminal);
    // console.log(details);
    // console.log(selectedOption);

    const obj = {
      tid: terminalInfoSingle?.tid || "",
      serial: terminalInfoSingle?.serial,
      phone: details?.phone,
      fname: details?.full_name?.split(" ")[0],
      lname: details?.full_name?.split(" ")[1] || "",
      contact_email: details?.email,
      whatsapp_enabled: selectedOption?.includes("whatsapp") ? "1" : "0",
      sms_enabled: selectedOption?.includes("sms") ? "1" : "0",
      email_enabled: selectedOption?.includes("email") ? "1" : "0",
      // poseidon_owner_email: insight_terminal?.terminalData?.terminal?.poseidon_business_email,
    };
    // console.log(obj);
    // return;
    const data = await dispatch(addAlertReceiverFunc(obj));
    if (data?.payload?.data?.status === "success") {
      setDetails((prev) => {
        return { ...prev, email: "", full_name: "", phone: "" };
      });
      const inputElement = document.getElementById("inputIdPhoneAlert");
      if (inputElement) {
        inputElement.value = ""; // Clear input value
      }
      setSelectedOption([]);
      const obj = {
        tid: terminalInfoSingle?.tid,
      };
      dispatch(getAlertReceiverFunc(obj));
      onFinish && onFinish();
    }
  };

  return (
    <RavenModal
      onBtnClick={() => {
        if (!validateEmail(details.email)) {
          toast.error("Invalid email address!", { position: "top-right" });
          setShowError(true);
          return;
        } else {
          handleSubmit();
        }
      }}
      loading={loading_alert}
      btnLabel="Add Alert Receiver "
      btnColor="black-light"
      visble={visible}
      onClose={onClose}
      className={`session-expired-wrap-modal`}
      disabled={
        !details?.phone ||
        !details?.full_name ||
        !details?.email ||
        selectedOption?.length < 1
      }
    >
      <div className="alert-receiver-modal-wrap-index">
        <div className="title-text-box">
          <p className="title">Add Alert Recievers</p>
          <p className="text grey-white-color">
            Add alert recievers via email, sms or whatsapp
          </p>
        </div>
        <div className="receiver-list-box">
          <RavenInputField
            color="black-light"
            type="text"
            label="Fullname"
            placeholder="Enter fullname"
            name={`full_name`}
            onChange={handleChange}
            value={details?.full_name}
          />
          <RavenInputField
            color="black-light"
            type="email"
            label="Email Address * "
            placeholder="john@mail.com"
            name={`email`}
            // onChange={handleChange}
            value={details?.email}
            onChange={(e) => {
              handleChange(e);
              setShowError(false);
            }}
            showError={showError}
          />
          <RavenInputField
            color="black-light"
            type="phone"
            label="Mobile Number"
            placeholder="E.g 8172783..."
            name={`phone`}
            onChange={handleChange}
            value={details?.phone}
            id="inputIdPhoneAlert"
            key={`guhsjs`}
          />
          <div className="form-group">
            <div className="form-label">Delivery method</div>
            <div className="select-option-list-box">
              {optionList?.map((chi, idx) => {
                return (
                  <div
                    // onClick={() => {
                    //   if (selectedOption?.includes(chi?.value)) {
                    //     const newList = selectedOption?.filter(
                    //       (child: any) => child !== chi?.value
                    //     );
                    //     setSelectedOption(newList);
                    //   } else {
                    //     const newList = [...selectedOption, chi?.value];
                    //     setSelectedOption(newList);
                    //   }
                    // }}
                    key={idx}
                    className="item-select"
                  >
                    <RavenCheckBox
                      id={idx}
                      color="black-light"
                      key={idx}
                      onChange={() => {
                        if (selectedOption?.includes(chi?.value)) {
                          const newList = selectedOption?.filter(
                            (child) => child !== chi?.value
                          );
                          setSelectedOption(newList);
                        } else {
                          const newList = [...selectedOption, chi?.value];
                          setSelectedOption(newList);
                        }
                      }}
                      checked={
                        selectedOption?.includes(chi?.value) ? true : false
                      }
                    />
                    <label
                      htmlFor={`input-check-${idx}`}
                      className="name grey-white-color"
                    >
                      {chi?.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          {/* note box start */}
          <div className="note-box-wrap border-theme grey-bg-two">
            <figure className="img-box">{noteIcon}</figure>
            <p className="text">
              NB: The mobile number included must <br />
              be an active WhatsApp number
            </p>
          </div>
          {/* note box end */}
        </div>
      </div>
    </RavenModal>
  );
};

export default AddAlertReceiver;
