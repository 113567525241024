/* eslint-disable no-useless-concat */

import moment from "moment";
import { stringSimilarity } from "string-similarity";
import waterDropSound from "../../assets/water_droplet_5-165635.mp3";
import messageSound from "../../assets/message-with-intro.mp3";
import moneyReceivedSound from "../../assets/smile-ringtone.mp3";
import newNotificationSound from "../../assets/new-notification-female-voice.mp3";
import definiteSound from "../../assets/definite-555.mp3";
import waveSound from "../../assets/raven_success_sound.wav";
// import useNotificationSound from "../../components/reusables/newSoundReusable";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// should prevent all dayjs relative time error
dayjs.extend(relativeTime);

/** https://day.js.org/docs/en/plugin/relative-time */
export const formatDateToRelativeTime = (date) => {
  if (date) {
    return dayjs()?.to(dayjs(date)) || "";
  }
};

export function returnInitial(name) {
  if (name) {
    const i = name?.split(" ");
    if (i.length > 1) {
      return i[0]?.slice(0, 1).toUpperCase() + i[1]?.slice(0, 1).toUpperCase();
    } else {
      return i[0]?.slice(0, 1).toUpperCase() + i[0]?.slice(1, 2).toUpperCase();
    }
  } else {
    return "";
  }
}

export function getOrdinalSuffix(number) {
  if (number) {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = Number(number) % 100;
    return (
      removeLeadingZeros(number) +
      (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
    );
  }
}

function removeLeadingZeros(input) {
  // Convert the input to a number, which will remove any leading zeros

  if (input) {
    const number = Number(input);

    // Convert the number back to a string if needed
    return number.toString();
  }
}

export const SPECIAL_ROLE_NAME = "owner";

export const reactSelectStyle = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "0.1rem solid #6F6F6F" : "0.1rem solid #6F6F6F",
    // backgroundColor: state.isSelected ? "#6F6F6F" : "white",
    boxShadow: state.isFocused ? "0.1rem solid #6F6F6F" : 0,
    "&:hover": {
      // border: state.isFocused ? 0 : 0
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "rgba(204, 204, 204, .3)" : "white",
    color: state.isSelected ? "#020202" : "#020202",
  }),
};

export const formatNumWithoutCommaNaira = (numb) => {
  // const nairaSymbol = "\u{020A6}";
  // var regex = /[,\sN$R£€KSh#₵%₦G]/g;
  // var result = String(numb)?.replace(regex, "");
  // return result;
  // Remove all characters except digits and the decimal point
  let cleanedStr = String(numb)?.replace(/[^\d.]/g, "");

  // Convert the cleaned string to a number
  let number = parseFloat(cleanedStr);

  // Return the number
  return number;
};

export const formatNumWithCommaNaira = (number) => {
  if (number) {
    // Separate the integer and decimal parts
    let [integerPart, decimalPart] = number?.toString()?.split(".");

    // Format the integer part with thousand separator
    // console.log(number);
    integerPart = Number(integerPart)?.toLocaleString();

    // Handle the decimal part
    if (decimalPart) {
      // Convert the decimal part back to a number and round to two significant figures
      decimalPart = Number("0." + decimalPart)
        ?.toPrecision(2)
        ?.split(".")[1];

      // Remove trailing zeros from the decimal part
      decimalPart = decimalPart?.replace(/0+$/, "");
    }
    // console.log(`${integerPart}.00`);
    // Combine the integer and decimal parts
    return decimalPart
      ? `${integerPart}${decimalPart ? "." : ""}${decimalPart}`
      : `${integerPart}.00`;
  } else {
    return "0.00";
  }
};

export const formatNumWithCommaNairaSymbol = (number) => {
  const nairaSymbol = "\u{020A6}";
  if (number) {
    // Separate the integer and decimal parts
    let [integerPart, decimalPart] = number?.toString()?.split(".");

    // Format the integer part with thousand separator
    integerPart = Number(integerPart)?.toLocaleString();

    // Handle the decimal part
    if (decimalPart) {
      // Convert the decimal part back to a number and round to two significant figures
      decimalPart = Number("0." + decimalPart)
        ?.toPrecision(2)
        ?.split(".")[1];

      // Remove trailing zeros from the decimal part
      decimalPart = decimalPart?.replace(/0+$/, "");
    }
    const val = decimalPart
      ? `${integerPart}${decimalPart ? "." : ""}${decimalPart}`
      : `${integerPart}.00`;
    // Combine the integer and decimal parts
    return `${nairaSymbol}${val}`;
  } else {
    return `${nairaSymbol}0.00`;
  }
};

// eslint-disable-next-line no-unused-vars
function addTrailingZero(number) {
  // Convert the number to a string
  let numStr = number.toString();

  // Check if there is a decimal point
  if (numStr?.includes(".")) {
    // Split the integer and decimal parts
    let [integerPart, decimalPart] = numStr?.split(".");

    // If the decimal part has only one digit, add a trailing zero
    if (decimalPart?.length === 1) {
      return `${integerPart}.${decimalPart}0`;
    }
  }

  // If no decimal point or more than one digit after the decimal point, return the number as is
  return numStr;
}

export const getTotalPage = (perpage, totalNum) => {
  const val = Math.ceil(Number(totalNum) / Number(perpage));
  // console.log(val);
  return val;
};

export function formatDate(date) {
  // console.log(date);
  // var actualDate = String(moment(date).format("MMMM, DD, YYYY  , HH:mm a"));
  var actualDate = String(moment(date).format("MMMM, DD, YYYY  — HH:mm a"));
  const splitDate = actualDate.split(",");
  // var newDate = splitDate[0].slice(0, 3) + "." + splitDate[1] + "," + (splitDate[2])?.replace("—", ",");
  var newDate =
    getOrdinalSuffix(splitDate[1]) +
    " " +
    splitDate[0].slice(0, 3) +
    "" +
    splitDate[2]?.replace("—", ",");
  return newDate;
  // const val = date ? formatTimeDifference(date) : null;
  // return val;
}

export function numberFormatChart(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "Q" },
    { value: 1e18, symbol: "QT" },
    { value: 1e21, symbol: "QQ" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  if (item && item.symbol === "M") {
    // const formattedNum = (num / item.value).toFixed(digits);
    // const integerPart = formattedNum.split(".")[0];
    // const decimalPart = formattedNum.split(".")[1];
    // return `${integerPart}.${decimalPart}${item.symbol}`;
  }
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

export const formatMetaData = (obj) => {
  if (typeof obj !== "string") {
    return obj;
  }

  if (obj) {
    const newObj = JSON?.parse(obj);
    // console.log(newObj);
    return newObj;
  }
};

export const getPageNum = (link) => {
  if (link) {
    const num = link?.split("&current_page=")[1]?.split("&")[0];
    return num;
  }
};

export const sumAllNum = (list) => {
  if (list) {
    const sumVal = list?.reduce((a, b) => a + b, 0);
    return sumVal;
  }
};

export const removeCountryCode = (num) => {
  // let val;
  // if(num?.length === 10){
  //  return `0${num}`
  // }
  // else{
  //  return num
  // }
  // if (num) {
  //   const val =
  //     num.slice(0, 3) === "234"
  //       ? num.replace("234", "0")
  //       : num.slice(0, 1) === "0"
  //       ? num.replace("0", "")
  //       : num;
  //   const valspace = val?.replace(" ", "");
  //   return removeLeadingZeroFromString(valspace);
  // }
  return num;
};

export const capitalizeFirstLetter = (str) => {
  if (str) {
    let capitalizedStr = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalizedStr;
  }
};

export const formatStatus = (param) => {
  if (param) {
    const val =
      param === "0"
        ? "pending"
        : param === "1"
        ? "processing"
        : param === "2"
        ? "success"
        : param === "3"
        ? "failed"
        : "--";
    return val;
  }
};

export function findBestMatch(str, strArr) {
  const lowerCaseArr = strArr.map((element) => element.toLowerCase()); //creating lower case array
  const match = stringSimilarity.findBestMatch(
    str.toLowerCase(),
    lowerCaseArr
  ).bestMatch; //trying to find bestMatch
  //  console.log('rating >>', match.rating);
  if (match.rating > 0 && match.rating >= 0.5) {
    const foundIndex = lowerCaseArr.findIndex((x) => x === match.target); //finding the index of found best case
    return strArr[foundIndex]; //returning initial value from array
  }
  return null;
}

export const reverseArrayFunc = (list) => {
  if (list?.length > 0) {
    const reversedArray = list?.reverse();
    return reversedArray;
  }
};

export const removeSpace = (str) => {
  if (str) {
    const val = String(str).replace(/\s+/g, "");
    // console.log(val);
    return val;
  }
};

export const trimLongString = (str, num) => {
  if (str && num) {
    const val =
      String(str).length > Number(num || 15)
        ? `${String(str).slice(0, Number(num || 15))}...`
        : str;
    return val;
  }
};

export const getPosTerminalRequest = (list) => {
  if (list?.length > 0) {
    const status = list[0]?.status;
    // console.log(status);
    return status;
  }
};

export const formatTitleTemplate = (text) => {
  var regex = /[,\s_.csvNG]/g;
  var result = String(text).replace(regex, " ");
  return result;
};

export function convertToLowerCase(inputString) {
  if (inputString) {
    return String(inputString)?.toLowerCase();
  }
}

export const formatNUmPan = (str) => {
  if (str) {
    const val = `${str?.slice(0, 6)}******${str?.slice(
      str?.length - 4,
      str?.length
    )}`;
    return val;
  }
};

export const lowerCaseWrap = (text) => {
  if (text) {
    // Capitalize the first letter of the first word
    const firstWord = text.split(" ")[0];
    const capitalizedFirstWord =
      firstWord.charAt(0).toUpperCase() + firstWord.slice(1);

    // Reconstruct the string with the capitalized first word
    return capitalizedFirstWord + text.slice(firstWord.length);
  }
};

export const formatTypeFunction = (str) => {
  let val;
  // console.log(str);
  if (str) {
    if (!str?.includes("_")) {
      val = str;
    } else {
      const splitStr = String(str)?.replace(/_/g, " ");
      val = lowerCaseWrap(splitStr);
    }

    // console.log(splitStr);

    // console.log(val);
    return val;
  }
};

export const getStartAndStopDates = (param) => {
  if (param) {
    // get the current date
    var currentDate = moment();

    // subtract 7 days from the current date moment(currentDate).subtract(daysToSubtract, 'days')
    var actualDate = moment(currentDate).subtract(param, "days");

    const fromDate = actualDate.format("YYYY-MM-DD");

    const toDate = currentDate.format("YYYY-MM-DD");

    const obj = {
      start_date: fromDate,
      end_date: toDate,
    };

    return obj;
  }
};

export const getAllAmount = (list) => {
  if (list.length > 0) {
    const amountList = list.map((chi) =>
      Number(formatNumWithoutCommaNaira(chi?.amount))
    );
    const sumList = sumAllNum(amountList);
    // console.log(sumList);
    return sumList;
  }
};

export const returnCbnTransferFee = (feesObject, amount) => {
  let transferCharge = 0;
  let cbnFeeStructure = feesObject.cbn;
  for (let l in cbnFeeStructure) {
    let splittedIndex = l.split("_");
    let lowerNumberForComparism = splittedIndex[0];
    if (parseFloat(splittedIndex.length) === parseFloat(2)) {
      if (parseFloat(splittedIndex[0]) > parseFloat(0)) {
        lowerNumberForComparism = parseFloat(splittedIndex[0]) - parseFloat(1);
      }
      if (
        parseFloat(amount) > parseFloat(lowerNumberForComparism) &&
        parseFloat(amount) <= parseFloat(splittedIndex[1])
      ) {
        transferCharge = parseFloat(cbnFeeStructure[String(l)]);
        break;
      }
    } else {
      if (parseFloat(amount) >= parseFloat(splittedIndex[0])) {
        transferCharge = parseFloat(cbnFeeStructure[String(splittedIndex[0])]);
        break;
      }
    }
  }
  return transferCharge;
};

export function clearCache() {
  if ("caches" in window) {
    // Clear all caches
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
  }
}

export const formatListWithImage = (list) => {
  const newList = list.map((chi) => {
    return {
      ...chi,
      imageSrc: `https://businessapi.getraventest.com/static/media/banks/${chi?.value}.png `,
    };
  });
  // console.log(newList);
  return newList;
};

export function removeLeadingZeroFromString(str) {
  if (typeof str !== "string") {
    return str; // Return as it is if not a string
  }

  if (str.charAt(0) === "0") {
    return str.substr(1); // Remove the first character (leading zero) and return the rest of the string
  }

  return str; // Return the string as it is if the first character is not '0'
}

export const formatPhoneNumber = (phoneNumber) => {
  // Remove all non-digit characters
  if (phoneNumber) {
    const cleaned = phoneNumber.replace(/\D/g, "");

    // Remove leading zeros
    const noLeadingZeros = cleaned.replace(/^0+/, "");

    // Get the first 10 digits
    const formattedNumber = noLeadingZeros.slice(0, 10);

    return formattedNumber;
  }
};

export function getCurrencySymbol(param) {
  // console.log(param);
  const nairaSymbol = "\u{020A6}";
  const symbol =
    String(param) === "NGN"
      ? nairaSymbol
      : String(param) === "USD"
      ? "$"
      : String(param) === "EUR"
      ? "€"
      : String(param) === "ZAR"
      ? `R`
      : String(param) === "KES"
      ? "KSh"
      : String(param) === "CAD"
      ? "$"
      : String(param) === "GHS"
      ? "₵"
      : String(param) === "GBP"
      ? "£"
      : String(param) === "CAD"
      ? "$"
      : String(param) === "ZAR"
      ? `R`
      : String(param) === "KES"
      ? "KSh"
      : String(param) === "GHS"
      ? "₵"
      : param;
  // console.log(symbol);
  return symbol;
}

export function getPrimaryAccount(list) {
  if (list?.length > 0) {
    const newList = list?.filter((chi) => chi?.primary === 1);
    // console.log(newList);

    return newList?.length >= 1 ? newList[0] : list[0];
  }
}

export function formAllAccountList(list) {
  // console.log(list, "---------------dytd-");

  if (list?.length > 1 && getPrimaryAccount(list)) {
    const firstChi = getPrimaryAccount(list);
    const newList = list?.filter((chi) => chi?.primary !== 1);
    const returnList = [firstChi, ...newList];
    return returnList;
  } else {
    // console.log(list);
    return list;
  }
}

export function canStringify(obj) {
  try {
    JSON.stringify(obj);
    return true;
  } catch (error) {
    return false;
  }
}

export function generateUniqueId() {
  const timestamp = Date.now().toString(36); // Convert current timestamp to base36
  const randomString = Math.random().toString(36).substr(2, 5); // Generate a random string
  const uniqueId = `${timestamp}-${randomString}`;
  return uniqueId;
}

export function convertToLowercase(inputString) {
  // console.log(inputString);

  if (inputString) {
    return String(inputString).toLowerCase();
  }
}

export function formatTimeDifference(date) {
  const now = new Date();
  const timeDifference = now - new Date(date);
  // console.log(date);
  if (timeDifference < 60000) {
    // Less than 1 minute
    return "Just now";
  } else if (timeDifference < 3600000) {
    // Less than 1 hour
    const minutesAgo = Math.floor(timeDifference / 60000);
    return `${minutesAgo} minutes ago`;
  } else if (timeDifference < 86400000 && date?.getDate() === now?.getDate()) {
    // Today
    const hoursAgo = Math.floor(timeDifference / 3600000);
    return `${hoursAgo} hours ago`;
  } else {
    // Other dates
    // const options = { year: 'numeric', month: 'long', day: 'numeric' };
    var actualDate = String(moment(date).format("MMMM, DD, YYYY  — HH:mm a"));
    const splitDate = actualDate.split(",");
    var newDate =
      splitDate[0].slice(0, 3) + "." + splitDate[1] + "," + splitDate[2];
    return newDate;
  }
}

export function getSoundToUseFunction(value) {
  let sound;
  if (value) {
    if (value === "water-drop") {
      sound = waterDropSound;
      // useNotificationSound(messageSound)
    }
    if (value === "smile") {
      sound = moneyReceivedSound;
    }
    if (value === "message") {
      sound = messageSound;
    }
    if (value === "new-notification") {
      sound = newNotificationSound;
    }
    if (value === "definite") {
      sound = definiteSound;
    }
    if (value === "wave") {
      sound = waveSound;
    }
  }

  return sound;
}

export function formatStringPermissionToLowerCase(inputString) {
  if (inputString) {
    // Split the input string into words
    const words = String(inputString)?.split("_");

    // Convert each word to lowercase
    const lowercaseWords = words?.map((word) => word?.toLowerCase());

    // Join the lowercase words with underscores
    const formattedString = lowercaseWords?.join("_");

    return formattedString;
  }
}

export function searchGlobalFunction(param, list, keysToSearch) {
  const lowerCaseValue = param.toLowerCase();
  const compareFunction = (a, b) => {
    const aContainsSearchValue = keysToSearch?.some((key) =>
      a[key]?.toLowerCase()?.includes(lowerCaseValue)
    );
    const bContainsSearchValue = keysToSearch?.some((key) =>
      b[key]?.toLowerCase()?.includes(lowerCaseValue)
    );

    if (aContainsSearchValue && !bContainsSearchValue) {
      return -1; // Move a before b
    } else if (!aContainsSearchValue && bContainsSearchValue) {
      return 1; // Move b before a
    } else {
      return 0; // Leave the order unchanged
    }
  };
  const sortedArray = list?.slice()?.sort(compareFunction);
  // console.log(sortedArray);
  return sortedArray;
}
export function filterEmptyColumns(obj) {
  // Create a new object to store the filtered properties
  let filteredObj = {};

  // Iterate over each property in the object
  for (let key in obj) {
    if (obj?.hasOwnProperty(key)) {
      let value = obj[key];

      // Check if the value is not empty
      if (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        !(Array?.isArray(value) && value?.length === 0) &&
        !(
          typeof value === "object" &&
          !Array?.isArray(value) &&
          Object?.keys(value)?.length === 0
        )
      ) {
        // Add the property to the filtered object
        filteredObj[key] = value;
      }
    }
  }

  return filteredObj;
}

export function formatNumberSpecail(number) {
  if (number) {
    // Separate the integer and decimal parts
    let [integerPart, decimalPart] = number?.toString()?.split(".");

    // Format the integer part with thousand separator
    integerPart = Number(integerPart)?.toLocaleString();

    // Handle the decimal part
    if (decimalPart) {
      // Convert the decimal part back to a number and round to two significant figures
      decimalPart = Number("0." + decimalPart)
        ?.toPrecision(2)
        ?.split(".")[1];

      // Remove trailing zeros from the decimal part
      decimalPart = decimalPart?.replace(/0+$/, "");
    }

    // Combine the integer and decimal parts
    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
  } else {
    return "0.00";
  }
}

export const getCurrencyName = (param, list) => {
  if (list?.length > 0 && param) {
    const obj = list?.find((chi) => chi?.currency === param);
    return obj?.name || param;
  }
};

export const getSystemThemePreference = () => {
  const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
  // console.log(darkThemeMq);
  return darkThemeMq.matches ? "dark" : "light";
};

export function formatDateNew(date) {
  // Check if the date is valid
  if (isNaN(new Date(date).getTime())) {
    return "Invalid Date";
  }

  date = new Date(date);

  const pad = (num) => String(num).padStart(2, "0");

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function hasTimePassed(timeStr, minutes) {
  // Parse the input time string
  const inputTime = new Date(timeStr);

  // Get the current time
  const currentTime = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = currentTime - inputTime;

  // Convert the specified minutes to milliseconds
  const minutesInMilliseconds = minutes * 60 * 1000;

  // Check if the time difference is greater than the specified number of minutes in milliseconds
  return timeDifference > minutesInMilliseconds;
}

export const getCurrentObj = (parentObj, objKey) => {
  if (parentObj && objKey) {
    // Check if the objKey exists in the object
    if (parentObj?.hasOwnProperty(objKey)) {
      return parentObj[objKey];
    }

    // Return null if the currencyKey is not found
    return null;
  }
};

export const getTierLevel = (business) => {
  let val;
  if (Object?.keys(business)?.length > 0) {
    if (
      String(business?.bvn) === "3" &&
      String(business?.nin) === "3" &&
      String(business?.address) !== "3"
    ) {
      const obj = {
        completed: ["tier_1_after_nin"],
        val: "tier_1_after_nin",
      };
      val = obj;
      // return;
    }
    if (
      String(business?.address) === "3" &&
      String(business?.cac) !== "3" &&
      String(business?.directors) !== "3" &&
      String(business?.indemnity) !== "3"
    ) {
      const obj = {
        completed: ["tier_1_after_nin", "tier_1_after_address"],
        val: "tier_1_after_address",
      };
      val = obj;
      // return;
    }
    if (
      String(business?.cac) === "3" &&
      String(business?.directors) === "3" &&
      String(business?.indemnity) === "3" &&
      String(business?.referees) !== "3" &&
      String(business?.shareholders) !== "3"
    ) {
      const obj = {
        completed: ["tier_1_after_nin", "tier_1_after_address", "tier_2"],
        val: "tier_2",
      };
      val = obj;
      // return;
    }
    if (
      String(business?.shareholders) === "3" &&
      String(business?.referees) === "3"
    ) {
      const obj = {
        completed: [
          "tier_1_after_nin",
          "tier_1_after_address",
          "tier_2",
          "tier_3",
        ],
        val: "tier_3",
      };
      val = obj;
      // return;
    }
    return val;
  } else {
    val = {
      completed: [],
      val: "",
    };
    // console.log(val);
    return val;
  }
};

export const getAuthLevelDes = (param) => {
  let val;
  switch (param) {
    case "initiator_approver":
      val =
        "As an initiator approver you have access to initiating any payment transactions on the application like Transfer Bills & Payrolls, you also have access to complete those transactions without a third party approver";
      break;
    case "initiator":
      val =
        "As an initiator you can initiate a transaction that requires a payment but you cannot approve such payment yourself. you need to have at least one person approve such payment.";
      break;
    case "approver":
      val =
        "As an approver you have access to only approve payment transactions on the application like Transfer Bills & Payrolls, but you cannot initiate this payments yourself";
      break;
    default:
      val =
        "Anyone with the None Authorisation level cannot and will not the eligible to make or approve payments.";
      break;
  }
  return val;
};

export function convertObjectToFormData(obj) {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      // If the value is an array, append each element
      value.forEach((item) => {
        formData.append(`${key}`, item);
      });
    } else if (value instanceof File) {
      // If the value is a File, directly append it
      formData.append(key, value);
    } else if (typeof value === "object" && value !== null) {
      // For nested objects, convert them to JSON
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value);
    }
  });

  return formData;
}

// export function convertObjectToFormData(obj) {
//   const formData = new FormData();

//   Object.entries(obj).forEach(([key, value]) => {
//     if (Array.isArray(value)) {
//       // Convert the entire array to a string (e.g., JSON format) and append as a single value
//       if (key === "document") {
//         // console.log("jhdjkd");
//         formData.append("document[]", convertFilesToFormData(value));
//       }
//       // formData.append(key, JSON.stringify(value));
//     } else if (value instanceof File) {
//       // If the value is a File, directly append it
//       formData.append(key, value);
//     } else if (typeof value === "object" && value !== null) {
//       // For nested objects, convert them to JSON
//       formData.append(key, JSON.stringify(value));
//     } else {
//       formData.append(key, value);
//     }
//   });

//   return formData;
// }

// export function convertFilesToFormData(obj) {
//   const formDataArray = [];

//   Object.entries(obj).forEach(([key, value]) => {
//     if (key === "document" && Array.isArray(value)) {
//       // For the 'document' key, create a new FormData for each file in the array
//       value.forEach((file, index) => {
//         if (file instanceof File) {
//           const formData = new FormData();
//           formData.append(`${key}[${index}]`, file); // Append file with indexed key
//           formDataArray.push(formData);
//         }
//       });
//     } else if (value instanceof File) {
//       // If the value is a File, append it directly
//       const formData = new FormData();
//       formData.append(key, value);
//       formDataArray.push(formData);
//     } else if (typeof value === "object" && value !== null) {
//       // Handle other nested objects
//       const formData = new FormData();
//       formData.append(key, JSON.stringify(value));
//       formDataArray.push(formData);
//     }
//   });

//   return formDataArray;
// }

export function detectProvider(phoneNumber) {
  if (phoneNumber) {
    // Ensure phoneNumber is defined and is a string
    if (typeof phoneNumber !== "string" || !phoneNumber) {
      console.error("Invalid phone number:", phoneNumber);
      return "Invalid phone number";
    }

    // Remove any non-numeric characters (e.g., spaces, hyphens, etc.)
    const cleanNumber = phoneNumber.replace(/[^\d]/g, "");

    // Remove country code (+234 or 234) or leading zero
    const normalizedNumber = cleanNumber.startsWith("234")
      ? cleanNumber.slice(3)
      : cleanNumber.startsWith("0")
      ? cleanNumber.slice(1)
      : cleanNumber;

    // Add a leading zero if the number doesn't start with one
    const phoneWithLeadingZero =
      normalizedNumber.length === 10
        ? `0${normalizedNumber}`
        : normalizedNumber;

    // Define prefix ranges for different providers
    const mtnPrefixes = [
      "0803",
      "0806",
      "0813",
      "0816",
      "0810",
      "0903",
      "0906",
      "0703",
      "0706",
    ];
    const gloPrefixes = ["0805", "0807", "0815", "0811", "0905"];
    const airtelPrefixes = [
      "0802",
      "0808",
      "0812",
      "0708",
      "0701",
      "0902",
      "0907",
      "0901",
    ];
    const mobile9Prefixes = ["0809", "0817", "0818", "0909", "0908"];

    // Check the first four digits of the normalized number
    const prefix = phoneWithLeadingZero.slice(0, 4);

    // Determine the provider based on the prefix
    if (mtnPrefixes.includes(prefix)) {
      return "mtn";
    } else if (gloPrefixes.includes(prefix)) {
      return "glo";
    } else if (airtelPrefixes.includes(prefix)) {
      return "airtel";
    } else if (mobile9Prefixes.includes(prefix)) {
      return "mobile";
    } else {
      return "";
    }
  }
}

// function addLeadingZero(phoneNumber) {
//   // Check if the phone number starts with "0"
//   if (phoneNumber.startsWith("0")) {
//     return phoneNumber;
//   } else {
//     return `0${phoneNumber}`;
//   }
// }

export function formatPhoneNumberwithSpace(phoneNumber) {
  // Remove all spaces from the input
  // console.log(phoneNumber);

  let cleanedNumber = phoneNumber.replace(/\s+/g, "");

  // Replace leading '234' with '0'
  if (cleanedNumber.startsWith("234")) {
    cleanedNumber = "0" + cleanedNumber.slice(3);
  }

  return cleanedNumber;
}

export const capitalizeAllWords = (param) => {
  if (param) {
    return param?.toUpperCase();
  }
};

export function stringifyArrayOfObjects(array) {
  if (array?.length > 0) {
    return JSON?.stringify(array);
  }
}

export function openLinkInNewTab(url) {
  const width = 800; // Define the width of the new window
  const height = window.screen.height; // Full screen height for the new window
  const left = window.screen.width - width; // Position on the right side

  // Open the new window with the specified dimensions and positioning
  window.open(
    url,
    "_blank",
    `width=${width},height=${height},left=${left},top=0`
  );
}

export function calculateDateRange(input) {
  const today = new Date(); // Get today's date
  const endDate = today.toISOString().split("T")[0]; // Format end date as YYYY-MM-DD

  // Extract the number and unit (weeks or months) from the input
  const [amount, unit] = input.split("_");
  const quantity = parseInt(amount, 10);

  if (isNaN(quantity) || quantity <= 0) {
    throw new Error("Invalid input: Amount must be a positive integer.");
  }

  let startDate;

  // Calculate the start date based on the unit
  if (unit === "week" || unit === "weeks" || unit === "weeek") {
    startDate = new Date(today);
    startDate.setDate(today.getDate() - quantity * 7); // Subtract weeks in days
  } else if (unit === "month" || unit === "months") {
    startDate = new Date(today);
    startDate.setMonth(today.getMonth() - quantity); // Subtract months
  } else {
    throw new Error(
      "Invalid input: Unit must be 'week', 'weeks', 'month', or 'months'."
    );
  }

  const formattedStartDate = startDate.toISOString().split("T")[0]; // Format start date as YYYY-MM-DD
  // console.log({ startDate: formattedStartDate, endDate });
  // const reverseObj = {
  //   startDate: endDate,
  //   endDate: formattedStartDate, // Today's date
  // };
  return {
    startDate: formattedStartDate,
    endDate, // Today's date
  };
}
