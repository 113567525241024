import React from "react";
import NewSelectScheduleBox from "../../../components/reusables/NewSelectScheduleBox";
import "../../../styles/fragments/PreviewDetailsCommonBox.css";
import { RavenInputField, RavenToggleSlide } from "@ravenpay/raven-bank-ui";
import moment from "moment";
import { formatDate, trimLongString } from "../../../utils/helper/Helper";
import ravenLogo from "../../../assets/raven-small-logo-green.svg";
import CheckUrlFragment from "../../../components/fragments/CheckUrlCodeFragment";
// import { useOnClickOutside } from "../../../utils/helper/UseOnClickOutside";
// import { useDispatch } from "react-redux";
// import { getBeneficiaries } from "../../../../redux/settings";
// import { useSelector } from "react-redux";

// const restructureName = (name) => {
//     let val;
//     const nameSplit = name?.split(" ");
//     if (nameSplit?.length > 2) {
//       const newVal = `${nameSplit[0]} ${nameSplit[1]}  ...`;
//       val = newVal;
//       // eslint-disable-next-line no-unused-expressions
//     } else {
//       val = name;
//     }
//     return val;
//   };

const WithdrawPreviewDetailBox = ({
  collapse,
  amount,
  name,
  bank,
  account_name,
  narration,
  onChange,
  value,
  id,
  checked,
  account_num,
  totalFee,
  onChangeSchedule,
  valueSchedule,
  enableTime,
  onReset,
  details,
  bulkAmount,
  setDetails,
  handleRemoveFromList,
  handleChange,
  list,
  imgCode,
  onSourceSelect,
  sourceAccount,
}) => {
//   const [viewAll, setViewAll] = useState(false);

//   const testingPreviewRef = useOnClickOutside(() => {
//     setViewAll(false);
//   });

  return (
    <div className="new-preview-detail-box-wrap card-bg border-theme">
      {/* img top box start */}
      <div className="img-top-box-wrap border-theme-bottom">
        <div
          className="img-wrap border-theme"
          style={{ borderRadius: "50%", borderWidth: ".15rem" }}
        >
          <CheckUrlFragment
            url={
              String(imgCode) === "090615"
                ? ravenLogo
                : `https://businessapi.getraventest.com/static/media/banks/${imgCode}.png`
            }
            
          >
            <figure className="img-box">
              <img
                // style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src={ravenLogo}
                alt=""
                className="img"
              />
            </figure>
          </CheckUrlFragment>

          {/* <figure
            className="img-box border-theme"
            style={{ borderWidth: ".15rem" }}
          >
            <img
              src={
                list?.length > 0
                  ? bankIconDefault
                  : `https://businessapi.getraventest.com/static/media/banks/${imgCode}.png ` ||
                    bankIcon
              }
              alt=""
              className="img"
            />
          </figure> */}
          {/* <div className="arrow-wrap grey-bg">
            <div className="arrow-box">
              <FaArrowRight className="icon" />
            </div>
          </div> */}
        </div>
        {/* amount date box styart */}
        <div className="amount-date-box">
          <p className="amount">
            {list?.length > 0 ? bulkAmount : amount || ""}
          </p>
          <p className="date grey-white-color">
            {valueSchedule
              ? formatDate(valueSchedule)
              : formatDate(moment().format("YYYY-MM-DD HH:mm:ss"))}
          </p>
        </div>
        {/* amount date box end */}
      </div>
      {/* img top box end */}
      {/* middle content wrap start */}
      <div
        style={{ position: "relative", zIndex: 80 }}
        className="middle-content-wrap border-theme-bottom"
      >
        <>
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Bank Name:</p>
            <p className="value">{trimLongString(bank, 20) || ""}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Account Number:</p>
            <p className="value">{account_num || ""}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Account Name:</p>
            <p className="value">{trimLongString(account_name, 20) || ""}</p>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Total Fee:</p>
            <div className="value">{totalFee || 0}</div>
          </div>
          {/* box end */}
          {/* box start */}
          <div className="box">
            <p className="label grey-white-color">Narration:</p>
            <p className="value">{narration || ""}</p>
          </div>
          {/* box end */}

          {/* box start */}
          {/* <div style={{ position: "relative", zIndex: 80 }} className="box">
            <p className="label grey-white-color">Source Account:</p>
            <div className="value">
              <div className="select-toggle-box">
                <NewAccountSelectDrop
                  position={`bottom`}
                  selected={sourceAccount}
                  onSelect={onSourceSelect}
                />
              </div>
            </div>
          </div> */}
          {/* box end */}
        </>
      </div>
      {/* middle content wrap end */}
      {/* bottom- content wrap start */}
      <div className="bottom-content-wrap">
        <NewSelectScheduleBox
          id={`onudjhahabafw`}
          value={valueSchedule}
          textSchedule="Schedule this transaction"
          onChange={(e) => {
            onChangeSchedule(e);
          }}
          onReset={onReset}
        />
        {/* bottom box start */}
        <div className="bottom-box">
          <p className="text grey-white-color">
            {collapse
              ? `Save all as beneficiary`
              : `Save recipient as beneficiary`}
          </p>
          <RavenToggleSlide
            onChange={onChange}
            id={id}
            value={value}
            color={`purple-light`}
            checked={checked}
            //   className={`toggle-box-wrap`}
          />
        </div>
        {/* bottom box end */}

        {/* template start ------------------ */}
        {collapse && (
          <>
            {/* .save bulk option box start */}
            <div className="save-bulk-template-box">
              <div className="toggle-select-text-box">
                <p className="text grey-white-color">
                  Save bulk list for later
                </p>
                <RavenToggleSlide
                  value={details?.save_template}
                  id={`jywfvsb`}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return {
                        ...prev,
                        save_template: details?.save_template ? false : true,
                        template_title:
                          e.target?.checked && details?.template_title
                            ? details?.template_title
                            : "",
                      };
                    });
                  }}
                  checked={details?.save_template}
                  color={`purple-light`}
                />
              </div>
              {/* template title name start */}
              <div
                className={`template-title-box ${
                  details?.save_template && "template-title-box-show"
                }`}
              >
                <RavenInputField
                  color="black-light"
                  type={`text`}
                  label={`Bulk List Title`}
                  name={`template_title`}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, template_title: e.target.value };
                    });
                  }}
                  value={details?.template_title}
                  placeholder={`E.g Salary bonus template.`}
                />
              </div>
              {/* template title name end */}
            </div>
            {/* select schedule start */}
          </>
        )}
        {/* template end ----------------- */}
        {/* {collapse && (
          <RavenInputField
            type={`text`}
            color={`black-light`}
            label={`Add a narration`}
            value={details?.narration}
            onChange={(e) => {
              // console.log(e);
              handleChange(e);
            }}
            placeholder={`E.g From timi`}
            name={`narration`}
          />
        )} */}
      </div>
      {/* bottom- content wrap end */}
      {/* source acount change box */}
      {/* <ChangeSourceAccount /> */}
      {/* source account change box end */}
    </div>
  );
};

export default WithdrawPreviewDetailBox;
